import { combineReducers } from 'redux';
import { ConfigurationsReducer } from './configurations/reducer';
import { ProductsReducer } from './products/reducer';
import { BookmarksReducer } from './bookmarks/reducer';
import { UserReducer } from './user/reducer';
import { NewslettersReducer } from './newsletters/reducer';

const rootReducer = combineReducers({
  User: UserReducer,
  Products: ProductsReducer,
  Bookmarks: BookmarksReducer,
  Newsletters: NewslettersReducer,
  Configurations: ConfigurationsReducer,
});

export default rootReducer;
