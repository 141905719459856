import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AppStore } from '../../store/applicationState';
import { ActivateCode } from '../modals';
import { FlowerpotIcon } from '../svgs/FlowerpotIcon';
import { OwlIcon } from '../svgs/OwlIcon';

const WrapperS = styled.div`
  position: relative;
  width: 100%;
  min-height: 500px;
  max-width: 868px;
  padding-top: 28px;
`;

const HeaderS = styled.h1`
  position: relative;
  margin: 0;
  display: block;
  font-size: 44px;
  font-weight: 600;
  line-height: 74px;
  letter-spacing: -0.34px;
  &:before {
    content: '👋';
    position: absolute;
    margin-left: -72px;
    font-weight: 400;
  }
`;

const SubHeaderS = styled.span`
  font-size: 26px;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: -0.2px;
`;

const LineS = styled.hr`
  height: 1px;
  border: none;
  background-color: #e1dfdb;
  margin: 64px 0;
`;

const PanelS = styled.div`
  background-color: #faf8f5;
  padding: 16px 32px;
  border-radius: 8px;
  font-size: 26px;
  line-height: 44px;
  letter-spacing: -0.2px;
  padding: 16px 31px;
`;

const LinkS = styled.u`
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  margin-left: 10px;
`;

const WrapperIcon = styled.div`
  position: absolute;
  right: -100px;
  bottom: -108px;
`;

const TextBtnS = styled.span`
  line-height: 27px;
  color: #0095ff;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: #f1361d;
  }
`;

const SectionsHelpS = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.2px;
  margin-top: 22px;
`;

const WrapperOwlIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  min-width: 64px;
  background-color: var(--warmGrayColor2);
  border-radius: 50%;
  margin-right: 16px;
`;

export const Main: React.FC = () => {
  const [isModal, setModal] = React.useState(false);
  const { User } = useSelector((store: AppStore) => store);
  return (
    <WrapperS>
      <HeaderS>Привіт, {User.userProfile?.fname}</HeaderS>
      <SubHeaderS>Ви перебуваєте в особистому кабінеті користувача продуктів Цифрового видавництва Експертус</SubHeaderS>
      <LineS />
      <div>
        <h2 style={{ marginBottom: '44px' }}>Тут ви можете:</h2>
        <PanelS>
          <TextBtnS onClick={() => setModal(true)}>Активувати код доступу</TextBtnS> до нового продукту
        </PanelS>
        <PanelS>
          Переглянути
          <Link to="/products">
            <LinkS>придбані продукти</LinkS>
          </Link>
        </PanelS>
        <PanelS>
          Редагувати
          <Link to="/profile">
            <LinkS>ваші реєстраційні дані</LinkS>
          </Link>
        </PanelS>
        <div>
          <h2 style={{ margin: '80px 0 12px' }}>Знайти відповіді на поширені запитання:</h2>
          <SectionsHelpS>
            <WrapperOwlIcon>
              <OwlIcon />
            </WrapperOwlIcon>
            <div>
              <Link to="/feedback">
                <LinkS>Більше питань і відповідей</LinkS>
              </Link>{' '}
              в розділі допомоги
            </div>
          </SectionsHelpS>
        </div>
      </div>
      <WrapperIcon>
        <FlowerpotIcon />
      </WrapperIcon>
      {isModal ? <ActivateCode email={User.userProfile?.email} type={1} handleClose={() => setModal(!isModal)} /> : null}
    </WrapperS>
  );
};
