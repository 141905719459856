import React from 'react';
import styled from 'styled-components';

const ConteinerS = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentS = styled.div`
  padding: 64px;
  background: #faf8f5;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
  text-align: center;
`;


export const MessageEmpty: React.FC = () => {
  return (
    <ConteinerS>
      <ContentS>
        <h2 style={{ marginBottom: '16px' }}>У вас поки що немає закладок</h2>
      </ContentS>
    </ConteinerS>
  );
};
