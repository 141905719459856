import React from 'react';

export const KeyIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="#14151A" stroke="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 5a1 1 0 100 2 1 1 0 000-2z" fill="inherit" stroke="none"></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 13.5v-1h1l.645-.586a5.425 5.425 0 10-4.76-3.606L3.93 11.76a2.925 2.925 0 00-.857 2.068v3.097h2.847c.775 0 1.52-.308 2.068-.857l.51-.568v-1h1.106l.894-1zm2-10.575a3.575 3.575 0 11-.554 7.107l-.464-.072-1.115 1.115H9.075v.993a1 1 0 01-1 1h-1v1.299l-.393.393a1.075 1.075 0 01-.76.315h-.997v-1.247c0-.285.113-.558.315-.76l4.366-4.365-.301-.598a3.575 3.575 0 013.195-5.18z"
        fill="inherit"
        stroke="none"
      ></path>
    </svg>
  );
};
