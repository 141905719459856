// @ts-nocheck
import React from 'react';
import FacebookLogin from 'react-facebook-login';
import '../styles';
import { useDispatch } from 'react-redux'; 
import { useNavigate } from 'react-router-dom';
import { GetFBUser } from '../../../store/user/actions';

const FaceBookAuthButton = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const login = (dataFb) => {
    
    if (dataFb && dataFb.facebookUserId) {

      dispatch(
        GetFBUser.request({
          data: dataFb,
          callBack: (succ: any, data: any) => {
            if (props.setIsSucc) {
              if (succ) {
                props.setIsSucc({
                  status: 'success',
                  url: '',
                  tokenCoock: data?.tokenCoock || JSON.stringify(data)

                })
              } else {
                const getDataParam = `&email=${data?.email ? data.email : ''}&firstName=${data?.firstName ? data?.firstName : ''}&lastName=${data?.lastName ? data?.lastName :''}`
                if (data.redirect) {
                  const safari = navigator.userAgent.indexOf("iPhone") !== -1;
                  if(safari){
                    window.open(`${data.redirect}${getDataParam}`)

                  }
                  props.setIsSucc({
                    status: 'register',
                    url: `${data.redirect}${getDataParam}`,
                  })
                }
              }
            } else {
              if (!succ && data.message) {
                props.setError(data.message);
              }
              if (!succ && data.redirect) {
                if (props.redirect) {
                  navigate(data.redirect);
                } else {
                  navigate(`/registration`);
                }
              }
              if (succ) {
                navigate('/main');
              }
            }
          },
        })
      );
    }

  }
  const callbackFunc = (response: any) => {

    if (response) {
      const data = {
        facebookToken: response.accessToken,
        facebookUserId: response.userID,
      }
      login(data)
    }


  }

  return (
    <>
        <FacebookLogin
          autoLoad={false}
          appId={process.env.REACT_APP_FACEBOOK_APP_ID}
          callback={callbackFunc}
          scope="email"
          textButton=''
          cssClass='facebookIcon'
          disableMobileRedirect={true}
          isMobile={false}
        />
</>
   
  );
};

export default FaceBookAuthButton;
