export interface TBookmarksState {
  data: {
    list: IBookmark[],
    products?: IBookmarkProduct[]
  } | null;
  readonly loading: boolean;
  isAuth?: boolean;
  readonly errors?: string | undefined;
}

export interface IBookmark {
  absnum: number;
  user_id: number;
  source_id: number;
  title: string;
  url: string;
  domain: string;
  number: number;
  date_create: Date;
  date_publication: Date;
  last_view_widget: Date;
  last_view_cabinet: Date;
  count_click_cabinet: number;
  count_click_widget: number;
  app_id: number;
  approved: number;
  hash : string;
  isNew: number;
}
export interface IBookmarkProduct {
  absnum: number;
  name: string;
  app_id: number;
  isNew: number;
}

export enum ActionTypes {
  GET_BOOKMARKS_R = '@@bookmarks/GET_BOOKMARKS_R',
  GET_BOOKMARKS_S = '@@bookmarks/GET_BOOKMARKS_S',
  GET_BOOKMARKS_E = '@@bookmarks/GET_BOOKMARKS_E',
  DELETE_BOOKMARKS_R = '@@bookmarks/DELETE_BOOKMARKS_R',
  DELETE_BOOKMARKS_S = '@@bookmarks/DELETE_BOOKMARKS_S',
  DELETE_BOOKMARKS_E = '@@bookmarks/DELETE_BOOKMARKS_E',
  SETCOUNT_BOOKMARKS_R = '@@bookmarks/SETCOUNT_BOOKMARKS_R',
  SETCOUNT_BOOKMARKS_S = '@@bookmarks/SETCOUNT_BOOKMARKS_S',
  SETCOUNT_BOOKMARKS_E = '@@bookmarks/SETCOUNT_BOOKMARKS_E',
  CHECKALL_BOOKMARKS_R = '@@bookmarks/CHECKALL_BOOKMARKS_R',
  CHECKALL_BOOKMARKS_S = '@@bookmarks/CHECKALL_BOOKMARKS_S',
  CHECKALL_BOOKMARKS_E = '@@bookmarks/CHECKALL_BOOKMARKS_E',
}

