import React from 'react';
import s from '../components/Auth/styles';
import { Header, Footer } from '../components/Auth/components';

export const ConfirmEmail: React.FC = () => {
  return (
    <div style={{ height: '100%' }}>
      <Header />
      <s.WrapperS>
        <s.FeedbackS>
          <h2>Адресу ел. пошти підтвердити не вдалося</h2>
          <p>
            Ви вже підтвердили с е-або сталася помилка. Спробуйте ще раз перейти за посиланням підтвердження пiзнiше або зверніться до нашої служби
            кліентської підтримки за номером 0 44 580 56 00
          </p>
        </s.FeedbackS>
      </s.WrapperS>
      <Footer />
    </div>
  );
};
