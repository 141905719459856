import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { callApi } from '../../utils/callApi';
import { GetConfigurations, TypeGetConfigurationsR } from './actions';
import { ActionTypes, TConfigurationsState } from './types';

function* getConfigurationsWorker(action: ReturnType<typeof GetConfigurations.request>): Generator {
  const { callBack } = action.payload as TypeGetConfigurationsR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/configurations`,
    })) as TConfigurationsState['data'];
    yield put(GetConfigurations.success(resp));
  } catch (e) {
    success = false;
    yield put(GetConfigurations.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_CONFIGURATIONS_R, getConfigurationsWorker);
}

export default function* configurationsSaga() {
  yield all([fork(watchFetchRequest)]);
}
