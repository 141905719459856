import React from 'react';

export const CaretCircle: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75ZM2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12Z" fill="#0065C1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0575 8.12328C14.3346 8.43116 14.3096 8.90538 14.0017 9.18247L10.8711 12L14.0017 14.8175C14.3096 15.0946 14.3346 15.5688 14.0575 15.8767C13.7804 16.1846 13.3062 16.2096 12.9983 15.9325L9.24828 12.5575C9.09024 12.4152 9 12.2126 9 12C9 11.7874 9.09024 11.5848 9.24828 11.4425L12.9983 8.06753C13.3062 7.79043 13.7804 7.81539 14.0575 8.12328Z" fill="#0065C1"/>
    </svg>
  );
};
