import { Reducer } from 'redux';
import { ActionTypes, TNewslettersState } from './types';

export const initialState: TNewslettersState = {
  loading: true,
  data: null,
  errors: undefined,
};

const newslettersReducer: Reducer<TNewslettersState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_NEWSLETTERS_R:
      return { ...state, loading: true, errors: undefined };

    case ActionTypes.GET_NEWSLETTERS_S:
      return { ...state, loading: false, errors: undefined, data: action.payload };

    case ActionTypes.GET_NEWSLETTERS_E:
      return { ...state, loading: false, errors: action.payload };
    default:
      return state;
  }
};

export { newslettersReducer as NewslettersReducer };
