import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AppStore } from '../../store/applicationState';
import { Logout, setAuth, setUser } from '../../store/user/actions';
import { AvatarIcon } from '../svgs/AvatarIcon';

const HeaderS = styled.div`
  background: #faf8f5;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;
  padding: 0 64px;
`;

const NavS = styled.nav`
  display: flex;
  align-items: center;
  height: 154px;
  flex-wrap: nowrap;
  width: 1440px;
  position: relative;
  max-width: 1440px;
`;

const LogoWrapperS = styled.div`
  display: flex;
  align-items: center;
  margin-right: 64px;
  svg {
    fill: none;
  }
`;

const MenuAboutS = styled.div<{ isBlock?: boolean }>`
  position: relative;
  cursor: pointer;
  margin-left: auto;
  width: 220px;
  &:hover {
    span {
      ${({ isBlock }) => (isBlock ? 'display: block;' : '')}
    }
  }
`;

const DropDownWrapperS = styled.span`
  display: none;
  position: absolute;
  min-width: 265px;
  left: unset;
  right: 0;
  border-radius: 16px;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  box-shadow: 0 1px 4px rgb(44 48 59 / 10%), 0 4px 12px rgb(44 48 59 / 15%);
  border-radius: 8px;
  padding: 20px;
  left: -10px;
  top: 100%;
  z-index: 99;
`;

const MenuItemS = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  color: #7d859b;
  margin-bottom: 12px;
  a {
    color: rgb(125, 133, 155);
  }
  &:hover {
    color: #7d859b;
  }
`;

const MenuItemWrapperS = styled.div<{ selected?: boolean }>`
  display: flex;
  position: relative;
  overflow: hidden;
  height: 100%;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02px;
  width: 100%;
  white-space: nowrap;
  ${({ selected }) => (selected ? 'color: black' : '')}
`;

const BtnLogoutS = styled.span`
  display: flex;
  position: relative;
  overflow: hidden;
  height: 100%;
  cursor: pointer;
  font-weight: 600;
`;

export const Header: React.FC = () => {
  const { User } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const pathname = window.location.pathname;

  const queryString = window.location.search;
  const queryParams = new URLSearchParams(queryString);
  const links = queryParams.get('link');

  let add_url = "";

  if(links)
    add_url =  `?link=${links}`;

  return (
    <HeaderS>
      <NavS>
        <LogoWrapperS>
          <Link to={`/main${add_url}`}>
            <img style={{ width: '170px', height: '45px' }} src={'/media/images/logo-e.png'} alt="Експертус" />
          </Link>
        </LogoWrapperS>
        <MenuItemS style={{ margin: '0 20px 0 0' }}>
          <Link to={`/products${add_url}`}>
            <MenuItemWrapperS selected={pathname.includes('/products')}>Продукти</MenuItemWrapperS>
          </Link>
        </MenuItemS>
        <MenuItemS style={{ margin: '0 20px 0 0' }}>
          <Link to={`/newsletters${add_url}`}>
            <MenuItemWrapperS selected={pathname.includes('/newsletters')}>Мої розсилки</MenuItemWrapperS>
          </Link>
        </MenuItemS>
        <MenuItemS style={{ margin: '0 20px 0 0' }}>
          <Link to={`/bookmarks${add_url}`}>
            <MenuItemWrapperS selected={pathname.includes('/bookmarks')}>Мої Заклaдки</MenuItemWrapperS>
          </Link>
        </MenuItemS>
        <MenuItemS style={{ margin: '0 20px 0 0' }}>
          <Link to={`/profile${add_url}`}>
            <MenuItemWrapperS selected={pathname.includes('/profile')}>Про мене</MenuItemWrapperS>
          </Link>
        </MenuItemS>
        <MenuItemS style={{ margin: '0 20px 0 0' }}>
          <Link to={`/feedback${add_url}`}>
            <MenuItemWrapperS selected={pathname.includes('/feedback')}>Допомога</MenuItemWrapperS>
          </Link>
        </MenuItemS>
        <MenuAboutS isBlock={!!User.userProfile}>
          <MenuItemS>
            <Link to={`/profile${add_url}`}>
              <MenuItemWrapperS selected={pathname.includes('/profile')} style={{display:'flex', justifyContent:'flex-end' }}>
                <LogoWrapperS style={{ marginRight: '8px' }}>
                  <AvatarIcon selected={pathname.includes('/profile')} />
                </LogoWrapperS>
                {User.userProfile ? `${User.userProfile.fname} ${User.userProfile.lname}` : 'Вхід та реєстрація'}
              </MenuItemWrapperS>
            </Link>
          </MenuItemS>
          <DropDownWrapperS>
            <MenuItemS style={{ marginLeft: 0, marginRight: 0 }}>
              <Link to={`/profile${add_url}`}>
                <MenuItemWrapperS selected={pathname.includes('/profile')} style={{ padding: 0 }}>
                  Про мене
                </MenuItemWrapperS>
              </Link>
            </MenuItemS>
            <MenuItemS>
              <a href={`/login${add_url}`}>
                <BtnLogoutS
                  onClick={() => {
                    localStorage.clear();
                    dispatch(setAuth(false));
                    dispatch(setUser(null));
                    dispatch(Logout.request({}));
                  }}
                >
                  Вийти із сервісів Експертус
                </BtnLogoutS>
              </a>
            </MenuItemS>
          </DropDownWrapperS>
        </MenuAboutS>
      </NavS>
    </HeaderS>
  );
};
