import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { callApi } from '../../utils/callApi';
import { GetBookmarks, TypeGetBookmarksR, 
  DeleteBookmarks, TypeDeleteBookmarksR, 
  SetCountBookmarks, TypeSetCountBookmarksR,
  CheckAllNewBookmarks, TypeCheckAllNewBookmarksR } from './actions';
import { ActionTypes, IBookmark } from './types';

function* getBookmarksWorker(action: ReturnType<typeof GetBookmarks.request>): Generator {
  const { data, callBack } = action.payload as TypeGetBookmarksR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      data,
      path: `/get-bookmarks`,
    })) as IBookmark[];
    yield put(GetBookmarks.success(resp));
  } catch (e) {
    success = false;
    yield put(GetBookmarks.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* deleteBookmarksWorker(action: ReturnType<typeof DeleteBookmarks.request>): Generator {
  const { data, callBack } = action.payload as TypeDeleteBookmarksR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      data,
      path: `/remove-bookmark`,
    })) as IBookmark;
    yield put(DeleteBookmarks.success(resp));
  } catch (e) {
    success = false;
    yield put(DeleteBookmarks.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}
function* setCountBookmarksWorker(action: ReturnType<typeof SetCountBookmarks.request>): Generator {
  const { data, callBack } = action.payload as TypeSetCountBookmarksR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      data,
      path: `/set-count-bookmark`,
    })) as IBookmark;
    yield put(SetCountBookmarks.success(resp));
  } catch (e) {
    success = false;
    yield put(SetCountBookmarks.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}
function* checkAllNewBookmarkWorker(action: ReturnType<typeof CheckAllNewBookmarks.request>): Generator {
  const { data, callBack } = action.payload as TypeCheckAllNewBookmarksR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      data,
      path: `/checkall-bookmark`,
    })) as IBookmark;
    yield put(CheckAllNewBookmarks.success(resp));
  } catch (e) {
    success = false;
    yield put(CheckAllNewBookmarks.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}
function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_BOOKMARKS_R, getBookmarksWorker);
  yield takeEvery(ActionTypes.DELETE_BOOKMARKS_R, deleteBookmarksWorker);
  yield takeEvery(ActionTypes.SETCOUNT_BOOKMARKS_R, setCountBookmarksWorker);
  yield takeEvery(ActionTypes.CHECKALL_BOOKMARKS_R, checkAllNewBookmarkWorker);

}

export default function* bookmarksSaga() {  
  yield all([fork(watchFetchRequest)]);
}
