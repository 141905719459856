import React from 'react';

export const LinkIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="#0095FF"
      viewBox="0 0 20 20"
      className="injected-svg"
      data-src="https://cdn.mcfr.ua/prod/lk/lk-frontend/acf9975f/assets/a996da137b3d92cf95a487341507828a.svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M11 3.075a.925.925 0 000 1.85h2.661L9.793 8.793a1 1 0 001.414 1.414l3.868-3.868v2.11a.925.925 0 101.85 0V3.075H11z"
        fill="inherit"
      ></path>
      <path
        d="M4.075 16.925a1 1 0 01-1-1V4.075a1 1 0 011-1H7a.925.925 0 010 1.85H5.425a.5.5 0 00-.5.5v9.15a.5.5 0 00.5.5h9.15a.5.5 0 00.5-.5V13a.925.925 0 111.85 0v2.925a1 1 0 01-1 1H4.075z"
        fill="inherit"
      ></path>
    </svg>
  );
};
