import { Actions, TDefRequest } from '../Actions';
import { ActionTypes, IProduct } from './types';

const GetProductsAction = new Actions('GET_PRODUCTS', ActionTypes);

export interface TypeGetProductsR extends TDefRequest {}

export const GetProducts = {
  request: (payload: TypeGetProductsR) => GetProductsAction.request(payload),
  success: (payload: IProduct[]) => GetProductsAction.success(payload || {}),
  error: (message: string) => GetProductsAction.error(message),
};

const ActivateProductAction = new Actions('ACTIVATE_PRODUCT', ActionTypes);

export interface TypeActivateProductR extends TDefRequest {
  data: {
    code: string;
  };
}

export const ActivateProduct = {
  request: (payload: TypeActivateProductR) => ActivateProductAction.request(payload),
  success: (payload: IProduct) => ActivateProductAction.success(payload || {}),
  error: (message: string) => ActivateProductAction.error(message),
};
