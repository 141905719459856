import React from 'react';
import styled from 'styled-components';
import 'moment/locale/uk';
import { IBookmark } from '../../../store/bookmarks/types';
import { Trash } from '../../svgs/Trash';
import { ConfirmDelete } from '../../modals';
import { SetCountBookmarks } from '../../../store/bookmarks/actions';
import { useDispatch } from 'react-redux';

const CardS = styled.div<{ show: boolean }>`
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.3;
  color: #616161;
  overflow: hidden;
  transition: all 0.5s ease;
  &:hover{
    background: #F0F8FF;
    svg path{
      fill: #D96666;
    }
  }
  ${({ show }) =>
  show
      ? `
      opacity: 1;
      min-height: 40px;
      `
      : `
      opacity: 0;
      min-height: 0px;
      max-height: 0px;
      `}
`;

const TitleS = styled.a`
  flex: 18;
  padding: 8px 16px;
  color: #616161;
  &:hover{
    color: #004889;
  }
`;

const NumberS = styled.div`
  color: #9E9E9E;
  padding: 12px 16px;
  width: 110px;
  text-align: right;
  flex: 2;
`;

const IsNewWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 3;
`;
const IsNew = styled.div`
  color: #7FB781;
  padding: 0;
  border: 1px solid #C5E1C6;
  background: #EFFBF0;
  padding: 1px 8px;
  border-radius: 20px;
  font-size: 11px;
`;


const IconS = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 4px;
`;

interface PropsType {
  bookmark: IBookmark;
  handleChange: (bookmark: IBookmark) => void;
  reduceCounter: (app_id: number) => void;
}

export const Bookmark: React.FC<PropsType> = ({ bookmark, handleChange, reduceCounter }) => {
  const dispatch = useDispatch();

  const [isModal, setModal] = React.useState(false);
  const [show, setShow] = React.useState(true);
  const [isNew, setIsNew] = React.useState(true);

  React.useEffect(() => {
    console.log(bookmark);
    
      setIsNew(!!bookmark.isNew);
  }, [bookmark, bookmark.isNew]);


  const handleClick = () => {
    setModal(true);
  }

  const handleMouseDown = (e:any) => {
    if (e.button === 0 || e.button === 1) {
      handleClickLink(e);
      if(isNew){
        reduceCounter(bookmark.app_id);
        setIsNew(false);
      }
    }
  };
  const handleClickLink = (e:any) => {
    dispatch(
      SetCountBookmarks.request({
        data: {
          absnum:bookmark.absnum,
          isId:true
        },
      })
    );

  }
  const handleConfirm = () => {
    setShow(false);
    setModal(false);
    handleChange(bookmark);
  }

  const formatDate = (date:Date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    
    const formattedDate = `${day}.${month}.${year}`;
    
  return formattedDate;
    
}
  const hash = bookmark.hash ? '#' + bookmark.hash : '';
  return (
    <CardS show = { show }>
      <TitleS onMouseDown={(e)=>handleMouseDown(e)}href = {`${bookmark.domain}${bookmark.url + hash}`} target = '_blank'>{bookmark.title}</TitleS>
      <IsNewWrap>
        {isNew && (
          <IsNew>New</IsNew>
        )}
      </IsNewWrap>
      <NumberS>
        {bookmark.number ? bookmark.number : ''}
        {!bookmark.number && bookmark.date_create ? formatDate(new Date(bookmark.date_create)) : '' }
      </NumberS>
      <IconS onClick={ ()=>handleClick() }>
        <Trash />
      </IconS>
      {isModal ? <ConfirmDelete handleClose={() => setModal(!isModal)} handleConfirm={ handleConfirm } /> : null}
    </CardS>
  );
};
