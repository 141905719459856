import React from 'react';
import s from './styles'; 
import { useDispatch } from 'react-redux';
import { LoginUser, ReqChangePasswd, ActivateEmail } from '../../store/user/actions';
import { useLocation, useNavigate } from 'react-router-dom';
import { Header, Footer } from './components';
import { DefaultInput } from '../ui';
import GoogleAuthButton from './components/GoogleAuthButton';
import FaceBookAuthButton from './components/FaceBookAuthButton';
import FaceBookAuthButtonFB from './components/FaceBookAuthButtonFB';

import './styles.css';
import { FirstLink } from './FirstLink';

export const Login: React.FC = () => {
  const [email, setEmail] = React.useState('');
  const [link, setLink] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [isPasswd, setIsPasswd] = React.useState(0);
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [errorVerify, setErrorVerify] = React.useState('');
  const [errorVerifySend, setErrorVerifySend] = React.useState(false);

  const [redirect, setRedirect] = React.useState('');
  const [appId, setAppId] = React.useState('');
  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);
  const isFacebookApp = navigator.userAgent.indexOf("FBAN") !== -1 || navigator.userAgent.indexOf("FBAV") !== -1;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useLocation();
  React.useEffect(() => {
    if (params?.search) {
      let searchParams: URLSearchParams = new URLSearchParams(params.search);
      let redirectParams: URLSearchParams | undefined;
      let redirectUrl: URL | undefined;

      const link = searchParams.get('link');

      if(link) {
        setLink(link);
      }  

      const utmParams: string[] = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];

      Array.from(searchParams.entries()).forEach(pair => {
        if (pair[0] === 'redirectUrl') {
          redirectUrl = new URL(decodeURIComponent(pair[1]));
          redirectParams = new URLSearchParams(redirectUrl.search);
        } else if (pair[0] === 'app') {
          setAppId(pair[1]);
        } else if (pair[0] === 'email') {
          setEmail(pair[1]);
        }

        if (utmParams.includes(pair[0]) && redirectParams) {
          redirectParams.set(pair[0], pair[1]);
        }
      });

      if (redirectUrl && redirectParams) {
        redirectUrl.search = redirectParams.toString();
        setRedirect(redirectUrl.toString());
      }
    }
  }, [params.search]);

  const handleChangeEmail = (value: string) => {
    const _value = value.replace(/ /g, '').replace(/\t/g, '').replace(/\u2003/g, '');
    setError('');
    setEmail(_value);
  };

  const handleClick = () => {
    if (!email) return setError('Поле пошта обов’язкове');
    if (!password) return setError('Поле пароль обов’язкове');
    setLoading(true);
    dispatch(
      LoginUser.request({
        data: {
          email,
          password,
        },
        callBack: (succ, data) => {
          setLoading(false);
          if (!succ && data.message) {
            if (!succ && data.message && data.notVerify) {
              setErrorVerify(data.notVerify);
            } else {
              setError(data.message);
            }
          }
          if (succ) {
            if (!isFacebookApp) {
              if(link)  {
                navigate(`/main?link=${link}`);
              }
              else {
                navigate(`/main`);
              }

            }
          }
        },
      })
    );
  };
  const setEmailVerify = () => {
    dispatch(
      ActivateEmail.request({
        email: errorVerify || '0',
        callBack: (succ) => {
          if (succ) {
            setErrorVerify('')
            setErrorVerifySend(true)
          } else {
          }
        },
      })
    )
  };
  const handleRemember = () => {
    if (!email) return setError('Поле пошта обов’язкове');
    setLoading(true);
    dispatch(
      ReqChangePasswd.request({
        data: {
          email,
        },
        callBack: (success, data) => {
          setLoading(false);
          if (!success && data.message) {
            setError(data.message);
          } else {
            setIsPasswd(1);
          }
        },
      })
    );
  };
  // const isFacebookAndorid = navigator.userAgent.indexOf("FBAV") !== -1
  // const isFacebookIphone = navigator.userAgent.indexOf("FBAN") !== -1

  return (
    <s.WrappS
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleClick();
        }
      }}
    >
      <Header />
      <s.WrapperS>
        <s.FormS>
          <s.MainS>
            <s.TitleS>Вхід на сайти Цифрового видавництва Експертус</s.TitleS>
            <s.WrapperCascadeS>
              <s.Cascade1S>
                <DefaultInput
                  styleWrap={{ padding: 0 }}
                  type="email"
                  placeholder="Ел. пошта"
                  handleChange={(value) => handleChangeEmail(value)}
                  value={email}
                />
                <s.Cascade6S></s.Cascade6S> 
                <DefaultInput
                  styleGroup={{ margin: 0 }}
                  styleWrap={{ padding: 0 }}
                  type={isPasswordVisible ? "text" : "password"}
                  placeholder="Пароль"
                  handleChange={(value) => {
                    setError('');
                    setPassword(value);
                  }}
                  value={password}
                  error={error}
                />
                <s.FieldEye onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
                  {isPasswordVisible ? (
                    <img src="https://dev.id3.expertus.com.ua/media/images/eye_passw.png" alt="" />

                  ) : (
                    <img src="https://dev.id3.expertus.com.ua/media/images/hidden.png" alt="" />

                  )}
                </s.FieldEye>
                {errorVerify ? (
                  <div style={{ color: 'red' }}>
                    E-mail не підтверджено.
                    <a href='/' onClick={(e) => {
                      e.preventDefault()
                      setEmailVerify()
                    }}> Надіслати </a>
                    лист для підтвердження
                  </div>
                ) : null}
                {errorVerifySend ? (
                  <div>
                    На Ваш e-mail відправлено лист. Перейдіть за посиланням з нього.
                  </div>
                ) : null}
                {!isPasswd ? <s.RememberS onClick={handleRemember}>нагадати</s.RememberS> : null}
                {isPasswd === 1 ? (
                  <s.EneredS onClick={() => setIsPasswd(0)}>
                    <div>Лист відправлено, перевірте пошту</div>
                    <s.EneredBtnS>ввести</s.EneredBtnS>
                  </s.EneredS>
                ) : null}
              </s.Cascade1S>
              <s.Cascade2S />
              <s.Cascade3S>
                <FirstLink redirect = { redirect } appId = { appId } />
              </s.Cascade3S>
            </s.WrapperCascadeS>
            <s.ButtonSWrap>
              <s.ButtonS disabled={loading || !!error} onClick={handleClick}>
                Увійти
              </s.ButtonS>
              <s.btnWrapSoc>
                <>
                  {isFacebookApp? (
                    <FaceBookAuthButtonFB appId={appId} setError={setError} isLogin={true} redirect={encodeURIComponent(redirect)} />
                  ) : (
                    <>
                      <GoogleAuthButton appId={appId} setError={setError}  isLogin={true} redirect={encodeURIComponent(redirect)} />
                      <FaceBookAuthButton appId={appId} setError={setError}isLogin={true} redirect={encodeURIComponent(redirect)} />

                    </>
                  )}

                </>
              </s.btnWrapSoc>
            </s.ButtonSWrap>
            <s.Cascade2S />
            <s.Cascade4S>
              <FirstLink redirect = { redirect } appId = { appId } />
            </s.Cascade4S>
          </s.MainS>
          <s.FooterS>
            <s.WrapperLogoS />© Цифрове видавництво Експертус
          </s.FooterS>
        </s.FormS>
      </s.WrapperS>
      <Footer />
    </s.WrappS>
  );
};