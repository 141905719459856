import styled from 'styled-components';

const WrapperS = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
`;

const WrapperItemS = styled.div`
  width: 100%;
`;

const WrapperMainS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
  flex-shrink: 0;
  padding 0 64px;
`;
const WrapperMain2S = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
  flex-shrink: 0;
`;

// eslint-disable-next-line
export default {
  WrapperS,
  WrapperItemS,
  WrapperMainS,
  WrapperMain2S
};
