import React from 'react';

export const FlowerpotIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="75"
      height="121"
      viewBox="0 0 75 121"
      fill="none"
      className="injected-svg"
      data-src="https://cdn.mcfr.ua/prod/lk/lk-frontend/acf9975f/assets/617b41d481663816d0f68c835fbbe28f.svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M40.7718 68.6804C53.1138 59.3355 62.7814 16.5527 50.534 5.43312C40.3514 -3.81098 32.1607 14.3871 30.5225 22.8773C27.8946 36.4665 28.7385 51.1308 28.7339 64.9186"
        fill="#C1BDBA"
      ></path>
      <path
        d="M36.3918 67.1756C36.3949 55.1004 39.319 26.4979 24.3755 18.2575C14.5078 12.8156 10.0696 23.3907 9.36221 31.6575C8.33061 43.7264 13.4297 60.1343 20.5904 70.1851"
        fill="#E1DFDD"
      ></path>
      <path
        d="M59.4864 66.0131H4.35387C1.84544 66.0131 -0.0688344 68.2593 0.331396 70.7367L5.35289 70.7569L8.3903 120.71H55.4515L58.4889 70.7569L63.5104 70.7367C63.9107 68.2593 61.9964 66.0131 59.4864 66.0131Z"
        fill="#F2F1ED"
      ></path>
      <path d="M5.35938 70.7544H58.497L58.1449 76.5298H5.71152L5.35938 70.7544Z" fill="#E1DFDD"></path>
      <path
        d="M52.6944 20.9847C51.2424 14.0613 62.4039 -2.44433 70.576 0.306091C74.9956 1.79222 75.3323 8.9064 73.6957 12.4899C70.4488 19.6009 61.4824 20.7644 52.6944 20.9847Z"
        fill="#C1BDBA"
      ></path>
    </svg>
  );
};
