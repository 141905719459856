import React from 'react';
import { Newsletters } from '../components/content';

export const NewslettersFrame: React.FC = () => {
  const isFrame = window !== window.parent;
  if(!isFrame) return null;
  return (
        <Newsletters isFrame={true}/>
  );
};
