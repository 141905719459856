export interface TProductsState {
  data: IProduct[] | null;
  readonly loading: boolean;
  isAuth?: boolean;
  readonly errors?: string | undefined;
}

export interface IProduct {
  absnum: number;
  name: string;
  subName: string;
  parent_id: number;
  dateStart: Date;
  dateEnd: Date;
  approved: number;
  url: string;
  children: IProduct[];
}

export enum ActionTypes {
  GET_PRODUCTS_R = '@@products/GET_PRODUCTS_R',
  GET_PRODUCTS_S = '@@products/GET_PRODUCTS_S',
  GET_PRODUCTS_E = '@@products/GET_PRODUCTS_E',

  ACTIVATE_PRODUCT_R = '@@products/ACTIVATE_PRODUCT_R',
  ACTIVATE_PRODUCT_S = '@@products/ACTIVATE_PRODUCT_S',
  ACTIVATE_PRODUCT_E = '@@products/ACTIVATE_PRODUCT_E',
}
