import React from 'react';
import s from './styles';
import sc from './stylesCompany';
import { DefaultInput } from '../../ui';
import { Trash } from '../../svgs/Trash';
import { TCompany } from './TCompany';
import { API_URL } from '../../../config';
import { TOKEN } from '../../../config';

type Props = {
    isSucc: boolean,
    company: TCompany
    companies: TCompany[]
    setCompany: Function
    setCompanies: Function
    setCompaniesError: Function
    setIsSucc: Function

}

export const Companies = (props: Props) => {
    const { isSucc, company, setCompany, companies, setCompanies, setCompaniesError, setIsSucc } = props;
    const [disabled, setDisabled] = React.useState(false);
    const [showNewCompany, setShowNewCompany] = React.useState<boolean>(false);
    const [error, setError] = React.useState<{ [x: string]: string }>({});

    React.useEffect(() => {
        if (isSucc) {
            setDisabled(false);
            setShowNewCompany(false);
        }
    }, [isSucc]);

    const addCompany = () => {
         setIsSucc(false);

        if (!disabled) {
            }
            setDisabled(true);
            setShowNewCompany(true);
    }
    const delCompany = async (item: TCompany, index: number) => {
        const shouldDelete = window.confirm("Ви впевнені, що хочете видалити цю компанію?");
        if (shouldDelete) {
            if (index >= 0 && index < companies.length) {
                const token = localStorage.getItem(TOKEN.access);
    
                const _list = [...companies.slice(0, index), ...companies.slice(index + 1)];
                setCompanies(_list);
                await fetch(`${API_URL}/users/delete-user-company`, { 
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': token || '',
                    },
                    body: JSON.stringify(item),
                })
                .then((res) => res ? res.json() : null);
            }
        }
    }
    
    const isValidNumber = (input: string): boolean => {
        // Перевірка, чи рядок містить лише цифри і має довжину 8 або 10 символів
        const regex = /^\d{8}$|^\d{10}$|^\d{12}$/;
        const test = regex.test(input);
        // Перевірка, чи є вже такий edpou в companies
        const find = test ? companies.find((item)=>item.edpou.toString() === input) : null;
        
        return test && !find;
    };

    const handleChangeErrorEderpou = (name: string, value: string) => {
        const str = value
        .replace(/[^0-9.]/g, '')
        .replaceAll('.', '');
            const isValid = isValidNumber(str);
            const obj = { ...error };
            if (isValid) {
                delete obj[`${name}`];
            
            } else {
                obj[`${name}`] = `error`;
                setCompaniesError(true);

            }
            setError(obj); // Знищуємо помилку, коли користувач вводить дані

        setValue('edpou', str);
    }
    const handleChangeError = (name: string, value: string) => {

            const obj = { ...error };
            if (value) {
                delete obj[`${name}`];
            
            } else {
                obj[`${name}`] = `error`;
                setCompaniesError(true);
            }
            setError(obj); // Знищуємо помилку, коли користувач вводить дані

        setValue('company', value);
    }

    const setValue = (name: string, value: string) => {
        const _company = {...company};
        _company[name] = value;
        setCompany(_company);

        let ed = name === 'edpou' ? value : company.edpou;
        let comp = name === 'company' ? value : company.company;
        const isValid = isValidNumber(ed);
        if(comp && isValid){
               setCompaniesError(false);
        }
    }
    const listJSX = companies.length > 0 ? companies.map((item, index) => {
        return (
            <sc.WrapS key={index}>
                <sc.InputS>
                    {item.edpou}
                </sc.InputS>
                <sc.InputS>
                    {item.company}
                </sc.InputS>
                <sc.IconS onClick={() => delCompany(item, index)}>
                    <Trash color='#ed3545' />
                </sc.IconS>
            </sc.WrapS>
        )
    }) : null;
    return (
        <>
            <s.TabsS>
                <s.LinkWrapperS selected>
                    <a href="#companies">Організація</a>
                </s.LinkWrapperS>
            </s.TabsS>
            <s.ProfileFormS>
                <s.LeftColumS>
                    {listJSX}
                    {showNewCompany && (
                        <sc.WrapS>
                            <DefaultInput
                                maxLength = {12}
                                styleGroup={{ margin: '5px 0px 0px' }}
                                styleWrap={{ padding: 0 }}
                                placeholder="ЄДРПОУ/ІПН"
                                type='text'
                                value={company.edpou}
                                handleChange={(value) => {
                                    handleChangeErrorEderpou('edpou', value);
                                }}
                                style={error[`edpou`] ? { border: '1px solid var(--redColor6)' } : {}}
                            />
                            <DefaultInput
                                styleGroup={{ margin: '5px 0px 0px' }}
                                styleWrap={{ padding: 0 }}
                                placeholder="Назва компанії"
                                type='text'
                                value={company.company}
                                handleChange={(value) => {
                                    setValue('company', value);
                                    handleChangeError('company', value);
                                    
                                }}
                                style={error['company'] ? { border: '1px solid var(--redColor6)' } : {}}
                            />
                            {error[`edpou`] && (
                                <sc.InfoS title='Код неправильний'>
                                    !
                                </sc.InfoS>
                            )}
                              {error.company && (
                                <sc.InfoС title='Поле обовʼязкове для заповнення'>
                                    !
                                </sc.InfoС>
                            )}
                        </sc.WrapS>
                    )}
                    <sc.BtnS disabled={disabled} onClick={addCompany}>
                        <sc.BtnContentS>Додати організацію</sc.BtnContentS>
                    </sc.BtnS>
                </s.LeftColumS>
            </s.ProfileFormS>
            <div style={{ width: '100%', height: '20px' }}></div>
        </>
    );
}