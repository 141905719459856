import { Reducer } from 'redux';
import { ActionTypes, TUserState } from './types';

export const initialState: TUserState = {
  userProfile: null,
  loading: false,
  data: null,
  isAuth: undefined,
  errors: undefined,
};

const userReducer: Reducer<TUserState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_USER:
      return { ...state, data: action.payload };

    case ActionTypes.ACTIVATE_EMAIL_R:
    case ActionTypes.GET_USER_PROFILE_R:
    case ActionTypes.UPDATE_USER_PROFILE_R:
    case ActionTypes.CHECK_ACCESS_R:
    case ActionTypes.LOGIN_USER_R:
    case ActionTypes.FB_USER_R:
    case ActionTypes.GOOGLE_USER_R:
    case ActionTypes.SOCIAL_DATA_R:
    case ActionTypes.REGISTRATION_USER_R:
    case ActionTypes.CHANGE_PASSWD_R:
    case ActionTypes.CHECK_USER_PROFILE_R:  
      return { ...state, loading: true, errors: undefined };
    case ActionTypes.SET_IS_AUTH:
      return { ...state, loading: false, errors: undefined, isAuth: action.payload };

    case ActionTypes.CHECK_ACCESS_S:
    case ActionTypes.LOGIN_USER_S:
      return { ...state, loading: false, errors: undefined, data: action.payload, isAuth: true };
    case ActionTypes.FB_USER_S:
      return { ...state, loading: false, errors: undefined, data: action.payload };
    case ActionTypes.GOOGLE_USER_S:
      return { ...state, loading: false, errors: undefined, data: action.payload};
    case ActionTypes.SOCIAL_DATA_S:
      return { ...state, loading: false, errors: undefined, data: action.payload};
    case ActionTypes.CHANGE_PASSWD_S:
    case ActionTypes.ACTIVATE_EMAIL_S:
    case ActionTypes.REGISTRATION_USER_S:
      return {
        ...state,
        loading: false,
        errors: undefined,
      };

    case ActionTypes.GET_USER_PROFILE_S:
    case ActionTypes.UPDATE_USER_PROFILE_S:
      return { ...state, loading: false, errors: undefined, userProfile: action.payload };

    case ActionTypes.ACTIVATE_EMAIL_E:
    case ActionTypes.GET_USER_PROFILE_E:
    case ActionTypes.UPDATE_USER_PROFILE_E:
    case ActionTypes.REGISTRATION_USER_E:
    case ActionTypes.REQ_CHANGE_PASSWD_E:
    case ActionTypes.CHANGE_PASSWD_E:
    case ActionTypes.CHECK_TOKEN_E:
    case ActionTypes.CHECK_USER_PROFILE_E:
      return { ...state, loading: false, errors: action.payload };

    case ActionTypes.CHECK_ACCESS_E:
    case ActionTypes.LOGIN_USER_E:
      return { ...state, loading: false, errors: action.payload, isAuth: false };
    case ActionTypes.FB_USER_E:
      return { ...state, loading: false, errors: action.payload};
    case ActionTypes.GOOGLE_USER_E:
      return { ...state, loading: false, errors: action.payload };
    case ActionTypes.SOCIAL_DATA_E:
      return { ...state, loading: false, errors: action.payload };
    case ActionTypes.CHECK_USER_PROFILE_S:
        return { ...state, loading: false, errors: undefined, data: action.payload };

    default:
      return state;
  }
};

export { userReducer as UserReducer };
