import React from 'react';
import styled from 'styled-components';
import { Warning } from '../svgs/Warning';

const ModalS = styled.div`
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
`;
const WrapperS = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const ModalInnerS = styled.div`
  max-width: 300px;
  width: 300px;
  background: #fff;
  box-shadow: 0 12px 40px rgb(20 21 25 / 30%), 0 4px 12px rgb(20 21 25 / 20%);
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ContentS = styled.div`
  padding-bottom: 32px;
  overflow: visible;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  flex: 1;
  padding: 16px;
`;


const BtnwrapS = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;   
`;


const BtnS = styled.button`
  background-color: var(--blueColor);
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  height: 37px;
  display: flex;
  width: 120px;
  flex-wrap: nowrap;
  justify-content: center;
  outline: none;
  text-align: center;
  white-space: nowrap;
  appearance: none;
  border: 1px solid transparent;
  border-radius: 3px;
  box-sizing: border-box;
  cursor: pointer;
  appearance: none;
  align-items: center;
`;


const CancelS = styled(BtnS)`
  color: var(--blueColor);
  background-color: #F0F8FF;;
`;

const IconS = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TextS = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #424242;
  padding: 15px;
`;

interface PropsType {
  handleConfirm: () => void;
  handleClose: () => void;
}

export const ConfirmDelete: React.FC<PropsType> = ({ handleConfirm, handleClose }) => {
  return (
    <ModalS>
      <WrapperS>
        <ModalInnerS>
          <ContentS>
            <IconS >
              <Warning />
            </IconS>
            <TextS >
              Видалити закладку?
            </TextS>
            <BtnwrapS>
              <BtnS onClick={handleConfirm}>
                Так
              </BtnS>
              <CancelS onClick={handleClose}>
                Скасувати
              </CancelS>
            </BtnwrapS>
          </ContentS>
        </ModalInnerS>
      </WrapperS>
    </ModalS>
  );
};
