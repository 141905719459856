import React from 'react';
import { IBookmarkProduct } from '../../../store/bookmarks/types';

import styled, { ThemeProvider } from 'styled-components';

const ProductNameWrapS = styled.div`
  font-size: 18px;
  line-height: 1.3;
  color: ${({ theme }) => (theme.isActive || theme.isHovered ? '#004889' : '#616161')};
  padding: 14px 15px;
  border-radius: 5px;
  cursor: pointer;
  background: ${({ theme }) => (theme.isActive || theme.isHovered ? '#F0F8FF' : 'transparent')};
  display: flex;
  alignItems: center;
  justify-content: space-between;
  min-height: 50px;
`;

const ProductNameS = styled.div`
  display: flex;
  align-items: center;
`;

const ProductNameCountS = styled.div`
  color:  ${({ theme }) => (theme.isActive || theme.isHovered ? '#FFFFFF' : '#9E9E9E')};
  padding: 0;
  border: 1px solid ${({ theme }) => (theme.isActive || theme.isHovered ? '#AAC7E1' : '#E0E0E0')};
  background-color: ${({ theme }) => (theme.isActive || theme.isHovered ? '#AAC7E1' : '#EDEDED')};
  padding: 1px 7px;
  border-radius: 20px;
  font-size: 11px;
  display: flex;
  align-items: center;
  height: 22px;
`;

interface PropsType {
  currentProduct?: IBookmarkProduct;
  product: IBookmarkProduct;
  setCurrentProduct:  (product: IBookmarkProduct) => void;
  isNew: number;
}

export const BookmarkProduct: React.FC<PropsType> = ({currentProduct, product, setCurrentProduct, isNew}) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const [isActive, setIsActive] = React.useState(false);

  const theme = {
    isHovered,
    setIsHovered,
    isActive,
    setIsActive
  };

  React.useEffect(() => {
    if (currentProduct && currentProduct.absnum === product.absnum) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [currentProduct, product]);

  return (
    //@ts-ignore
    <ThemeProvider theme={theme}>
      <ProductNameWrapS
        key={product.absnum}
        onClick={() => setCurrentProduct(product)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
          <ProductNameS>
            {product.name}
          </ProductNameS>
          {isNew > 0 && (
            <ProductNameCountS>
              {isNew}
            </ProductNameCountS>                          
          )}
      </ProductNameWrapS>
    </ThemeProvider>
  );
};
