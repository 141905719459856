import React from 'react';

export const QuestionIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className="injected-svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3Z"
        stroke="#6B6861"
        strokeWidth="1.5"
        strokeLinecap="round"
      ></path>
      <path
        d="M9.25683 12.4762V14H10.701V12.4762H9.25683ZM10.701 11.2229C11.2813 11.0383 11.7795 10.64 12.1048 10.1004C12.4302 9.56079 12.561 8.91585 12.4735 8.28302C12.386 7.65019 12.086 7.07157 11.6282 6.65254C11.1703 6.2335 10.585 6.00193 9.97891 6C9.39464 5.99995 8.82839 6.21345 8.37646 6.60419C7.92453 6.99492 7.61482 7.53877 7.5 8.14324L8.91673 8.44267C8.95693 8.23042 9.05343 8.03459 9.19499 7.87796C9.33655 7.72132 9.51736 7.61032 9.71638 7.55787C9.91539 7.50542 10.1244 7.51368 10.3192 7.58169C10.5139 7.6497 10.6863 7.77466 10.8164 7.94203C10.9465 8.1094 11.0288 8.3123 11.0539 8.52712C11.0789 8.74194 11.0456 8.95985 10.9579 9.15549C10.8701 9.35114 10.7316 9.51647 10.5583 9.63225C10.3849 9.74803 10.184 9.80951 9.97891 9.80952C9.7874 9.80952 9.60374 9.8898 9.46832 10.0327C9.3329 10.1756 9.25683 10.3694 9.25683 10.5714V11.7143H10.701V11.2229Z"
        fill="#6B6861"
      ></path>
    </svg>
  );
};
