import React from 'react';

export const Warning: React.FC = () => {
  return (
  <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M28.5 21C29.4665 21 30.25 21.7835 30.25 22.75V31.5C30.25 32.4665 29.4665 33.25 28.5 33.25C27.5335 33.25 26.75 32.4665 26.75 31.5V22.75C26.75 21.7835 27.5335 21 28.5 21Z" fill="black"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M25.8773 5.95488C26.6741 5.4928 27.5789 5.24945 28.5 5.24945C29.4211 5.24945 30.3259 5.4928 31.1227 5.95488C31.9182 6.41617 32.5779 7.07909 33.0353 7.87673C33.0361 7.87808 33.0368 7.87943 33.0376 7.88079L52.2843 41.125C52.7443 41.9217 52.9869 42.8254 52.9877 43.7454C52.9885 44.6654 52.7475 45.5694 52.2889 46.367C51.8303 47.1645 51.1702 47.8276 50.3747 48.2897C49.5792 48.7518 48.6762 48.9967 47.7562 49L47.75 49H9.25L9.24384 49C8.32384 48.9967 7.42085 48.7518 6.62532 48.2897C5.82979 47.8276 5.16967 47.1645 4.71107 46.367C4.25248 45.5694 4.01152 44.6654 4.01233 43.7454C4.01315 42.8254 4.25571 41.9217 4.71571 41.125L23.9624 7.88079C23.9632 7.87943 23.9639 7.87808 23.9647 7.87673C24.4221 7.07908 25.0818 6.41616 25.8773 5.95488ZM25.4812 8.75L26.9957 9.62682L7.74679 42.875C7.74666 42.8752 7.74692 42.8748 7.74679 42.875C7.59371 43.1404 7.5126 43.4421 7.51233 43.7485C7.51206 44.0551 7.59238 44.3565 7.74524 44.6223C7.89811 44.8882 8.11815 45.1092 8.38333 45.2632C8.64781 45.4169 8.94794 45.4985 9.25378 45.5H47.7462C48.0521 45.4985 48.3522 45.4169 48.6167 45.2632C48.8819 45.1092 49.1019 44.8882 49.2548 44.6223C49.4076 44.3565 49.4879 44.0551 49.4877 43.7485C49.4874 43.4422 49.4068 43.1414 49.2538 42.876C49.2536 42.8757 49.254 42.8764 49.2538 42.876L29.9999 9.61923C29.8486 9.35498 29.6303 9.13536 29.3669 8.98262C29.1035 8.82989 28.8045 8.74945 28.5 8.74945C28.1955 8.74945 27.8965 8.82989 27.6331 8.98262C27.3697 9.13536 27.1513 9.35497 27.0001 9.61922L25.4812 8.75Z" fill="#CD3333"/>
    <path d="M28.5 42C29.9497 42 31.125 40.8247 31.125 39.375C31.125 37.9253 29.9497 36.75 28.5 36.75C27.0503 36.75 25.875 37.9253 25.875 39.375C25.875 40.8247 27.0503 42 28.5 42Z" fill="black"/>
  </svg>
  );
};
