import React from 'react';
import s from './styles';
import { NewslettersItem } from './item';
import { INewsletter } from '../../../store/newsletters/types';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../../store/applicationState';
import { GetNewsletters, SetNewsletters } from '../../../store/newsletters/actions';
type Props = {
  isFrame?: boolean;
}
export const Newsletters = (props: Props) => {
    const {isFrame} = props;
    const { User, Newsletters } = useSelector((store: AppStore) => store);
    const [status, setStatus] = React.useState(false);
    const [isSucc, setIsSave] = React.useState(false);
    const [isError, setError] = React.useState('');

    const [list, setList] = React.useState<INewsletter[]>();
    const [defList, setDefList] = React.useState<INewsletter[]>();
    const dispatch = useDispatch();

    React.useEffect(() => {        
        if (User.isAuth && User.userProfile) {
          dispatch(
            GetNewsletters.request({
              data: {
                userId: User.userProfile.id,
              },
            })
          );
        }
    }, [User, dispatch]);

    React.useEffect(() => {
        if (Newsletters && Newsletters.data && Newsletters.data.list) {
            setList(Newsletters.data.list);
            setDefList(Newsletters.data.list);
        }
    }, [Newsletters]);

    const handleChange = (item: INewsletter) => {
        setIsSave(false);
        setError('');
        if(list && defList){
            let diff = false;
            const _list = list.map((el)=>{
                return el.key === item.key ? item : el;
            })
            setList(_list);
            for(const el of defList){
                const _item = _list.find((i)=>i.key === el.key);
                if(_item){
                    if(el.key === _item.key && el.status !== _item.status){
                        diff = true;
                    }
                }
            }
            
            if(diff){
                setStatus(true);
            } else {
                setStatus(false);
            }            
        }
    }

    const handleSave = () => {
        if (list && User.userProfile) {
          dispatch(
            SetNewsletters.request({
                data: {
                  userId: User.userProfile.id,
                  list: list
                },
                callBack: (success) => {
                    console.log(success, 'success')
                    if (!success) setError('Сталася помилка');
                    if (success) {
                        setIsSave(true);

                    }
                  },
            })
          );
          setStatus(false);
          setDefList(list);
        }
    }
    const listJSX = defList ? defList.map((item)=>{
        return(
            <NewslettersItem 
                key = { item.key} 
                item = { item } 
                handleChange = { handleChange }
            />
        )
    }) : null;

  return (
    <s.WrapS className={isFrame ? 'frame' : ''}>
      <s.MainS>
        <s.TitleS>Мої розсилки</s.TitleS>
        <s.InfoS>
          {
            <>Ви будете отримувати розсилки на e-mail вказаний у розділі «<a href="/profile" target="_blank">Про мене</a>».</>
          }

        </s.InfoS>
        <s.LineS className={'line'}/>
        {listJSX}

        {<s.BtnS
          className={'newsletters-save'}
          onClick={handleSave}
          status={status}>
          Зберегти налаштування
        </s.BtnS>}
        {isSucc ? <s.SuccS>Дані збережено</s.SuccS> : null}
        {isError ? <s.ErrorS>Сталася помилка</s.ErrorS> : null}
      </s.MainS>
    </s.WrapS>
  );
};
