import React from 'react';
import { Header, Footer } from '../components/Auth/components';
import s from '../components/Auth/styles';

export const Private: React.FC = () => {
  return (
    <div>
      <Header />
      <s.WrapperS>
        <div className="content">
          <h2>Положення про порядок обробки і захисту персональних даних користувачів сайту</h2>
          <p>
            Оскільки Цифрове видавництво Експертус в рамках здійснення своєї діяльності та для забезпечення належного рівня співпраці оброблює персональні дані
            користувачів сайту: <a href="https://id3.expertus.com.ua">https://id.expertus.com.ua</a>,
          </p>
          <p>Оскільки персональні дані є інформацію з обмеженим доступом,</p>
          <p>Оскільки обробка персональних даних допускається лише за згоди суб’єкта персональних даних, крім випадків, встановлених законом,</p>
          <p>
            Оскільки Цифрове видавництво Експертус бажає належним чином здійснювати всі необхідні дії з персональними даними, дотримуючись положень
            законодавства,
          </p>
          <p>
            Керуючись положенням Закону України «Про захист персональних даних» від 1 червня 2010 року № 2297-VI, Закону України «Про інформацію» від
            2 жовтня 1992 року № 2657-XII, Типового порядку обробки персональних даних затвердженого Наказом Уповноваженого Верховної Ради України з
            прав людини від 08.01.2014 № 1/02-14 (надалі – «Порядок») та іншими законодавчими актами,
          </p>
          <p>
            Цифрове видавництво Експертус затверджує це Положення про порядок обробки і захисту персональних даних користувачів сайту (надалі – «Положення») про
            наступне:
          </p>
          <ol>
            <li>
              <h3>1. Терміни та визначення</h3>
              <p>
                <strong>Сайт</strong> — сукупність програмних і апаратних засобів для ЕОМ, які забезпечують публікацію для загального огляду
                інформації і даних, об'єднаних загальним цільовим призначенням, за допомогою технічних засобів, що використовуються для зв'язку між
                ЕОМ в мережі Інтернет. Під Сайтом в Положенні слід розуміти Сайт, на якому розташована Єдина Система Реєстрації і Авторизації
                користувачів (яка об’єднує усі сайти Цифрового видавництва Експертус), що знаходиться в мережі Інтернет за адресою:
                <a href="https://id3.expertus.com.ua">https://id.expertus.com.ua</a>. Сайт є об’єктом авторського права і в якості складного твору, а
                також «компіляції даних» («бази даних»), охороняється згідно із Законом України «Про авторське право і суміжні права»;
              </p>
              <p>
                <strong>Користувач</strong> — користувач мережі Інтернет, зокрема, Сайту та який має свою особисту сторінку (профіль/аккаунт) та є
                суб’єктом персональних даних;
              </p>
              <p>
                <strong>Профіль/аккаунт</strong> — особиста сторінка Користувача на Сайті;
              </p>
              <p>
                <strong>Персональні дані</strong> — відомості чи сукупність відомостей про фізичну особу користувача Сайту, яка ідентифікована або
                може бути конкретно ідентифікована;
              </p>
              <p>
                <strong>Власник сайту</strong> — це організація, яка самостійно або спільно з іншими особами визначає мету обробки персональних даних,
                встановлює склад цих даних та процедури їх обробки, якщо інше не визначено законодавством України та є володільцем/розпорядником
                персональних даних Користувачів Сайту. Власником сайту є Цифрове видавництво Експертус, 02002, м. Київ, вул. Раїси Окіпної, 4-Б;
              </p>
              <p>
                <strong>База персональних даних</strong> — іменована сукупність упорядкованих персональних даних в електронній формі;
              </p>
              <p>
                <strong>Обробка персональних даних</strong> — будь-яка дія або сукупність дій, таких як збирання, реєстрація, накопичення, зберігання,
                адаптування, зміна, поновлення, використання і поширення (розповсюдження, реалізація, передача), знеособлення, знищення персональних
                даних, у тому числі з використанням інформаційних (автоматизованих) систем;
              </p>
              <p>
                <strong>Автоматизована обробка персональних даних</strong> — обробка персональних даних за допомогою засобів обчислювальної техніки;
              </p>
              <p>
                <strong>Поширення персональних даних</strong> — дії щодо передачі відомостей про фізичну особу за згодою суб'єкта персональних даних;
              </p>
              <p>
                <strong>Знищення персональних даних</strong> — дії, в результаті яких стає неможливим відновити зміст персональних даних в
                інформаційній системі персональних даних та/або в результаті яких знищуються матеріальні носії персональних даних;
              </p>
              <p>
                <strong>Знеособлення персональних даних</strong> — вилучення відомостей, які дають змогу прямо чи опосередковано ідентифікувати особу;
              </p>
              <p>
                <strong>Інформаційна система персональних даних (ІСПДн)</strong> — сукупність персональних даних, що міститься в базах даних, та
                сукупність інформаційних технологій і технічних засобів, що забезпечують їх обробку;
              </p>
              <p>
                <strong>Транскордонна передача персональних даних</strong> — передача персональних даних на територію іноземної держави органу влади
                іноземної держави, іноземній фізичній особі або іноземній юридичній особі.
              </p>
            </li>
            <li>
              <h3>2. Загальні положення</h3>
              <ol>
                <li>
                  2.1. Положення про порядок обробки і захисту персональних даних користувачів Сайту (надалі – «Положення») розроблено з метою
                  дотримання вимог законодавства України, що містять персональні дані і ідентифікації Користувачів, які знаходяться на Сайті.
                </li>
                <li>
                  2.2. Положення розроблено відповідно до Конституції України, Закону України «Про захист персональних даних» від 01 червня 2010 року
                  № 2297-VI, Закону України «Про інформацію» від 02 жовтня 1992 року № 2657-XII, Типового порядку обробки персональних даних
                  затвердженого Наказом Уповноваженого Верховної Ради України з прав людини від 08.01.2014 № 1/02-14 та чинного законодавства України
                  у сфері захисту персональних даних.
                </li>
                <li>
                  2.3. Положення встановлює порядок обробки персональних даних Користувачів Сайту: збір, реєстрація, накопичення, зберігання,
                  адаптування, зміна, поновлення, використання і поширення (розповсюдження, реалізація, передача), знеособлення, знищення персональних
                  даних з метою надання Користувачу можливості взаємодіяти з Сайтом та порядок їх захисту Власником.
                </li>
                <li>
                  2.4. Положення встановлює обов'язкові для працівників Власника Сайту, які приймають участь в обслуговуванні Сайту, загальні вимоги
                  та правила по роботі з усіма видами носіїв інформації, що містять персональні дані Користувачів Сайту.
                </li>
                <li>
                  2.5. Положення не розглядає питання забезпечення безпеки персональних даних, віднесених в установленому порядку до відомостей, що
                  становлять державну таємницю України.
                </li>
                <li>
                  <strong>2.6. Мета Положення:</strong>
                  <ul>
                    <li>забезпечення вимог захисту прав і свобод людини і громадянина при обробці персональних даних;</li>
                    <li>
                      виключення несанкціонованих дій співробітників Власника Сайту і будь-яких третіх осіб з обробки персональних даних, інших форм
                      незаконного втручання в інформаційні ресурси і локальну обчислювальну мережу Власника Сайту, забезпечення правового і
                      нормативного режиму конфіденційності недокументованої інформації Користувачів Сайту;
                    </li>
                    <li>
                      захист конституційних прав громадян на особисту таємницю, конфіденційність відомостей, що становлять персональні дані, та
                      запобігання виникненню можливої загрози безпеці Користувачів Сайту;
                    </li>
                    <li>виключення можливого розголошення персональних даних.</li>
                  </ul>
                </li>
                <li>
                  <strong>2.7. Принципи обробки персональних даних:</strong>
                  <ul>
                    <li>обробка персональних даних повинна здійснюватися на законній та справедливій основі;</li>
                    <li>
                      обробка персональних даних повинна обмежуватися досягненням конкретних наперед визначених законних цілей. Не допускається
                      обробка персональних даних, несумісна з цілями збору персональних даних;
                    </li>
                    <li>
                      не допускається об'єднання баз даних, що містять персональні дані, обробка яких здійснюється в цілях, несумісних між собою;
                    </li>
                    <li>обробці підлягають тільки персональні дані, які відповідають меті їх обробки;</li>
                    <li>
                      зміст і обсяг оброблюваних персональних даних повинні відповідати заявленим цілям обробки. Оброблювані персональні дані не
                      повинні бути надмірними по відношенню до заявлених цілей їх обробки;
                    </li>
                    <li>
                      при обробці персональних даних повинні бути забезпечені точність персональних даних, їх достатність, а в необхідних випадках і
                      актуальність відносно цілей обробки персональних даних;
                    </li>
                    <li>
                      зберігання персональних даних має здійснюватися не довше, ніж цього вимагають цілі обробки персональних даних, якщо термін
                      зберігання персональних даних не встановлено законодавством України, договором, стороною якого є Користувач;
                    </li>
                    <li>
                      оброблювані персональні дані підлягають знищенню або знеособленню після досягнення цілей обробки або в разі втрати необхідності
                      в досягненні цих цілей, якщо інше не передбачено законодавством України.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>2.8. Умови обробки персональних даних.</strong>
                  <ol>
                    <li>
                      2.8.1. Обробка персональних даних користувачів Сайту здійснюється на підставі Конституції України, Закону України «Про захист
                      персональних даних» від 1 червня 2010 року № 2297-VI, Закону України «Про інформацію» від 2 жовтня 1992 року № 2657-XII,
                      Типового порядку обробки персональних даних затвердженого Наказом Уповноваженого Верховної Ради України з прав людини від
                      08.01.2014 № 1/02-14 та чинного законодавства України у сфері захисту персональних даних.
                    </li>
                    <li>
                      2.8.2. Обробка персональних даних на Сайті здійснюється з дотриманням принципів і правил, передбачених Положенням та
                      законодавством України.
                      <br />
                      Підставами для обробки персональних даних є:
                      <ul>
                        <li>згода Користувача на обробку його персональних даних;</li>
                        <li>
                          дозвіл на обробку персональних даних, наданий володільцю персональних даних відповідно до закону виключно для здійснення
                          його повноважень;
                        </li>
                        <li>
                          укладення та виконання правочину, стороною якого є Користувач або який укладено на користь Користувача чи для здійснення
                          заходів, що передують укладенню правочину на вимогу Користувача;
                        </li>
                        <li>захист життєво важливих інтересів суб'єкта персональних даних;</li>
                        <li>необхідність виконання обов'язку володільця персональних даних, який передбачений законом;</li>
                        <li>
                          необхідність захисту законних інтересів володільця персональних даних або третьої особи, якій передаються персональні дані,
                          крім випадків, коли потреби захисту основоположних прав і свобод Користувача у зв'язку з обробкою його даних переважають
                          такі інтереси.
                        </li>
                      </ul>
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>2.9. Мета обробки персональних даних</strong>.
                  <ol>
                    <li>
                      2.9.1. Обробка персональних даних Користувачів Сайту здійснюється виключно з метою надання Користувачу можливості взаємодіяти з
                      Сайтом.
                    </li>
                    <li>
                      2.9.2. Під відомостями, що становлять персональні дані на Сайті, слід розуміти відомості чи сукупність відомостей про
                      Користувача, який ідентифікований або може бути конкретно ідентифікованим.
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>2.10. Джерела отримання персональних даних Користувачів</strong>.
                  <ol>
                    <li>2.10.1. Джерелом інформації про всі персональні дані Користувача є безпосередньо сам Користувач.</li>
                    <li>
                      2.10.2. Джерелом інформації про персональні дані Користувача є відомості, отримані внаслідок надання Власником Сайту
                      Користувачеві прав користування Сайтом.
                    </li>
                    <li>2.10.3. Персональні дані Користувачів відносяться до конфіденційної інформації з обмеженим доступом.</li>
                    <li>
                      2.10.4. Забезпечення конфіденційності персональних даних не вимагається в разі їх знеособлення, а також щодо загальнодоступних
                      персональних даних.
                    </li>
                    <li>
                      2.10.5. Власник Сайту не має права обробляти персональні дані про расове або етнічне походження, політичні, релігійні або
                      світоглядні переконання, засудження до кримінального покарання, а також даних, що стосуються здоров’я, статевого життя,
                      біометричних або генетичних даних, за винятком випадків, передбачених чинним законодавством України.
                    </li>
                    <li>
                      2.10.6. Власник не має права обробляти персональні дані Користувача про його членство в політичних партіях та професійних
                      спілках, за винятком випадків, передбачених законодавством України.
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>2.11. Способи обробки персональних даних.</strong>
                  <ol>
                    <li>2.11.1. Персональні дані Користувачів Сайту обробляються виключно з використанням засобів автоматизації.</li>
                  </ol>
                </li>
                <li>
                  <strong>2.12. Права суб'єктів персональних даних (Користувачів Сайту).</strong>
                  <ol>
                    <li>
                      2.12.1. Суб'єкт персональних даних має право:
                      <ul>
                        <li>
                          знати про джерела збирання, місцезнаходження своїх персональних даних, мету їх обробки, місцезнаходження або
                          місцезнаходження Власника Сайту або дати відповідне доручення щодо отримання цієї інформації уповноваженим ним особам, крім
                          випадків, встановлених законом;
                        </li>
                        <li>на доступ до своїх персональних даних;</li>
                        <li>пред'являти вмотивовану вимогу Власнику із запереченням проти обробки своїх персональних даних;</li>
                        <li>
                          пред'являти вмотивовану вимогу щодо зміни або знищення своїх персональних даних Власником, якщо ці дані обробляються
                          незаконно чи є недостовірними;
                        </li>
                        <li>
                          на захист своїх персональних даних від незаконної обробки та випадкової втрати, знищення, пошкодження у зв'язку з умисним
                          приховуванням, ненаданням чи несвоєчасним їх наданням, а також на захист від надання відомостей, що є недостовірними чи
                          ганьблять честь, гідність та ділову репутацію фізичної особи;
                        </li>
                        <li>застосовувати засоби правового захисту в разі порушення законодавства про захист персональних даних;</li>
                        <li>вносити застереження стосовно обмеження права на обробку своїх персональних даних під час надання згоди;</li>
                        <li>на захист від автоматизованого рішення, яке має для нього правові наслідки;</li>
                        <li>
                          інші права, передбачені Угодою про умови користування Сайтом, що укладається між Власником та Користувачем (надалі - Угода)
                          та законодавством України.
                        </li>
                      </ul>
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>2.13. Обов'язки Власника Сайту.</strong>
                  <ol>
                    <li>
                      2.13.1. Власник Сайту зобов’язаний виконувати та дотримуватись вимог щодо обробки та захисту персональних даних Користувачів, що
                      встановлені цим Положенням, Угодою, діючим законодавством України.
                    </li>
                    <li>
                      2.13.2. Забороняється прийняття на підставі виключно автоматизованої обробки персональних даних рішень, що породжують юридичні
                      наслідки щодо Користувача або іншим чином зачіпають його права і законні інтереси.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <h3>3. Обробка персональних даних</h3>
              <ol>
                <li>
                  <strong>3.1. Надання згоди Користувачем на обробку його персональних даних.</strong>
                  <ol>
                    <li>
                      3.1.1. Згода Користувача на обробку його персональних даних Власником надається під час реєстрації на Сайті, створення особистої
                      сторінки (профіль/аккаунт) на Сайті, шляхом підтвердження через натиснення на посилання в повідомленні, яке буде відправлено на
                      вказану ним під час реєстрації електронну пошту. Система реєстрації Користувача на Сайті не створює можливості для обробки даних
                      Користувача до отримання Власником Сайту.
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>3.2. Перелік оброблюваних персональних даних користувачів Сайту:</strong>
                  <ul>
                    <li>Прізвище</li>
                    <li>Ім'я</li>
                    <li>Логін</li>
                    <li>Пароль</li>
                  </ul>
                  Реєстраційна форма Сайту може запросити у Користувача іншу додаткову інформацію.
                </li>
                <li>
                  3.3. Облік персональних даних Користувачів.
                  <ol>
                    <li>
                      3.3.1. Власник персональних даних самостійно визначає процедуру збереження інформації про операції, пов'язані з обробкою
                      персональних даних Користувачів та доступом до них. У випадку обробки персональних даних за допомогою автоматизованої системи
                      така система автоматично фіксує вказану інформацію.
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>3.4. Передача персональних даних. Особи, які мають право доступу до персональних даних.</strong>
                  <ol>
                    <li>
                      3.4.1. Правом доступу до персональних даних Користувачів мають особи, наділені відповідними повноваженнями відповідно до своїх
                      трудових/посадових обов'язків.
                    </li>
                    <li>
                      3.4.2. Використання персональних даних працівниками Власника здійснюється лише відповідно до їхніх професійних чи трудових
                      обов'язків. Ці працівники зобов'язані не допускати розголошення у будь-який спосіб персональних даних, які їм було довірено або
                      які стали відомі у зв'язку з виконанням професійних чи трудових обов'язків, крім випадків, передбачених законом. Таке
                      зобов'язання чинне після припинення ними діяльності, пов'язаної з персональними даними, крім випадків, установлених законом.
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>3.5. Заборона обробки персональних даних (їх частини) та/або зміна їх складу/змісту.</strong>
                  <ol>
                    <li>
                      3.5.1. Користувач має право пред'являти вмотивовану вимогу Власнику щодо заборони обробки своїх персональних даних (їх частини)
                      та/або зміни їх складу/змісту. Така вимога розглядається Власником впродовж 10 днів з моменту отримання.
                    </li>
                    <li>
                      <p>
                        3.5.2. Якщо за результатами розгляду такої вимоги виявлено, що персональні дані Користувача (їх частина) обробляються
                        незаконно, Власник припиняє обробку персональних даних Користувача (їх частини) та інформує про це Користувача.
                      </p>
                      <p>
                        Якщо за результатами розгляду такої вимоги виявлено, що персональні дані Користувача (їх частина) є недостовірними, Власник
                        припиняє обробку персональних даних суб'єкта (чи їх частини) та/або змінює їх склад/зміст та інформує про це Користувача.
                      </p>
                    </li>
                    <li>
                      3.5.3. У разі якщо вимога не підлягає задоволенню, Користувачу надається мотивована відповідь щодо відсутності підстав для її
                      задоволення.
                    </li>
                    <li>
                      3.5.4. Власник Сайту зобов'язаний вносити зміни до персональних даних Користувача також за зверненням інших суб'єктів відносин,
                      пов'язаних із персональними даними, якщо на це є згода Користувача чи відповідна зміна здійснюється згідно з приписом
                      Уповноваженого або визначених ним посадових осіб секретаріату Уповноваженого чи за рішенням суду, що набрало законної сили.
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>3.6. Знищення персональних даних.</strong>
                  <ol>
                    <li>
                      3.6.1. Під знищенням персональних даних розуміються дії, в результаті яких стає неможливим відновити зміст персональних даних на
                      Сайті і/або в результаті яких знищуються матеріальні носії персональних даних.
                    </li>
                    <li>
                      3.6.2. Персональні дані підлягають знищенню у разі:
                      <ul>
                        <li>припинення правовідносин між Користувачем та Власником якщо інше не передбачено законом;</li>
                        <li>видання відповідного припису Уповноваженого або визначених ним посадових осіб секретаріату Уповноваженого;</li>
                        <li>набрання законної сили рішенням суду щодо видалення або знищення персональних даних.</li>
                      </ul>
                    </li>
                    <li>
                      3.6.3. Користувач має право в письмовій формі вимагати знищення своїх персональних даних у разі, якщо персональні дані є
                      неповними, застарілими, недостовірними, незаконно отриманими або не є необхідними для заявленої мети обробки.
                    </li>
                    <li>
                      3.6.4. Знищення персональних даних здійснюється у спосіб, що виключає подальшу можливість поновлення таких персональних даних.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <h3>4. Система захисту персональних даних</h3>
              <ol>
                <li>
                  4.1. <strong>Заходи щодо забезпечення безпеки персональних даних при їх обробці.</strong>
                  <ol>
                    <li>
                      4.1.1. Власник Сайту при обробці персональних даних зобов'язаний приймати необхідні правові, організаційні та технічні заходи
                      або забезпечувати їх прийняття для захисту персональних даних від неправомірного або випадкового доступу до них, знищення,
                      перекручення, блокування, копіювання, надання, поширення персональних даних, а також від інших неправомірних дій щодо
                      персональних даних.
                    </li>
                    <li>
                      4.1.2. Забезпечення безпеки персональних даних досягається, зокрема:
                      <ul>
                        <li>визначенням загроз безпеки персональних даних при їх обробці в інформаційних системах персональних даних;</li>
                        <li>
                          застосуванням організаційних і технічних заходів щодо забезпечення безпеки персональних даних при їх обробці в інформаційних
                          системах персональних даних, необхідних для виконання вимог до захисту персональних даних;
                        </li>
                        <li>застосуванням засобів захисту інформації, які пройшли в установленому порядку процедуру оцінки відповідності;</li>
                        <li>
                          оцінкою ефективності вжитих заходів щодо забезпечення безпеки персональних даних до введення в експлуатацію інформаційної
                          системи персональних даних;
                        </li>
                        <li>урахуванням машинних носіїв персональних даних;</li>
                        <li>виявленням фактів несанкціонованого доступу до персональних даних та вжиттям заходів;</li>
                        <li>відновленням персональних даних, модифікованих або знищених внаслідок несанкціонованого доступу до них;</li>
                        <li>
                          контролем за прийнятими заходами щодо забезпечення безпеки персональних даних і рівня захищеності інформаційних систем
                          персональних даних.
                        </li>
                      </ul>
                    </li>
                    <li>
                      4.1.3. Для цілей Положення під загрозою безпеці персональних даних розуміється сукупність умов і факторів, що створюють
                      небезпеку несанкціонованого, в тому числі випадкового, доступу до персональних даних, результатом якого можуть стати знищення,
                      зміна, блокування, копіювання, надання, поширення персональних даних, а також інші неправомірні дії при їх обробці в
                      інформаційній системі персональних даних. Під рівнем захищеності персональних даних розуміється комплексний показник, що
                      характеризує вимоги, виконання яких забезпечує нейтралізацію певних загроз безпеки персональних даних при їх обробці в
                      інформаційних системах персональних даних.
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    <strong>4.2. Відомості про суб'єкта персональних даних, що підлягають захисту.</strong>
                  </p>
                  <p>
                    До відомостями про суб'єкта персональних даних на Сайті, що підлягають захисту, відносяться дані, що дозволяють ідентифікувати
                    суб'єкта персональних даних та/або отримати про нього додаткові відомості, передбачені законодавством та Положенням.
                  </p>
                </li>
                <li>
                  <strong>4.3. Об'єкти персональних даних, шо підлягають захисту.</strong>
                  <ol>
                    <li>
                      4.3.1. До об'єктів персональних на Сайті, що підлягають захисту, відносяться:
                      <ul>
                        <li>об'єкти інформатизації та технічні засоби автоматизованої обробки інформації, що містить персональні дані;</li>
                        <li>
                          інформаційні ресурси (бази даних, файли і інші), що містять інформацію про інформаційно-телекомунікаційні системи, в яких
                          циркулюють персональні дані, про події, що сталися з керованими об'єктами, про плани забезпечення безперебійної роботи і
                          процедури переходу до управління в аварійних режимах;
                        </li>
                        <li>
                          канали зв'язку, які використовуються для передачі персональних даних у вигляді інформативних електричних сигналів і фізичних
                          полів;
                        </li>
                        <li>
                          відчужувані носії інформації на магнітній, магнітно-оптичній та інших засадах, що застосовуються для обробки персональних
                          даних.
                        </li>
                      </ul>
                    </li>
                    <li>
                      4.3.2. Технологічна інформація про інформаційні системи та елементи системи захисту персональних даних, що підлягає захисту,
                      включає:
                      <ul>
                        <li>відомості про систему управління доступом на об'єкти інформатизації, на яких здійснюється обробка персональних даних;</li>
                        <li>керуюча інформація (конфігураційні файли, таблиці маршрутизації, налаштування системи захисту та ін.);</li>
                        <li>
                          технологічна інформація засобів доступу до систем управління (аутентифікаційна інформація, ключі та атрибути доступу і ін.);
                        </li>
                        <li>
                          характеристики каналів зв'язку, які використовуються для передачі персональних даних у вигляді інформативних електричних
                          сигналів і фізичних полів;
                        </li>
                        <li>інформація про засоби захисту персональних даних, їх склад та структуру, принципи і технічні рішення захисту;</li>
                        <li>
                          службові дані (метадані) з'являються при роботі програмного забезпечення, повідомлень і протоколів міжмережевої взаємодії, в
                          результаті обробки персональних даних.
                        </li>
                      </ul>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    <strong>4.4. Вимоги до системи захисту персональних даних.</strong>
                  </p>
                  <p>
                    Система захисту персональних даних повинна відповідати вимогам положення Закону України «Про захист персональних даних» від 1
                    червня 2010 року № 2297-VI, Закону України «Про інформацію» від 2 жовтня 1992 року № 2657-XII, Порядку та чинного законодавству
                    України.
                  </p>
                  <ol>
                    <li>
                      4.4.1. Система захисту персональних даних повинна забезпечувати:
                      <ul>
                        <li>
                          своєчасне виявлення і запобігання несанкціонованого доступу до персональних даних та (або) передачі їх особам, які не мають
                          права доступу до такої інформації;
                        </li>
                        <li>
                          недопущення впливу на технічні засоби автоматизованої обробки персональних даних, в результаті якого може бути порушено їхнє
                          функціонування;
                        </li>
                        <li>постійний контроль за забезпеченням рівня захищеності персональних даних.</li>
                      </ul>
                    </li>
                    <li>
                      4.4.2. Засоби захисту інформації, що застосовуються в інформаційних системах, повинні в установленому порядку проходити
                      процедуру оцінки відповідності.
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>4.5. Методи і способи захисту інформації в інформаційних системах персональних даних.</strong>
                  <ol>
                    <li>
                      4.5.1. Методи і способи захисту інформації в інформаційних системах персональних даних Власника Сайту повинні відповідати
                      вимогам законодавства України.
                    </li>
                    <li>
                      4.5.2. Основними методами і способами захисту інформації в інформаційних системах персональних даних Користувачів є методи і
                      способи захисту інформації від несанкціонованого, в тому числі випадкового, доступу до персональних даних, результатом якого
                      може стати знищення, зміна, блокування, копіювання, поширення персональних даних, а також інших несанкціонованих дій (далі -
                      методи і способи захисту інформації від несанкціонованого доступу).
                    </li>
                    <li>
                      4.5.3. Вибір і реалізація методів і способів захисту інформації на Сайті здійснюється відповідно до визначених Власником Сайту
                      загроз безпеки персональних даних (моделі загроз) і в залежності від класу інформаційної системи.
                    </li>
                    <li>
                      4.5.4. Обрані і реалізовані методи і способи захисту інформації на Сайті повинні забезпечувати нейтралізацію можливих загроз
                      безпеки персональних даних при їх обробці.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <h3>5. Прикінцеві положення</h3>
              <ol>
                <li>
                  5.1. У разі зміни чинного законодавства України, внесення змін до нормативних документів щодо захисту персональних даних, це
                  Положення діє в частині, що не суперечить чинному законодавству до приведення його у відповідність з такими.
                </li>
                <li>
                  5.2. Умови цього Положення встановлюються, змінюються і скасовуються Власником Сайту в односторонньому порядку без попереднього
                  повідомлення Користувача. З моменту розміщення на Сайті нової редакції Положення попередня редакція вважається такою, що втратила
                  чинність. У разі істотної зміни умов цієї Угоди, Власник Сайту повідомляє про це Користувачів шляхом розміщення на Сайті
                  відповідного повідомлення.
                </li>
                <li>
                  5.3. Якщо Користувач не згоден з умовами цього Положення, то він повинен негайно видалити свій Профіль з Сайту, в іншому випадку
                  продовження використання Користувачем Сайту означає, що Користувач згоден з умовами цього Положення.
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </s.WrapperS>
      <Footer />
    </div>
  );
};
