import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import './index.css';
import store from './store';
import TagManager, { TagManagerArgs } from 'react-gtm-module';
import ReactGA from 'react-ga4';

//a common GTM for all sites to track registrations on all our sites.
// gtmId: 'GTM-PMX8NWF',
const tagManagerArgs1: TagManagerArgs = {
  gtmId: 'GTM-MRHC4JW',
};

TagManager.initialize(tagManagerArgs1);

//GTM specifically for ID3 to track analytics for your personal account
// const tagManagerArgs2: TagManagerArgs = {
//   gtmId: 'GTM-MRHC4JW',
//   dataLayerName: 'dataLayer2'
// };

// TagManager.initialize(tagManagerArgs2);
//Initialize Google Analytics
ReactGA.initialize('G-KNCDWSNKVW');
ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
