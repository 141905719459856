import styled from 'styled-components';

const WrapperS = styled.div`
  padding-top: 64px;
  display: flex;
  width: 100%;
  min-height: 500px;
  flex-wrap: wrap;
  @media (min-width: 1024px) {
    flex-wrap: nowrap;
    justify-content: center;
  }
`;

const MainS = styled.div`
  width: 866px;
  margin-right: 32px;
  max-width: 100%;
`;

const TitleS = styled.h2`
  margin: 0 16px 24px 0;
  display: inline-block;
  font-size: 32px;
  line-height: 44px;
  letter-spacing: -0.2px;
`;

const SubTitle = styled.h3`
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.15px;
`;

const LineS = styled.div`
  width: calc(100% + 32px);
  transform: translateX(-16px);
  height: 1px;
  background-color: var(--warmGrayColor3);
  margin-bottom: 64px;
`;

const PanelS = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
  cursor: pointer;
  margin-bottom: 16px;
  overflow: hidden;
  background-color: var(--warmGrayColor1);
  border-radius: 8px;
`;
const DescriptionS = styled.div`
  font-weight: 600;
  color: var(--warmGrayColor7);
  font-size: var(--fontSizeNormal);
  max-width: 500px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

// eslint-disable-next-line
export default {
  DescriptionS,
  LineS,
  MainS,
  PanelS,
  SubTitle,
  TitleS,
  WrapperS,
};
