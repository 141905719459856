import React from 'react';

export const DoneIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="14"
      viewBox="0 0 17 14"
      fill="none"
      className="injected-svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{ fill: 'none' }}
    >
      <path d="M1 6L6.5 12.5L15.5 1" stroke="#00B333" strokeWidth="1.85" strokeLinecap="round"></path>
    </svg>
  );
};
