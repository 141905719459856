import React from 'react';

export const Onlinehelp: React.FC = () => {
  return (
    <div>
      {' '}
      <div className="logotype">
        {' '}
        <a href="https://id.expertus.com.ua">
          {' '}
          <img style={{ width: '210px', height: '56px' }} src={'/media/images/logo-e.png'} alt="" />
        </a>{' '}
      </div>{' '}
      <div className="content">
        <h2 style={{ color: '#1a82c5' }}>Правила роботи чату «Технічна підтримка»</h2>
        <p>1. Чат працює в робочі дні: пн-пт з 09:00 до 18:00. Відповіді на запитання, які поставите в неробочий час, отримаєте наступного робочого дня. Поки Ви їх не прочитаєте, на віджеті чату підсвічуватимуться червоним кольором кількість відповідей.
        </p>
        <br/>
        <p>
          2. Консультанти чату не надають консультації на професійну тематику, із питань налаштування операційної системи та інших програм, установлених на Вашому ПК.
        </p>
        <br/>
        <div><img  src={'/media/images/online2.png'} alt="" /></div>
        <br/>
        <p>
          3. Чати з консультантами трансформуються в стрічку, яку зручно гортати. Історія листування зберігається.
        </p>
        <br/>
        <p>
          4. Не зазначайте свої ПІБ та контактну інформацію. Ці дані нам підтягуватиме з Вашого особистого кабінету автоматично.
        </p>
        <br/>
        <p>
          5. Прикріпіть за допомогою  <img  src={'/media/images/onlineskrep.png'} alt="" /> файл із уточненнями до запитання. Він може бути текстового формату (word, pdf) або зображенням (фото з телефону, скрін екрану). Це допоможе нам швидше розібратися з Вашим запитом й надати Вам відповідь.
        </p>
        <br/>
        <div><img  src={'/media/images/online5.png'} alt="" /></div>
        <br/>
        <p>
          6. У Вас є можливість оцінити роботу консультанта за п’ятибальною шкалою. Для цього натисніть на зірочки в кінці чату.
        </p>
        <br/>
        <div><img  src={'/media/images/online6.png'} alt="" /></div>

      </div>
    </div>
  );
};
