import React from 'react';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { DefaultInput } from './DefaultInput';
import styled from 'styled-components';

const UlS = styled.ul`
  position: absolute;
  top: 90%;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0;
  padding: 0;
  max-height: 302px;
  background: #fff;
  border: 1px solid #cac9c9;
  border-radius: 3px;
  box-sizing: border-box;
  box-shadow: 0 2px 2px rgb(74 70 62 / 35%);
  list-style: none;
  color: #000;
  overflow: auto;
  z-index: 20;
  li {
    padding: 5px 10px;
    cursor: pointer;
  }
  li:hover {
    background: #bfe2fb;
  }
`;

type PropsType = {
  type?: React.HTMLInputTypeAttribute;
  placeholder?: string;
  label?: string;
  value: string;
  handleChange: (value: string) => void;
  handleFocusout?: () => void;
  styleGroup?: React.CSSProperties;
  styleWrap?: React.CSSProperties;
  style?: React.CSSProperties;
  styleSelect?: React.CSSProperties;
  error?: string;
  isDone?: boolean;
  isRedonly?: boolean;
  maxLength?: number;
  options?: { value: string; label: string }[];
};

export const SelectSearch: React.FC<PropsType> = React.memo(
  ({ maxLength, isRedonly, type, options, label, error, placeholder, value, styleGroup, styleSelect, styleWrap, style, isDone, handleChange }) => {
    const [currentValue, setCurrentValue] = React.useState('');
    const [items, setItems] = React.useState<{ value: string; label: string }[] | undefined>([]);
    const [isFocus, setIsFocus] = React.useState(false);
    const { ref } = useOutsideClick(() => {
      const curentPosition = items?.find((it) => it.value === currentValue);
      handleChange(curentPosition?.value || '');
      setCurrentValue(curentPosition?.value || '');
      setIsFocus(false);
    });

    const handleSearch = React.useCallback(
      (search: string) => {
        setCurrentValue(search);
        if (options) {
          const items = options.filter((item) => {
            if (item.label.toLowerCase().includes(search.toLowerCase())) {
              return true;
            }
            return false;
          });
          setItems(items);
        }
      },
      [options]
    );

    React.useEffect(() => {
      if (options && value) {
        handleSearch(value);
      } else if (options) {
        setItems(options);
      }
    }, [options, value, handleSearch]);

    return (
      <div style={{ position: 'relative' }} ref={ref}>
        <DefaultInput
          error={error}
          isDone={isDone}
          label={label}
          styleGroup={styleGroup}
          styleWrap={styleWrap}
          isRedonly={isRedonly}
          type={type}
          placeholder={placeholder}
          value={currentValue}
          style={style}
          maxLength={maxLength}
          handleFocus={() => setIsFocus(true)}
          handleChange={handleSearch}
        />
        {isFocus && items && items.length > 0 ? (
          <UlS style={styleSelect}>
            {items?.map((option, i) => (
              <li
                key={`${i}-${option.value}`}
                value={option.value}
                onClick={() => {
                  handleChange(option.value);
                  setCurrentValue(option.value);
                  setIsFocus(false);
                }}
                dangerouslySetInnerHTML={{
                  __html: option.label.replace(
                    `${currentValue[0]?.toUpperCase()}${currentValue.slice(1)}`,
                    `<span style="color: #0095ff">${currentValue[0]?.toUpperCase()}${currentValue.slice(1)}</span>`
                  ),
                }}
              />
            ))}
          </UlS>
        ) : null}
      </div>
    );
  }
);
