import { Reducer } from 'redux';
import { ActionTypes, IProduct, TProductsState } from './types';

export const initialState: TProductsState = {
  loading: true,
  data: null,
  errors: undefined,
};

const changeProducts = (products: TProductsState['data'], product: IProduct) => {
  if (!products) return [product];
  if (product.parent_id === 0) {
    const root = products.find((it) => it.absnum === product.absnum);
    if (root) {
      return products.map((row) => {
        if (row.absnum === product.absnum) {
          const children = product.children;
          row.children.push(...children);
        }
        return row;
      });
    } else {
      return [...products, product];
    }
  } else {
    return products.map((row) => {
      if (row.absnum === product.parent_id) {
        row.children.push(product);
      }
      return row;
    });
  }
};

const productsReducer: Reducer<TProductsState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_PRODUCTS_R:
    case ActionTypes.ACTIVATE_PRODUCT_R:
      return { ...state, loading: true, errors: undefined };

    case ActionTypes.GET_PRODUCTS_S:
      return { ...state, loading: false, errors: undefined, data: action.payload };

    case ActionTypes.ACTIVATE_PRODUCT_S:
      return { ...state, loading: false, errors: undefined, data: changeProducts(state.data, action.payload) };

    case ActionTypes.GET_PRODUCTS_E:
    case ActionTypes.ACTIVATE_PRODUCT_E:
      return { ...state, loading: false, errors: action.payload };
    default:
      return state;
  }
};

export { productsReducer as ProductsReducer };
