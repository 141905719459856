export const onlineHelper = () => {
  const body = document.getElementsByTagName('body');
  const child = body[0]?.lastChild;
  if (child) {
    const script = document.createElement('script');
    script.insertAdjacentHTML(
      'afterbegin',
      `
      (() => {
        const script = document.createElement('script');
        script.async = true;
        script.src = '${process.env.REACT_APP_WIDJET_SRC}';
        script.onload = onLoadLoaderScript;
        document.getElementsByTagName('head')[0].appendChild(script);
      })();

      function onLoadLoaderScript() {
        const widgetId = ${process.env.REACT_APP_WIDJET_ID};
        window.Widget?.init(widgetId);
      }`
    );
    child.after(script);
  }
};
