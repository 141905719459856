import { Reducer } from 'redux';
import { ActionTypes, TBookmarksState } from './types';

export const initialState: TBookmarksState = {
  loading: true,
  data: null,
  errors: undefined,
};

const bookmarksReducer: Reducer<TBookmarksState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_BOOKMARKS_R:
      return { ...state, loading: true, errors: undefined };

    case ActionTypes.GET_BOOKMARKS_S:
      return { ...state, loading: false, errors: undefined, data: action.payload };

    case ActionTypes.GET_BOOKMARKS_E:
      return { ...state, loading: false, errors: action.payload };
    default:
      return state;
  }
};

export { bookmarksReducer as BookmarksReducer };
