import React from 'react';

export const ListChecks: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 8C7.5 7.72386 7.72386 7.5 8 7.5H13.5C13.7761 7.5 14 7.72386 14 8C14 8.27614 13.7761 8.5 13.5 8.5H8C7.72386 8.5 7.5 8.27614 7.5 8Z" fill="#9E9E9E" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 4C7.5 3.72386 7.72386 3.5 8 3.5H13.5C13.7761 3.5 14 3.72386 14 4C14 4.27614 13.7761 4.5 13.5 4.5H8C7.72386 4.5 7.5 4.27614 7.5 4Z" fill="#9E9E9E" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 12C7.5 11.7239 7.72386 11.5 8 11.5H13.5C13.7761 11.5 14 11.7239 14 12C14 12.2761 13.7761 12.5 13.5 12.5H8C7.72386 12.5 7.5 12.2761 7.5 12Z" fill="#9E9E9E" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.11757 2.66104C6.30477 2.86404 6.29197 3.18036 6.08897 3.36757L3.92022 5.36757C3.72853 5.54434 3.43319 5.54412 3.24176 5.36708L2.16051 4.36708C1.95778 4.17958 1.94543 3.86324 2.13293 3.66051C2.32042 3.45778 2.63676 3.44543 2.83949 3.63293L3.58174 4.3194L5.41104 2.63244C5.61404 2.44523 5.93036 2.45804 6.11757 2.66104Z" fill="#9E9E9E" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.11757 6.66104C6.30477 6.86404 6.29197 7.18036 6.08897 7.36757L3.92022 9.36757C3.72853 9.54434 3.43319 9.54412 3.24176 9.36708L2.16051 8.36708C1.95778 8.17958 1.94543 7.86324 2.13293 7.66051C2.32042 7.45778 2.63676 7.44543 2.83949 7.63293L3.58174 8.3194L5.41104 6.63244C5.61404 6.44523 5.93036 6.45804 6.11757 6.66104Z" fill="#9E9E9E" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.11757 10.661C6.30477 10.864 6.29197 11.1804 6.08897 11.3676L3.92022 13.3676C3.72853 13.5443 3.43319 13.5441 3.24176 13.3671L2.16051 12.3671C1.95778 12.1796 1.94543 11.8632 2.13293 11.6605C2.32042 11.4578 2.63676 11.4454 2.83949 11.6329L3.58174 12.3194L5.41104 10.6324C5.61404 10.4452 5.93036 10.458 6.11757 10.661Z" fill="#9E9E9E" />
    </svg>
  );
};