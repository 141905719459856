import React from 'react';
import { isMobile } from 'react-device-detect';
import { URI } from '../../config';
import { Link } from 'react-router-dom';
import s from './styles';

type Props = {
  redirect: string
  appId: string
}

export const FirstLink = (props: Props) => {
  const {redirect, appId} = props;

  return (
    <>
        <b>Я тут вперше</b>
        <s.Cascade5S />
        <Link
          style={{ border: 'none', color: '#1252a1' }}
          to="/registration"
          onClick={(e) => {
            if (redirect) {
              e.preventDefault();
              const link = document.createElement('a');
              document.body.appendChild(link);
              link.href = `${URI.trim()}/api/auth/site/registration?app=${appId}&redirectUrl=${encodeURIComponent(redirect)}`;
              link.target = '_blank';
              link.click();
              document.body.removeChild(link);
              if (!isMobile) {
                const link2 = document.createElement('a');
                document.body.appendChild(link);
                link2.href = `/login/?app=${appId}&redirectUrl=${encodeURIComponent(redirect)}`;
                link2.click();
                document.body.removeChild(link);
              }

            }
          }}
        >
          <u>Зареєструватися </u>
        </Link>
    </>
  );
};
