import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import {
  HomePage,
  Agreement,
  Onlinehelp,
  ChangePasswdPage,
  Private,
  ConfirmEmail,
  ProductsPage,
  BookmarksPage,
  NewslettersPage,
  ProfilePage,
  Feedback,
  FeedbackAnswer,
  OnlinehelpSales,
  ConfirmChangeEmail,
  Confirmlogin
} from './pages';
import { Login, Registration, LoginFrame, ALogin } from './components/Auth';
import { AppStore } from './store/applicationState';
import { CheckAccess, GetUserProfile } from './store/user/actions';
import { GetProducts } from './store/products/actions';
import { GetConfigurations } from './store/configurations/actions';
import { onlineHelper } from './utils/onlineHelper';
import initID3 from './utils/id3';
import TagManager from 'react-gtm-module';
import {NewslettersFrame} from "./pages/NewslettersFrame";

const queryString = window.location.search;
const queryParams = new URLSearchParams(queryString);
const links = queryParams.get('link');

let url_add = "";

if(links) {
  url_add = `?link=${links}`
}


function RequireAuth({ children }: { children: JSX.Element }) {
  const { User } = useSelector((store: AppStore) => store);
  let location = useLocation();

  if (User.isAuth === false) {
    return <Navigate to={`/login${url_add}`} state={{ from: location }} replace />;
  }

  return children;
}

function Redirect() {
  const { User } = useSelector((store: AppStore) => store);
  let location = useLocation();

  if (User.isAuth === true) {
    return <Navigate to={`/main${url_add}`} state={{ from: location }} replace />;
  }
  if (User.isAuth === false) {
    return <Navigate to={`/login${url_add}`} state={{ from: location }} replace />;
  }
  return null;
}

function App() {
  const { User } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const handleCheck = React.useCallback(() => {
    const href = window.location.href;
    if (href.includes('registration')) return null;
    if (href.includes('login')) return null;
    if (href.includes('/redirect')) return null;
    dispatch(CheckAccess.request({}));
  }, [dispatch]);

  React.useEffect(() => {
    handleCheck();
  }, [handleCheck]);

  React.useEffect(() => {
    dispatch(GetConfigurations.request({}));
  }, [dispatch]);

  React.useEffect(() => {
    if (User.isAuth) {
      initID3(onlineHelper);
      dispatch(GetProducts.request({}));
      dispatch(GetUserProfile.request({}));
    }
  }, [User.isAuth, dispatch]);

  React.useEffect(() => {
    if (User.userProfile?.id) {
      const dataLayer = {
        'userID': User.userProfile.id,
        'e_subscriber': User.userProfile.e_subscriber,
        's_subscriber': User.userProfile.s_subscriber,
        'p_subscriber': User.userProfile.p_subscriber,
        'sch_subscriber': User.userProfile.sch_subscriber,
        'demo_e': User.userProfile.demo_e,
        'demo_s': User.userProfile.demo_s,
        'authorized': User.userProfile.authorized,
      }
      setTimeout(()=> {
        TagManager.dataLayer({dataLayer});
      }, 1000)
      // TagManager.dataLayer({dataLayer, dataLayerName: 'dataLayer2'});
    }
  }, [User.userProfile]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Redirect />} />
        <Route
          path="/main"
          element={
            <RequireAuth>
              <HomePage />
            </RequireAuth>
          }
        />
        <Route
          path="/profile"
          element={
            <RequireAuth>
              <ProfilePage />
            </RequireAuth>
          }
        />
        <Route
          path="/products"
          element={
            <RequireAuth>
              <ProductsPage />
            </RequireAuth>
          }
        />
        <Route
          path="/bookmarks"
          element={
            <RequireAuth>
              <BookmarksPage />
            </RequireAuth>
          }
        />
        <Route
          path="/newsletters"
          element={
            <RequireAuth>
              <NewslettersPage />
            </RequireAuth>
          }
        />
        <Route
          path="/newslettersFrame"
          element={
              <NewslettersFrame />
          }
        />
        <Route path="/login" element={<Login />} />;
        <Route path="/aloginOld" element={<ALogin />} />;
        <Route path="/loginFrame" element={<LoginFrame />} />;
        <Route path="/registration" element={<Registration />} />;
        <Route path="/change-password/:token" element={<ChangePasswdPage />} />;
        <Route path="/agreement" element={<Agreement />} />;
        <Route path="/onlinehelp" element={<Onlinehelp />} />;
        <Route path="/onlinehelp-sales" element={<OnlinehelpSales />} />;
        <Route path="/private" element={<Private />} />;
        <Route path="/confirm-email" element={<ConfirmEmail />} />;
        <Route path="/confirm-change-email" element={<ConfirmChangeEmail />} />;
        <Route path="/confirm-login" element={<Confirmlogin />} />;

        <Route path="/feedback">
          <Route index element={<Feedback />} />
          <Route path=":id" element={<FeedbackAnswer />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
