export interface INewsletter {
    key: string,
    text: string,
    status: boolean
}

export interface TNewslettersState {
    data: {
      list: INewsletter[],
    } | null;
    readonly loading: boolean;
    isAuth?: boolean;
    readonly errors?: string | undefined;
  }

export enum ActionTypes {
    GET_NEWSLETTERS_R = '@@newsletters/GET_NEWSLETTERS_R',
    GET_NEWSLETTERS_S = '@@newsletters/GET_NEWSLETTERS_S',
    GET_NEWSLETTERS_E = '@@newsletters/GET_NEWSLETTERS_E',
    SET_NEWSLETTERS_R = '@@newsletters/SET_NEWSLETTERS_R',
    SET_NEWSLETTERS_S = '@@newsletters/SET_NEWSLETTERS_S',
    SET_NEWSLETTERS_E = '@@newsletters/SET_NEWSLETTERS_E',
}