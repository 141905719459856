import React from 'react';
import s from './styles'; 
import { Header, Footer } from './components';

import './styles.css';
import { callApi } from '../../utils/callApi';
import { TOKEN } from '../../config';
import { useNavigate } from 'react-router-dom';

export const ALogin: React.FC = () => {
  const navigate = useNavigate();

  React.useEffect(() => {    
    const getData = async() => {
      const payload = {
        method: 'get' as const,
        path:   `/auth/alogin${window.location.search}`,
        data: null,
        isFormData: false
      }

      const resp = await callApi(payload);
      
      if(resp?.redirectApp){
        if(resp?.token){
          localStorage.setItem(TOKEN.access, resp.token);

        }
          window.location.href = resp.redirectApp;   
      } else {
        navigate('/login');
      }
         
    };

    getData();
  }, [ navigate ]);

  return (
    <s.WrappS>
      <Header />
      <s.WrapperS>
      </s.WrapperS>
      <Footer />
    </s.WrappS>
  );
};