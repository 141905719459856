import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { callApi } from '../../utils/callApi';
import { ActionTypes, INewsletter } from './types';
import { GetNewsletters, TypeGetNewslettersR } from './actions';

function* getNewslettersWorker(action: ReturnType<typeof GetNewsletters.request>): Generator {
  const { data, callBack } = action.payload as TypeGetNewslettersR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      data,
      path: `/get-newsletters`,
    })) as INewsletter[];
    yield put(GetNewsletters.success(resp));
  } catch (e) {
    success = false;
    yield put(GetNewsletters.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* setNewslettersWorker(action: ReturnType<typeof GetNewsletters.request>): Generator {
  const { data, callBack } = action.payload as TypeGetNewslettersR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      data,
      path: `/set-newsletters`,
    })) as INewsletter[];
    yield put(GetNewsletters.success(resp));
  } catch (e) {
    success = false;
    yield put(GetNewsletters.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_NEWSLETTERS_R, getNewslettersWorker);
  yield takeEvery(ActionTypes.SET_NEWSLETTERS_R, setNewslettersWorker);

}

export default function* newslettersSaga() {  
  yield all([fork(watchFetchRequest)]);
}
