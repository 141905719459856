import React from 'react';

export const Agreement: React.FC = () => {
  return (
    <div>
      {' '}
      <div className="logotype">
        {' '}
        <a href="https://id.expertus.com.ua">
          {' '}
          <img style={{ width: '210px', height: '56px' }} src={'/media/images/logo-e.png'} alt="Експертус" />
        </a>{' '}
      </div>{' '}
      <div className="content">
        {' '}
        <h2>Угода про&nbsp;умови користування Сайтом</h2>{' '}
        <p>
          {' '}
          Дана Угода укладена між Цифровим видавництвом Експертус (далі&nbsp;— Власник) і&nbsp;{' '}
          <span className="noBreak">будь-якою</span> особою, що&nbsp;стає після прийняття умов даної Угоди користувачем Сайту{' '}
          <a href="https://id.expertus.com.ua" className="noBreak">
            https://id.expertus.com.ua
          </a>
          , надалі іменованим «Користувач», разом за&nbsp;текстом Угоди іменовані «Сторони», а&nbsp;окремо&nbsp;— «Сторона».{' '}
        </p>{' '}
        <p>
          {' '}
          Дана Угода носить характер публічної оферти, тобто є еквівалентом «усної угоди», не&nbsp;вимагає двостороннього підписання, дійсна
          в&nbsp;електронному вигляді і&nbsp;відповідно до&nbsp;чинного законодавства України має належну юридичну силу.{' '}
        </p>{' '}
        <ol>
          {' '}
          <li>
            {' '}
            <h3>1. Терміни і&nbsp;визначення</h3>{' '}
            <ol>
              {' '}
              <li>
                {' '}
                1.1. Сайт&nbsp;— сукупність програмних і&nbsp;апаратних засобів для ЕОМ, які забезпечують публікацію для загального огляду інформації
                і&nbsp;даних, об’єднаних загальним цільовим призначенням, за&nbsp;допомогою технічних засобів, що&nbsp;використовуються для зв’язку
                між ЕОМ в&nbsp;мережі Інтернет. Під Сайтом в&nbsp;Угоді розуміється Сайт, на&nbsp;якому розташована Єдина Система Реєстрації
                і&nbsp;Авторизації користувачів (яка об’єднує усі сайти Цифрового видавництва Експертус), що&nbsp;знаходиться
                в&nbsp;мережі Інтернет за&nbsp;адресою{' '}
                <a href="https://id.expertus.com.ua" className="noBreak">
                  https://id.expertus.com.ua
                </a>
                . Сайт є об’єктом авторського права і&nbsp;в&nbsp;якості складного твору, а&nbsp;також «компіляції даних» («бази даних»), охороняється
                згідно із Законом України «про&nbsp;авторське право і&nbsp;суміжні права».{' '}
              </li>{' '}
              <li>
                {' '}
                1.2. Адміністратор/Власник Сайту Цифрове видавництво Експертус, 02002, м.&nbsp;Київ, вул.&nbsp;Раїси
                Окіпної, 4-Б.
              </li>{' '}
              <li>
                {' '}
                1.3. Контентом Сайту визнаються усі об’єкти, розміщені на&nbsp;Сайті, у&nbsp;тому числі елементи дизайну, текст, графічні зображення,
                ілюстрації, відео, скрипти, програми, музика, звуки і&nbsp;інші об’єкти та&nbsp;їх підбірки. Власник Сайту має виняткові права
                на&nbsp;використання Сайту, включаючи увесь контент Сайту.{' '}
              </li>{' '}
              <li>
                {' '}
                1.4. Користувач&nbsp;— користувач мережі Інтернет, зокрема, Сайту і&nbsp;що&nbsp;має свою особисту сторінку (профіль/аккаунт).{' '}
              </li>{' '}
              <li> 1.5. Профіль/аккаунт&nbsp;— особиста сторінка Користувача на&nbsp;Сайті. </li>{' '}
              <li>
                {' '}
                1.6. Облікова інформація&nbsp;— унікальне ім’я Користувача (логін) і&nbsp;пароль для входу на&nbsp;Сайт, що&nbsp;вказуються
                Користувачем під час реєстрації на&nbsp;Сайті. Реєстрація на&nbsp;Сайті надає Користувачеві право отримувати доступ
                до&nbsp;інформаційних ресурсів, розміщених на&nbsp;Сайті.{' '}
              </li>{' '}
              <li>
                {' '}
                1.7. Модерація&nbsp;— перегляд Власником Сайту розміщуваної (чи&nbsp;планованою до&nbsp;розміщення&nbsp;— Премодерація) на&nbsp;Сайті
                Користувачем інформації на&nbsp;предмет її&nbsp;відповідності положенням даної Угоди.{' '}
              </li>{' '}
            </ol>{' '}
          </li>{' '}
          <li>
            {' '}
            <h3>2. Предмет угоди</h3>{' '}
            <ol>
              {' '}
              <li>
                {' '}
                2.1. Власник Сайту надає Користувачу право отримувати доступ до&nbsp;сервісів Сайту, при цьому обов’язковою умовою є&nbsp;прийняття,
                дотримання Користувачем і&nbsp;застосування до&nbsp;відносин Сторін вимог і&nbsp;положень, визначених даною Угодою.{' '}
              </li>{' '}
              <li>
                {' '}
                2.2. Власник Сайту залишає за&nbsp;собою право змінювати умови даної Угоди і&nbsp;усіх її&nbsp;невід’ємних частин без узгодження
                з&nbsp;Користувачем, але з&nbsp;повідомленням останнього за&nbsp;допомогою розміщення на&nbsp;Сайті нової редакції Угоди або
                якої-небудь її&nbsp;невід’ємної частини, що&nbsp;піддалася змінам. Зважаючи, що&nbsp;інформаційна розсилка може бути визнана Сторонами
                спамом, Користувач зобов’язується не&nbsp;менше одного разу на&nbsp;місяць знайомитися зі&nbsp;змістом Угоди, розміщеного
                на&nbsp;Сайті, в&nbsp;цілях своєчасного ознайомлення з&nbsp;його змінами. Нова редакція Угоди або будь-якої її&nbsp;невід’ємної
                частини набуває чинності з&nbsp;моменту публікації на&nbsp;Сайті, якщо інший термін вступу змін в&nbsp;силу не&nbsp;визначений
                Власником Сайту при їх&nbsp;публікації. Діюча редакція Угоди завжди знаходиться на&nbsp;Сайті в&nbsp;публічному доступі
                за&nbsp;адресою{' '}
                <a href="https://id.expertus.com.ua/agreement" className="noBreak">
                  https://id.expertus.com.ua/agreement
                </a>
                .{' '}
              </li>{' '}
            </ol>{' '}
          </li>{' '}
          <li>
            {' '}
            <h3>3. Права і&nbsp;обов’язки Власника Сайту</h3>{' '}
            <ol>
              {' '}
              <li>
                {' '}
                3.1. Власник Сайту зобов’язується:{' '}
                <ol>
                  {' '}
                  <li>
                    {' '}
                    3.1.1. Надавати Користувачу права, вказані в&nbsp;2.1. даної Угоди. Доступ до&nbsp;Сайту надається шляхом закріплення
                    за&nbsp;Користувачем Облікової інформації впродовж 1 (одного) робочого дня з&nbsp;дати реєстрації останнього за&nbsp;адресою{' '}
                    <a href="https://id.expertus.com.ua/registration" className="noBreak">
                      https://id.expertus.com.ua/registration
                    </a>
                    .{' '}
                  </li>{' '}
                  <li> 3.1.2. Надавати Користувачеві можливість самостійно публікувати інформацію про&nbsp;себе. </li>{' '}
                  <li> 3.1.3. Не&nbsp;розголошувати третім особам Облікову інформацію Користувача. </li>{' '}
                  <li>
                    {' '}
                    3.1.4. Забезпечувати цілодобову доступність сервера, на&nbsp;якому розташований Сайт, за&nbsp;винятком часу проведення
                    профілактичних робіт.{' '}
                  </li>{' '}
                </ol>{' '}
              </li>{' '}
              <li>
                {' '}
                3.2. Власник Сайту має право:{' '}
                <ol>
                  {' '}
                  <li>
                    {' '}
                    3.2.1. У&nbsp;разі порушення Користувачем умов Угоди, направити Користувачеві попередження, що&nbsp;містить перелік порушень.
                    У&nbsp;разі, якщо Користувач не&nbsp;усуне порушення протягом одного дня з&nbsp;моменту направлення йому повідомлення або повторно
                    порушить вказані умови, Власник Сайту має право в&nbsp;односторонньому порядку відмовитися від виконання Угоди, заблокувати доступ
                    Користувача до&nbsp;його Профілю і&nbsp;анулювати Профіль Користувача.{' '}
                  </li>{' '}
                  <li>
                    {' '}
                    3.2.2. Здійснювати модерацію у&nbsp;випадках, коли інформація, що&nbsp;розміщується Користувачем, пропагує ненависть і/або
                    дискримінацію людей за&nbsp;расовими, етнічними, статевими, релігійними, соціальними ознаками; пропагує вживання наркотичних
                    і&nbsp;інших засобів, що&nbsp;завдають шкоди здоров’ю; закликає до&nbsp;нелюдяного поводження з&nbsp;тваринами; порушує права
                    національних і&nbsp;інших меншин; містить заклики до&nbsp;насильницьких дій; порушує права авторів і&nbsp;інших власників
                    інтелектуальних прав.{' '}
                  </li>{' '}
                  <li>
                    {' '}
                    3.2.3. Розпоряджатися статистичною інформацією, пов’язаною з&nbsp;функціонуванням Сайту, а&nbsp;також інформацією Користувачів для
                    забезпечення адресного показу рекламної інформації різним аудиторіям Користувачів Сайту.{' '}
                  </li>{' '}
                  <li>
                    {' '}
                    3.2.4. Направляти Користувачу інформацію про розвиток Сайту і&nbsp;його сервісів; відправляти sms-повідомлення з&nbsp;метою
                    інформування про коди активації, проводити опитування про якість роботи Сайту, надсилати запити іншої інформації, а&nbsp;також
                    рекламувати власну діяльність і&nbsp;послуги.{' '}
                  </li>{' '}
                </ol>{' '}
              </li>{' '}
            </ol>{' '}
          </li>{' '}
          <li>
            {' '}
            <h3>4. Права і&nbsp;обов’язки Користувача</h3>{' '}
            <ol>
              {' '}
              <li>
                {' '}
                4.1. Користувач зобов’язується:{' '}
                <ol>
                  {' '}
                  <li> 4.1.1. Повністю ознайомитися з&nbsp;умовами даної Угоди до&nbsp;моменту реєстрації на&nbsp;Сайті. </li>{' '}
                  <li>4.1.2. Дотримуватися усіх умов даної Угоди.</li>{' '}
                  <li> 4.1.3. Не&nbsp;передавати відомості про&nbsp;інших Користувачів, отримані за&nbsp;допомогою Сайту, третім особам. </li>{' '}
                  <li> 4.1.4. Не&nbsp;передавати свою Облікову інформацію третім особам. </li>{' '}
                  <li>
                    {' '}
                    4.1.5. Не&nbsp;розміщувати на&nbsp;Сайті персональні дані інших осіб, а&nbsp;також не&nbsp;використати персональні дані інших
                    Користувачів або Користувачів будь-яким чином, що&nbsp;не&nbsp;відповідає вимогам законодавства України, в&nbsp;протиправних або
                    протизаконних цілях, в&nbsp;цілях отримання вигоди і&nbsp;будь-яких інших цілях, що&nbsp;не&nbsp;відповідають цілям створення
                    Сайту.{' '}
                  </li>{' '}
                  <li>
                    {' '}
                    4.1.6. Не&nbsp;розміщувати в&nbsp;Профілі інформацію і&nbsp;об’єкти (включаючи посилання на&nbsp;них), які можуть порушувати права
                    і&nbsp;інтереси інших осіб.{' '}
                  </li>{' '}
                  <li>
                    {' '}
                    4.1.7. Не&nbsp;реєструватися в&nbsp;якості Користувача від імені або замість іншої особи або реєструвати групу (об’єднання) осіб
                    або юридичну особу/індивідуального підприємця в&nbsp;якості Користувача.{' '}
                  </li>{' '}
                  <li>
                    {' '}
                    4.1.8. Не&nbsp;завантажувати, не&nbsp;зберігати, не&nbsp;публікувати, не&nbsp;поширювати <span className="noBreak">
                      будь-яку
                    </span>{' '}
                    інформацію, яка:{' '}
                    <ul>
                      {' '}
                      <li>
                        {' '}
                        містить загрози, дискредитує, ображає, порочить честь і&nbsp;гідність або порушує недоторканість приватного життя інших
                        Користувачів або третіх осіб;{' '}
                      </li>{' '}
                      <li>
                        {' '}
                        є вульгарною або непристойною, містить нецензурну лексику, містить порнографічні зображення і&nbsp;тексти або сцени
                        сексуального характеру за&nbsp;участю неповнолітніх;{' '}
                      </li>{' '}
                      <li> містить сцени насильства, або нелюдяного поводження з&nbsp;тваринами; </li>{' '}
                      <li>
                        {' '}
                        містить опис засобів і&nbsp;способів суїциду, <span className="noBreak">будь-яке</span> підбурювання до&nbsp;його здійснення;{' '}
                      </li>{' '}
                      <li>
                        {' '}
                        пропагує і/або сприяє розпалюванню расової, релігійної, етнічної ненависті або ворожнечі, пропагує фашизм або ідеологію
                        расової переваги;{' '}
                      </li>{' '} 
                      <li>містить екстремістські матеріали;</li>{' '}
                      <li> пропагує злочинну діяльність або містить ради, інструкції або керівництва по&nbsp;здійсненню злочинних дій; </li>{' '}
                      <li>
                        {' '}
                        містить інформацію обмеженого доступу, включаючи, але не&nbsp;обмежуючись, державною і&nbsp;комерційною таємницею, інформацією
                        про приватне життя третіх осіб;{' '}
                      </li>{' '}
                      <li>
                        {' '}
                        містить рекламу або описує привабливість вживання алкоголю і/або наркотичних речовин, у&nbsp;тому числі "цифрових
                        наркотиків"(звукових файлів, що&nbsp;чинять дію на&nbsp;мозок людини за&nbsp;рахунок бінауральних ритмів), інформацію про
                        поширення наркотиків, рецепти їх&nbsp;виготовлення і&nbsp;раді із&nbsp;вживання;{' '}
                      </li>{' '}
                      <li>носить шахрайський характер;</li>{' '}
                      <li> а&nbsp;також порушує інші права і&nbsp;інтереси громадян і&nbsp;юридичних осіб або вимоги законодавства України. </li>{' '}
                    </ul>{' '}
                  </li>{' '}
                  <li>
                    {' '}
                    4.1.9. Не&nbsp;використовувати програмне забезпечення і&nbsp;здійснювати дії, що&nbsp;спрямовані на&nbsp;порушення нормального
                    функціонування Сайту і&nbsp;його сервісів або персональних сторінок Користувачів, не&nbsp;завантажувати, не&nbsp;зберігати,
                    не&nbsp;публікувати, не&nbsp;поширювати і&nbsp;не&nbsp;надавати доступ або іншим чином використовувати віруси, трояни і&nbsp;інші
                    шкідливі програми; не&nbsp;використовувати без спеціального на&nbsp;те&nbsp;дозволу Власника Сайту автоматизовані скрипти
                    (програми) для збору інформації на&nbsp;Сайті&nbsp;і (чи) взаємодії з&nbsp;Сайтом і&nbsp;його сервісами.{' '}
                  </li>{' '}
                  <li>
                    {' '}
                    <span className="noBreak">4.1.10</span>. Не&nbsp;намагатися отримати доступ до&nbsp;логіна і&nbsp;пароля іншого Користувача,
                    у&nbsp;тому числі включаючи, але не&nbsp;обмежуючись, обманом, зламом Профілів інших Користувачів і&nbsp;інше.{' '}
                  </li>{' '}
                </ol>{' '}
              </li>{' '}
              <li>
                {' '}
                4.2. Користувачу заборонено:{' '}
                <ol>
                  {' '}
                  <li> 4.2.1. Здійснювати незаконний збір і&nbsp;обробку персональних даних інших Користувачів. </li>{' '}
                  <li>
                    {' '}
                    4.2.2. Здійснювати доступ до&nbsp;будь-яких послуг іншим способом, окрім як&nbsp;через інтерфейс, наданий Власником Сайту.{' '}
                  </li>{' '}
                  <li>
                    {' '}
                    4.2.3. Відтворювати, дублювати, копіювати, передавати, продавати, здійснювати торгові операції і&nbsp;перепродавати послуги для
                    будь-яких цілей, за&nbsp;винятком випадків, коли такі дії були прямо дозволені Користувачу відповідно до&nbsp;умов окремої угоди
                    з&nbsp;Власником Сайту.{' '}
                  </li>{' '}
                  <li>
                    {' '}
                    4.2.4. Розміщувати комерційну і&nbsp;політичну рекламу поза спеціальними розділами Сайту, встановленими Власником Сайту.{' '}
                  </li>{' '}
                  <li>
                    {' '}
                    4.2.5. Розміщувати <span className="noBreak">будь-яку</span> інформацію, яка, на&nbsp;думку Власника Сайту, є небажаною, ущемляє
                    інтереси Користувачів або з&nbsp;інших причин є небажаною для розміщення на&nbsp;Сайті.{' '}
                  </li>{' '}
                </ol>{' '}
              </li>{' '}
              <li>
                {' '}
                4.3. Користувач має право:{' '}
                <ol>
                  {' '}
                  <li>
                    {' '}
                    4.3.1. Цілодобово отримувати доступ до&nbsp;сервера, на&nbsp;якому розташований Сайт, за&nbsp;винятком часу проведення
                    профілактичних робіт.{' '}
                  </li>{' '}
                  <li> 4.3.2. Самостійно змінювати пароль, не&nbsp;повідомляючи про&nbsp;це&nbsp;Власника Сайту. </li>{' '}
                  <li> 4.3.3. Самостійно редагувати раніше розміщену про&nbsp;себе інформацію на&nbsp;Сайті. </li>{' '}
                  <li>
                    {' '}
                    4.3.4. Звернутися в&nbsp;службу підтримки для блокування свого Профілю (за&nbsp;відсутності діючих{' '}
                    <span className="noBreak">демо-доступів</span> та&nbsp;передплат).{' '}
                  </li>{' '}
                </ol>{' '}
              </li>{' '}
              <li>
                {' '}
                4.4. Користувач погоджується з&nbsp;тим, що&nbsp;здійснюючи доступ до&nbsp;Сайту і&nbsp;користуючись його контентом, він:{' '}
                <ol>
                  {' '}
                  <li>
                    {' '}
                    4.4.1. Висловлює свою беззастережну згоду з&nbsp;усіма умовами справжньої Угоди і&nbsp;зобов’язується їх&nbsp;дотримуватися або
                    має припинити використовувати Сайт.{' '}
                  </li>{' '}
                  <li>
                    {' '}
                    4.4.2. Отримує особисте невиняткове і&nbsp;непередаване право використати контент Сайту на&nbsp;одному комп’ютері, за&nbsp;умови,
                    що&nbsp;ні&nbsp;сам Користувач, ні&nbsp;будь-які інші особи за&nbsp;сприяння Користувача не&nbsp;копіюватимуть або
                    не&nbsp;змінюватимуть програмне забезпечення; створювати програми, похідні від програмного забезпечення; проникати
                    в&nbsp;програмне забезпечення з&nbsp;метою отримання кодів програм; здійснювати продаж, поступку, здачу в&nbsp;оренду, передачу
                    третім особам у&nbsp;будь-якій іншій формі прав відносно програмного забезпечення сервісів, що&nbsp;надаються Сайтом.{' '}
                  </li>{' '}
                  <li>
                    {' '}
                    <p>
                      {' '}
                      4.4.3. В&nbsp;цілях реалізації даної Угоди Користувачі дають Власнику Сайту дозвіл на&nbsp;використання, зберігання, обробку
                      персональних даних тим способом і&nbsp;в&nbsp;тій мірі, в&nbsp;якій це&nbsp;необхідно для виконання умов даної Угоди.{' '}
                    </p>{' '}
                    <p>
                      {' '}
                      Порядок використання, зберігання, обробки і поширення персональних даних Користувачів розміщено на Сайті за адресою:{' '}
                      <a href="https://id.expertus.com.ua/private" className="noBreak">
                        https://id.expertus.com.ua/private
                      </a>
                      . Користувач погоджується на&nbsp;передачу третім особам персональних та&nbsp;інших даних, у&nbsp;тому числі для
                      їх&nbsp;обробки, для забезпечення функціонування Сайту, реалізації партнерських і&nbsp;інших програм, за&nbsp;умови забезпечення
                      відносно даних, що&nbsp;передаються, режиму, аналогічного режиму, існуючому на&nbsp;Сайті, у&nbsp;тому числі, включаючи, але
                      не&nbsp;обмежуючись передачею персональних даних особам, що&nbsp;афільовані з&nbsp;групою осіб Цифрового видавництва Експертус або уклали
                      з&nbsp;ними договори, а&nbsp;також третім особам у&nbsp;випадках, коли така передача потрібна для використання користувачем
                      певного сервісу або для виконання певної угоди або договору з&nbsp;користувачем.{' '}
                    </p>{' '}
                  </li>{' '}
                </ol>{' '}
              </li>{' '}
            </ol>{' '}
          </li>{' '}
          <li>
            {' '}
            <h3>5. Реєстрація Користувача</h3>{' '}
            <ol>
              {' '}
              <li>
                {' '}
                5.1. З&nbsp;метою користування доступом Користувач повинен пройти обов’язкову процедуру реєстрації на&nbsp;Сайті за&nbsp;адресою{' '}
                <a href="https://id.expertus.com.ua/registration" className="noBreak">
                  https://id.expertus.com.ua/registration
                </a>
                . Реєстрація Користувача на&nbsp;Сайті є безкоштовною, добровільною.{' '}
              </li>{' '}
              <li>
                {' '}
                5.2. При реєстрації на&nbsp;Сайті Користувач зобов’язаний надати Власнику Сайту необхідну достовірну і&nbsp;актуальну інформацію для
                формування Профілю, включаючи унікальні для кожного Користувача логін (адреса електронної пошти) і&nbsp;пароль доступу до&nbsp;Сайту,
                а&nbsp;також прізвище і&nbsp;ім’я. Реєстраційна форма Сайту може запросити у&nbsp;Користувача іншу додаткову інформацію.{' '}
              </li>{' '}
              <li>
                {' '}
                5.3. Після надання інформації, вказаної в&nbsp;п.&nbsp;5.2. даної Угоди, Користувачу необхідно підтвердити реєстрацію через натиснення
                на&nbsp;посилання в&nbsp;повідомленні, яке буде відправлено на&nbsp;вказану ним під час реєстрації електронну пошту.{' '}
              </li>{' '}
              <li>
                {' '}
                5.4. Після закінчення процесу реєстрації Користувач набуває права володіння Обліковою інформацією Користувача. Це&nbsp;означає,
                що&nbsp;він несе відповідальність за&nbsp;безпеку Облікової інформації, а&nbsp;також за&nbsp;всі майбутні дії на&nbsp;Сайті під даною
                Обліковою інформацією Користувача. Користувач зобов’язаний негайно повідомити Власника Сайту про будь-який випадок несанкціонованого
                доступу до&nbsp;Сайту, здійсненого третьою особою без згоди і&nbsp;відома Користувача і/або про будь-яке порушення безпеки Облікової
                інформації Користувача. Власник Сайту не&nbsp;несе відповідальність за&nbsp;можливу втрату або псування даних, яке може статися через
                порушення Користувачем положень даного пункту Угоди.{' '}
              </li>{' '}
              <li>
                {' '}
                5.5. Для початку роботи з&nbsp;Сайтом Користувач повинен внести у&nbsp;відповідному розділі Сайту свою Облікову інформацію.{' '}
              </li>{' '}
              <li>
                {' '}
                5.6. Якщо Користувачем не&nbsp;доведене зворотне, будь-які дії, здійснені з&nbsp;використанням його логіна і&nbsp;пароля, вважаються
                здійсненими відповідним Користувачем. У&nbsp;разі несанкціонованого доступу до&nbsp;логіна і&nbsp;пароля і/або Профілю Користувача або
                поширення логіна і&nbsp;пароля Користувач зобов’язаний негайно повідомити про це&nbsp;Власника Сайту.{' '}
              </li>{' '}

              <li>
                {' '}
              5.7. Якщо Користувач не бажає більше використовувати Сайти, то для цього йому достатньо лише вилогінитися (Вийти із сервісів Експертус).
               Користувач і Власник Сайту не можуть видалити Профіль Користувача з Сайту, адже Профіль є підтвердженням згоди Користувача на дотримання 
               Умов користування Сайтом з моменту реєстрації. 
              </li>{' '}
           
            </ol>{' '}
          </li>{' '}
          <li>
            {' '}
            <h3>6. Відповідальність Сторін</h3>{' '}
            <ol>
              {' '}
              <li>
                {' '}
                6.1. Користувач самостійно визначає перелік організаційних і&nbsp;програмних (для ЕОМ) засобів для збереження в&nbsp;таємниці своєї
                Облікової інформації і&nbsp;забезпечення санкціонованого доступу до&nbsp;неї. Власник Сайту не&nbsp;несе відповідальність
                за&nbsp;збитки, заподіяні Користувачу в&nbsp;результаті розголошування третім особам Облікової інформації Користувача, що&nbsp;сталися
                не&nbsp;з&nbsp;вини Власника Сайту. Якщо будь-яка особа, окрім Користувача, авторизується на&nbsp;Сайті, використовуючи Облікову
                інформацію Користувача, то&nbsp;усі дії, здійснені такою особою, вважатимуться здійсненими цим Користувачем. Користувач самостійно
                несе відповідальність за&nbsp;усі дії, здійснені ним на&nbsp;Сайті, а&nbsp;також за&nbsp;усі дії, здійснені на&nbsp;Сайті будь-якими
                іншими особами з&nbsp;використанням його Облікової інформації.{' '}
              </li>{' '}
              <li>
                {' '}
                Власник Сайту не&nbsp;гарантує, що&nbsp;програмне забезпечення Cайту не&nbsp;містить помилок і/або комп’ютерних вірусів або сторонніх
                фрагментів коду. Власник Сайту надає можливість Користувачу використовувати програмне забезпечення Сайту «Як&nbsp;воно&nbsp;є», без
                будь-яких гарантій з&nbsp;боку Власника Сайту.{' '}
              </li>{' '}
              <li>
                {' '}
                6.3. Власник Сайту не&nbsp;несе відповідальності за&nbsp;збитки, заподіяні Користувачу в&nbsp;результаті повідомлення іншим
                Користувачем недостовірної інформації, а&nbsp;також заподіяні діями (бездіяльністю) іншого Користувача. Власник Сайту
                не&nbsp;гарантує, що&nbsp;інформація, що&nbsp;міститься в&nbsp;Профілях Користувачів, є&nbsp;достовірною і&nbsp;повною.{' '}
              </li>{' '}
              <li>
                {' '}
                6.4. Власник Сайту докладає усіх можливих зусиль для забезпечення нормальної працездатності Сайту, проте не&nbsp;несе відповідальності
                за&nbsp;невиконання або неналежне виконання зобов’язань за&nbsp;Угодою, а&nbsp;також можливі збитки, що&nbsp;виникли у&nbsp;тому
                числі, але не&nbsp;обмежуючись, в&nbsp;результаті:{' '}
                <ul>
                  {' '}
                  <li>
                    {' '}
                    неправомірних дій Користувачів, спрямованих на&nbsp;порушення інформаційної безпеки або нормального функціонування Сайту;{' '}
                  </li>{' '}
                  <li>
                    {' '}
                    збоїв в&nbsp;роботі Сайту, викликаних помилками в&nbsp;коді, комп’ютерними вірусами і&nbsp;іншими сторонніми фрагментами коду
                    в&nbsp;програмному забезпеченні Сайту;{' '}
                  </li>{' '}
                  <li>
                    {' '}
                    відсутності (неможливості встановлення, припинення та&nbsp;ін.) <span className="noBreak">Інтернет-з</span>’єднань між сервером
                    Користувача і&nbsp;сервером Сайту;{' '}
                  </li>{' '}
                  <li>
                    {' '}
                    проведення державними чи&nbsp;іншими органами заходів у&nbsp;рамках Системи <span className="noBreak">
                      оперативно-розшукових
                    </span>{' '}
                    заходів;{' '}
                  </li>{' '}
                  <li>
                    {' '}
                    встановлення державного регулювання (чи&nbsp;регулювання іншими організаціями) господарської діяльності комерційних організацій
                    в&nbsp;мережі Інтернет і/або встановлення вказаними суб’єктами разових обмежень, що&nbsp;ускладнюють або роблять неможливим
                    виконання Угоди;{' '}
                  </li>{' '}
                  <li>
                    {' '}
                    інших випадків, пов’язаних з&nbsp;діями (бездіяльністю) Користувачів і/або інших суб’єктів, спрямованими на&nbsp;погіршення
                    загальної ситуації з&nbsp;використанням мережі Інтернет і/або комп’ютерного устаткування, що&nbsp;існувала на&nbsp;момент
                    укладення Договору, так само <span className="noBreak">будь-яких</span> інших дій, спрямованих на&nbsp;Сайт і&nbsp;на&nbsp;третіх
                    осіб;{' '}
                  </li>{' '}
                  <li> виконання робіт, вказаних в&nbsp;п.&nbsp;6.5&nbsp;і п.&nbsp;6.6 даної Угоди. </li>{' '}
                </ul>{' '}
              </li>{' '}
              <li>
                {' '}
                6.5. Власник Сайту має право проводити профілактичні роботи з&nbsp;тимчасовим призупиненням роботи Сайту по&nbsp;можливості
                в&nbsp;нічний час і&nbsp;максимально скорочуючи час непрацездатності Сайту, повідомляючи про це&nbsp;Користувача, якщо технічно
                це&nbsp;є&nbsp;можливим.{' '}
              </li>{' '}
              <li>
                {' '}
                6.6. У&nbsp;разі настання <span className="noBreak">форс-мажорних</span> обставин, а&nbsp;також аварій чи&nbsp;збоїв в&nbsp;{' '}
                <span className="noBreak">програмно-апаратних</span> комплексах третіх осіб, що&nbsp;співпрацюють з&nbsp;Власником Сайту, можливе
                призупинення роботи Сайту без попереднього повідомлення Користувача.{' '}
              </li>{' '}
              <li>
                {' '}
                6.7. Власник Сайту не&nbsp;несе відповідальності за&nbsp;порушення Користувачем даної Угоди і&nbsp;залишає за&nbsp;собою право
                на&nbsp;свій власний розсуд, а&nbsp;також при отриманні інформації від інших користувачів або третіх осіб про порушення Користувачем
                даної Угоди, змінювати (модерувати) або видаляти <span className="noBreak">будь-яку</span> опубліковану Користувачем інформацію,
                що&nbsp;порушує заборони, встановлені даною Угодою (включаючи особисті повідомлення), призупиняти, обмежувати або припиняти доступ
                Користувача до&nbsp;усіх або до&nbsp; <span className="noBreak">будь-якого</span> з&nbsp;розділів або сервісів Сайту у&nbsp;{' '}
                <span className="noBreak">будь-який</span> час з&nbsp;<span className="noBreak">будь-якої</span> причини або без пояснення причини,
                з&nbsp;попереднім повідомленням або без такого, не&nbsp;відповідаючи за&nbsp;<span className="noBreak">будь-яку</span> шкоду, яку може
                заподіяти така дія. Власник Сайту закріплює за&nbsp;собою право видалити Профіль Користувача і/(або) призупинити, обмежити або
                припинити доступ Користувача до&nbsp; <span className="noBreak">будь-якого</span> з&nbsp;сервісів Сайту, якщо виявить,
                що&nbsp;Користувач представляє загрозу для Сайту&nbsp;і (чи) його Користувачів. Власник Сайту не&nbsp;несе відповідальності
                за&nbsp;здійснене відповідно до&nbsp;даної Угоди тимчасове блокування або видалення інформації, або видалення Облікової інформації
                (припинення реєстрації) Користувача. Видалення Профілю Користувача означає автоматичне видалення усієї інформації, введеної при
                реєстрації на&nbsp;Сайті. Після видалення Облікової інформації Користувач втрачає право доступу до&nbsp;Сайту{' '}
              </li>{' '}
              <li>
                {' '}
                6.8. Жодна із&nbsp;Сторін не&nbsp;несе відповідальності за&nbsp;повне або часткове невиконання{' '}
                <span className="noBreak">будь-якого</span> зі&nbsp;своїх обов’язків, якщо невиконання є&nbsp;наслідком таких обставин,
                як&nbsp;повінь, пожежа, землетрус, інші стихійні лиха, війна або військові дії і&nbsp;інші обставини непереборної сили, які виникли
                після укладення Угоди і&nbsp;які не&nbsp;залежать від волі Сторін.{' '}
              </li>{' '}
            </ol>{' '}
          </li>{' '}
          <li>
            {' '}
            <h3>7. Порядок вирішення суперечок і&nbsp;врегулювання претензій</h3>{' '}
            <ol>
              {' '}
              <li>
                {' '}
                7.1. У&nbsp;разі виникнення суперечок між Користувачем і&nbsp;Власником Сайту з&nbsp;питань, пов’язаних з&nbsp;виконанням Угоди,
                Сторони вживатимуть заходів для їх&nbsp;вирішення шляхом переговорів між собою. Претензійний порядок вирішення суперечок обов’язковий.
                Претензії Користувачів приймаються і&nbsp;розглядаються Власником Сайту тільки письмово і&nbsp;в&nbsp;порядку, передбаченому даною
                Угодою і&nbsp;чинним законодавством України.{' '}
              </li>{' '}
              <li>
                {' '}
                7.2. Для вирішення суперечок, що&nbsp;виникли між Користувачем і&nbsp;Власником Сайту в&nbsp;результаті використання Сайту,
                застосовується наступний претензійний порядок:{' '}
                <ol>
                  {' '}
                  <li>
                    {' '}
                    Користувач, який вважає, що&nbsp;його права порушені через дії Власника Сайту, направляє останньому претензію, яка містить суть
                    вимоги, що&nbsp;пред’являється, її&nbsp;обґрунтування, а&nbsp;також усі дані Користувача. Претензія надсилається Власнику Сайту
                    письмово поштою;{' '}
                  </li>{' '}
                  <li>
                    {' '}
                    впродовж&nbsp;5 (п’яти) робочих днів з&nbsp;дня отримання претензії Власник Сайту зобов’язаний направити свою відповідь
                    на&nbsp;адресу електронної пошти або поштою на&nbsp;поштову адресу, вказану в&nbsp;претензії Користувача;{' '}
                  </li>{' '}
                  <li>
                    {' '}
                    у&nbsp;разі недосягнення згоди шляхом претензійного порядку суперечка підлягає розгляду відповідно до&nbsp;п. 7.4 Угоди;{' '}
                  </li>{' '}
                  <li>
                    {' '}
                    Власником Сайту не&nbsp;розглядаються анонімні претензії або претензії, що&nbsp;не&nbsp;дозволяють ідентифікувати Користувача
                    на&nbsp;основі наданих ним при реєстрації даних.{' '}
                  </li>{' '}
                </ol>{' '}
              </li>{' '}
              <li>
                {' '}
                7.3. Для вирішення технічних питань при визначенні провини Користувача в&nbsp;результаті його неправомірних дій при користуванні
                мережею Інтернет і&nbsp;Сайтом зокрема, Власник Сайту має право самостійно притягати компетентні організації в&nbsp;якості експертів.
                У&nbsp;разі встановлення провини Користувача, останній зобов’язаний відшкодувати витрати на&nbsp;проведення експертизи.{' '}
              </li>{' '}
              <li>
                {' '}
                7.4. При недосягненні згоди між Сторонами шляхом переговорів, суперечка, підлягає вирішенню у&nbsp;судовому порядку відповідно
                до&nbsp;вимог чинного законодавства України.{' '}
              </li>{' '}
            </ol>{' '}
          </li>{' '}
          <li>
            {' '}
            <h3>8. Інші умови</h3>{' '}
            <ol>
              {' '}
              <li>
                {' '}
                8.1. Дана Угода набуває чинності з&nbsp;моменту підтвердження даної Угоди Користувачем і&nbsp;діє весь термін користування Сайтом.{' '}
              </li>{' '}
              <li>
                {' '}
                8.2. Положення даної Угоди встановлюються, змінюються і&nbsp;відміняються Власником Сайту в&nbsp;односторонньому порядку без
                попереднього повідомлення. З&nbsp;моменту розміщення на&nbsp;Сайті нової редакції Угоди попередня редакція вважається такою,
                що&nbsp;втратила свою силу. У&nbsp;разі істотної зміни положень даної Угоди, Власник Сайту сповіщає про&nbsp;це&nbsp;Користувачів
                шляхом розміщення на&nbsp;Сайті відповідного повідомлення.{' '}
              </li>{' '}
              <li>
                {' '}
                8.3. Питання, не&nbsp;врегульовані даною Угодою, підлягають вирішенню у&nbsp;відповідності до&nbsp;чинного законодавства України.{' '}
              </li>{' '}
            </ol>{' '}
          </li>{' '}
        </ol>{' '}
      </div>{' '}
    </div>
  );
};
