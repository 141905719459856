import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { CircleHelpIcon } from '../../svgs/CircleHelpIcon';

export const HeaderS = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #232323;
  padding: 0;
  padding: 0 20px;
  max-width: inherit;
  position: relative;
  font: 13px/13px 'Helvetica Neue', Arial, sans-serif;
}
`;

const WrapperBtns = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  font: 13px/17px HelveticaNeueCyr, Helvetica Neue, Helvetica, Arial, sans-serif !important;
  position: relative;
  float: right;
`;

const BtnSupp = styled.a`
  width: 28px;
  height: 28px;
  float: left;
  margin-right: 20px;
  opacity: 0.8;
  border-bottom: none;
  color: rgb(204, 204, 204);
`;

export const ButtonHS = styled.div`
  min-width: 135px;
  color: white;
  height: 32px;
  position: relative;
  font-size: 12px;
  padding: 0 10px;
  margin: 0;
  border: none;
  border-radius: 0;
  background: #5b657f;
  line-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    opacity: 0.92;
  }
`;

export const Header: React.FC = () => {
  return (
    <HeaderS>
      <WrapperBtns>
        <BtnSupp title="Служба підтримки" href="/feedback">
          <CircleHelpIcon />
        </BtnSupp>
        <Link to="/login" >
          <ButtonHS>
            <span style={{ fontSize: '12px' }}>Вхід та реєстрація</span>
          </ButtonHS>
        </Link>
      </WrapperBtns>
    </HeaderS>
  );
};
