import styled from 'styled-components';

const WrappS = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  @media screen and (max-height: 500px) {
    height: auto;
  }
  @media screen and (max-width: 650px) {
    //height: calc(100vh - 130px);
      min-height: calc(100vh - 130px);
      height: auto;
  }
`;

const WrapperS = styled.div`
  height: calc(100% - 130px);
  padding-top: 79px;
  margin: 0 auto;
  max-width: 1214px;
  position: relative;

  @media screen and (max-width: 650px) {
    padding: 0;
    height: 100%;
    display: flex;
  }
  @media screen and (min-width: 651px) and (max-height: 550px) {
    padding-top: 0;
  }
  @media screen and (min-width: 651px) and (max-height: 450px) {
    height: auto;
  }
`;

const FormS = styled.div`
  color: #000;
  font: 14px/18px sans-serif;
  background-clip: padding-box;
  background-color: #efefef;
  border-color: #e5e5e5 #cccccc #b4b4b4;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 4px 15px rgb(74 70 62 / 35%);
  overflow: hidden;
  text-align: left;
  width: 600px;
  margin: 40px auto 0 auto;
  max-width: 100%;
  @media screen and (max-width: 650px) {
    width: 90%;
    max-width: 400px;
    margin: auto;
  }
`;
const FormSFrame = styled.div`
  color: #000;
  font: 14px/18px sans-serif;
  background-clip: padding-box;
  background-color: #efefef;
  border-color: #e5e5e5 #cccccc #b4b4b4;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 4px 15px rgb(74 70 62 / 35%);
  overflow: hidden;
  text-align: left;
  width: 660px;
  margin: 40px auto 0 auto;
  max-width: 100%;
`;
const MainS = styled.div`
  padding: 24px 20px 15px;
  position: relative;
`;

const TitleSub = styled.div`
margin-bottom: 20px;
`;

const TitleS = styled.div`
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
  @media screen and (max-width: 650px) {
    text-align: center;
  }
`;

const WrapperCascadeS = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  @media screen and (max-width: 650px) {
    flex-direction: column;
  }
`;
const Cascade1S = styled.div`
  max-width: 60%;
  width: 340px;
  padding-bottom: 10px;
  position: relative;
  @media (max-width: 650px) {
    max-width: 100%;
    width: 100%;
  }
`;
const btnWrapSoc = styled.div`
  display:flex;
  width:100px;
  justify-content: space-between;
`;
const btnWrap = styled.div`
  display:flex;
  width:270px;
  justify-content: space-between;
`;

const Cascade2S = styled.div`
  max-width: 70px;
  min-height: 1px;
  min-width: 25px;
  width: 4%;
  float: left;
`;

const Cascade3S = styled.div`
  overflow: hidden;
  font-size: 14px; 
  line-height: 18px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 10px;
  max-width: 260px;
  @media screen and (max-width: 650px) {
    text-align: center;
    display: none;
  }
`;

const Cascade4S = styled.div`
  overflow: hidden;
  font-size: 14px; 
  line-height: 18px;
  font-weight: bold;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
  text-align: center;
  display: none;
  @media screen and (max-width: 650px) {
    display: flex;
  }
`;
const BtnS = styled.button<{ disabled?: boolean }>`
  display: block;
  float: left;
  margin-right: 10px;
  font-size: 16px;
  height: 36px;
  padding: 8px 12px 9px;
  text-decoration: none;
  vertical-align: baseline;
  white-space: nowrap;
  font: 16px / normal sans-serif;
  float: left;
  margin-right: 10px;
  background-clip: padding-box;
  border-color: #3d8491 #126a7a #004d5b;
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  background-image: linear-gradient(#257584 5%, #005e6f 80%);
  box-sizing: border-box;
  background-color: #126979;

  ${({ disabled }) =>
    disabled
      ? `
      opacity: 0.5;
      `
      : `
      &:hover {
        opacity: 0.92;
      }
      `}
`;

const FooterS = styled.div`
  font-size: 11px;
  line-height: 14px;
  background: #e0e0e0;
  padding: 14px 20px;
  display: flex;
  align-items: flex-end;
  @media screen and (max-width: 650px) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

const WrapperLogoS = styled.div`
  width: 110px;
  height: 30px;
  background: url(/media/images/logo-expertus.png) left top no-repeat;
  background-size: 110px 30px;
  overflow: hidden;
  margin-right: 15px;
  &:before {
    width: 0;
    height: 150%;
    content: '';
    display: block;
  }
`;

const FeedbackS = styled.div`
  padding: 44px 34px 0 34px;
  h2 {
    font-size: 34px;
    line-height: 41px;
    font-weight: bold;
    margin-bottom: 9px;
  }
  p {
    margin: 0;
  }
`;

const ButtonS = styled.button<{ disabled?: boolean }>`
  box-sizing: border-box;
  background-color: #126979;
  background-image: linear-gradient(#257584 5%, #005e6f 80%);
  background-clip: padding-box;
  border-color: #3d8491 #126a7a #004d5b;
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  padding: 4px 16px;
  text-decoration: none;
  vertical-align: baseline;
  white-space: nowrap;
  font-size: 16px;
  height: 40px;
  position: relative;
  &:hover {
    opacity: 0.92;
  }

  ${({ disabled }) =>
    disabled
      ? `
      background: var(--grayColor);
      border-color: var(--grayColor);
    `
      : ''}
`;

const RememberS = styled.span`
  position: absolute;
  right: 30px;
  top: 68px;
  font-size: 14px;
  line-height: normal;
  display: inline-block;
  padding-right: 10px;
  text-align: right;
  vertical-align: baseline;
  width: 68px;
  border-color: rgba(0, 0, 0, 0.25);
  color: #000;
  border-style: dashed;
  border-width: 0 0 1px;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: #da0032;
  }
  @media screen and (max-width: 650px) {
    top: 84px;
  }
`;

const FieldEye = styled.span`
  right: 5px;
  top: 69px;
  position: absolute;
  cursor:pointer;
  @media screen and (max-width: 650px) {
    top: 85px;
  }
`;

const ButtonSWrap = styled.div`
  overflow: hidden;
  display: flex;
  width: 340px;
  justify-content: space-between;
  @media screen and (max-width: 650px) {
    width: 100%;
  }
`;


const EneredS = styled.div`
  display: flex; 
  width: 340px;
  height: 40px;
  justify-content: space-evenly;
  background-color: #efefef;
  align-items: center;
  border: 1px solid #bfbfbf;
  position: absolute;
  left: 0px;
  top: 56px;
  font-size: 14px;
  line-height: normal;
`;

const EneredBtnS = styled.div`
  font-size: 11px;
  line-height: 14px;
  position: relative;
  top: -1px;
  border: 1px solid #8fb5bb;
  border: 1px solid rgba(0, 94, 111, 0.4);
  border-radius: 3px;
  color: #005e6f;
  cursor: pointer;
  display: inline-block;
  padding: 3px 5px 2px;
  margin-top: -2px;
  vertical-align: baseline;
  &:hover {
    border: 1px solid #da0032;
    color: #da0032;
  }
`;

const UnderBtn = styled.div`
  font-size: 12px;
  padding-top: 10px;
  font-weight: normal;
  line-height: 1.2;
`;

const Cascade5S = styled.div`
   width: 100%;
   height: 30px;
   @media screen and (max-width: 650px) {
    height: 10px;
  }
`;

const Cascade6S = styled.div`
   width: 100%;
   height: 16px;
   display: none;
   @media screen and (max-width: 650px) {
    display: block;
  }
`;

// eslint-disable-next-line
export default {
  BtnS,
  Cascade1S,
  Cascade2S,
  Cascade3S,
  Cascade5S,
  Cascade6S,
  FooterS,
  FormS,
  MainS,
  TitleS,
  TitleSub,
  WrapperCascadeS,
  WrapperLogoS,
  WrapperS,
  FeedbackS,
  ButtonS,
  RememberS,
  FieldEye,
  EneredS,
  EneredBtnS,
  btnWrapSoc,
  btnWrap,
  UnderBtn,
  FormSFrame,
  ButtonSWrap,
  Cascade4S,
  WrappS
};