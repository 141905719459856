import { Actions, TDefRequest } from '../Actions';
import { ActionTypes, INewsletter } from './types';

const GetNewslettersAction = new Actions('GET_NEWSLETTERS', ActionTypes);
const SetNewslettersAction = new Actions('SET_NEWSLETTERS', ActionTypes);

export interface TypeGetNewslettersR extends TDefRequest {
  data: {
    userId: number,
  };
}

export interface TypeSetNewslettersR extends TDefRequest {
  data: {
    userId: number,
    list: INewsletter[]
  };
}

export const GetNewsletters = {
  request: (payload: TypeGetNewslettersR) => GetNewslettersAction.request(payload),
  success: (payload: {}) => GetNewslettersAction.success(payload || {}),
  error: (message: string) => GetNewslettersAction.error(message),
};

export const SetNewsletters = {
  request: (payload: TypeSetNewslettersR) => SetNewslettersAction.request(payload),
  success: (payload: {}) => SetNewslettersAction.success(payload || {}),
  error: (message: string) => SetNewslettersAction.error(message),
};
