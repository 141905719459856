import React from 'react';
import s from './styles';
import { INewsletter } from '../../../store/newsletters/types';

type Props = {
    item: INewsletter
    handleChange: Function
}

export const NewslettersItem: React.FC<Props> = (props) => {
    const { item, handleChange } = props;
    const [status, setStatus] = React.useState(item.status);

    const onChange = () => {
        const _status = !status;
        const _item = {...item, status: _status}
        handleChange(_item)
        setStatus(_status)
    }

  return (
    <s.ItemS className={'newsletters-item'}>
            <s.NameWrapS>
                <s.NameS>{ item.text }</s.NameS>
                <s.StatusS>{ status ? 'Ви отримуєте розсилки' : 'Ви не отримуєте розсилки' }</s.StatusS>                
            </s.NameWrapS>
            <s.SwitchS
                className={'newsletters-switch'}
                onClick = {()=>onChange()}
                status = { status }>
                <s.SwitcherS status = { status }/>
            </s.SwitchS>
    </s.ItemS>
  );
};
