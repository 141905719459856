import React from 'react';

export const CloseIcon: React.FC = () => {
  return (
    <svg viewBox="0 0 24 24" fill="#000" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.34304 17.6555C5.9818 17.2943 5.9818 16.7086 6.34304 16.3474L16.3459 6.34453C16.7071 5.98329 17.2928 5.98329 17.6541 6.34453C18.0153 6.70576 18.0153 7.29144 17.6541 7.65268L7.65119 17.6555C7.28995 18.0168 6.70427 18.0168 6.34304 17.6555Z"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.65207 6.34449L17.6549 16.3473C18.0162 16.7086 18.0162 17.2943 17.6549 17.6555C17.2937 18.0167 16.708 18.0167 16.3468 17.6555L6.34392 7.65264C5.98268 7.2914 5.98268 6.70572 6.34392 6.34449C6.70515 5.98325 7.29083 5.98325 7.65207 6.34449Z"
      ></path>
    </svg>
  );
};
