import React from 'react';

export const AttetionIcon: React.FC = () => {
  return (
    <svg viewBox="0 0 20 20" fill="#000" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.99995 3.925C6.64482 3.925 3.92495 6.64487 3.92495 10C3.92495 13.3551 6.64482 16.075 9.99995 16.075C13.3551 16.075 16.075 13.3551 16.075 10C16.075 6.64487 13.3551 3.925 9.99995 3.925ZM2.07495 10C2.07495 5.62314 5.62309 2.075 9.99995 2.075C14.3768 2.075 17.925 5.62314 17.925 10C17.925 14.3769 14.3768 17.925 9.99995 17.925C5.62309 17.925 2.07495 14.3769 2.07495 10Z"
      ></path>
      <path d="M11 7C11 7.55228 10.5523 8 10 8C9.44772 8 9 7.55228 9 7C9 6.44772 9.44772 6 10 6C10.5523 6 11 6.44772 11 7Z"></path>
      <path d="M11 13C11 13.5523 10.5523 14 10 14C9.44772 14 9 13.5523 9 13L9 10C9 9.44772 9.44772 9 10 9C10.5523 9 11 9.44772 11 10L11 13Z"></path>
    </svg>
  );
};
