import { URI } from '../config';
const initID3 = (callback: (value: string | null) => void) => {
  (() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = process.env.REACT_APP_ENV === 'local' ? 'loader.js' :`${URI}/js/loader.js`;
    script.onload = onLoadLoaderScript;
    document.getElementsByTagName('head')[0].appendChild(script);
  })();

  function onLoadLoaderScript() {
    //@ts-ignore
    window.AuthButton.init({ appId: 2500, typeBtn: 1, handleToken });
  }

  function handleToken(token: string, userId: number) {
    callback(token);
  }
};

export default initID3;
