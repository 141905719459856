import { Actions, TDefRequest } from '../Actions';
import { ActionTypes } from './types';

const GetBookmarksAction = new Actions('GET_BOOKMARKS', ActionTypes);
const DeleteBookmarksAction = new Actions('DELETE_BOOKMARKS', ActionTypes);
const SetCountBookmarksAction = new Actions('SETCOUNT_BOOKMARKS', ActionTypes);
const CheckAllNewBookmarksAction = new Actions('CHECKALL_BOOKMARKS', ActionTypes);

export interface TypeGetBookmarksR extends TDefRequest {
  data: {
    appId: number,
    userId?: number,
    isId: boolean
  };
}

export interface TypeDeleteBookmarksR extends TDefRequest {
  data: {
    appId: number,
    userId: number,
    url: string
  };
}
export interface TypeSetCountBookmarksR extends TDefRequest {
  data: {
    absnum: number,
    isId:boolean
  };
}
export interface TypeCheckAllNewBookmarksR extends TDefRequest {
  data: {
    appId: number,
    userId: number,
  };
}
export const GetBookmarks = {
  request: (payload: TypeGetBookmarksR) => GetBookmarksAction.request(payload),
  success: (payload: {}) => GetBookmarksAction.success(payload || {}),
  error: (message: string) => GetBookmarksAction.error(message),
};

export const DeleteBookmarks = {
  request: (payload: TypeDeleteBookmarksR) => DeleteBookmarksAction.request(payload),
  success: (payload: {}) => DeleteBookmarksAction.success(payload || {}),
  error: (message: string) => DeleteBookmarksAction.error(message),
};

export const SetCountBookmarks = {
  request: (payload: TypeSetCountBookmarksR) => SetCountBookmarksAction.request(payload),
  success: (payload: {}) => SetCountBookmarksAction.success(payload || {}),
  error: (message: string) => SetCountBookmarksAction.error(message),
};

export const CheckAllNewBookmarks = {
  request: (payload: TypeCheckAllNewBookmarksR) => CheckAllNewBookmarksAction.request(payload),
  success: (payload: {}) => CheckAllNewBookmarksAction.success(payload || {}),
  error: (message: string) => CheckAllNewBookmarksAction.error(message),
};
