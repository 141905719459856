import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { IProduct } from '../../../store/products/types';
import { LinkIcon } from '../../svgs/LinkIcon';
import 'moment/locale/uk';

const ProductCardS = styled.div<{ first?: boolean }>`
  display: flex;
  ${({ first }) =>
    !first
      ? `
      padding-top: 32px;
      margin-top: 32px;
      border-top: 1px solid #eaebf1;
      `
      : ``}
`;

const ProductContentS = styled.div`
  flex: 1;
`;

const ProductHeaderS = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  min-height: 40px;
`;

const ProductNameS = styled.h2`
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #14151a;
  flex: 1;
`;

const ProductDescriptionS = styled.div`
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
`;

const ProductListS = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ProductLinkS = styled.a`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  position: relative;
  &:hover {
    svg {
      fill: brown;
    }
  }
`;

const WrapperLinkS = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 5px 0;
`;

interface LinkProductType {
  product: IProduct;
}

const LinkProduct: React.FC<LinkProductType> = ({ product }) => {
  return (
    <WrapperLinkS>
      <ProductLinkS href={product.url} target="_blank" rel="noreferrer noopener">
        <div style={{ marginRight: '6px' }}>
          <LinkIcon />
        </div>
        <span>{product.subName}</span>
      </ProductLinkS>
      <ProductDescriptionS>
        <span>
          Термін передплати: {moment(product.dateStart).format('DD MMMM YYYY')} — {moment(product.dateEnd).format('DD MMMM YYYY')}
        </span>
      </ProductDescriptionS>
    </WrapperLinkS>
  );
};

interface PropsType {
  first?: boolean;
  product: IProduct;
}

export const Product: React.FC<PropsType> = ({ first, product }) => {
  return (
    <ProductCardS first={first}>
      <ProductContentS>
        <ProductHeaderS>
          <ProductNameS>{product.name}</ProductNameS>
        </ProductHeaderS>
        <ProductListS>
          {product.children && product.children.length > 0 ? (
            product.children.map((it, i) => <LinkProduct key={`${i}-${it.absnum}`} product={it} />)
          ) : (
            <LinkProduct product={product} />
          )}
        </ProductListS>
      </ProductContentS>
    </ProductCardS>
  );
};
