import { Actions, TDefRequest } from '../Actions';
import { ActionTypes, TConfigurationsState } from './types';

const GetConfigurationsAction = new Actions('GET_CONFIGURATIONS', ActionTypes);

export interface TypeGetConfigurationsR extends TDefRequest {}

export const GetConfigurations = {
  request: (payload: TypeGetConfigurationsR) => GetConfigurationsAction.request(payload),
  success: (payload: TConfigurationsState['data']) => GetConfigurationsAction.success(payload || {}),
  error: (message: string) => GetConfigurationsAction.error(message),
};
