import React from 'react';
import styled from 'styled-components';

const FooterS = styled.footer`
  margin-top: auto;
  font-size: 16px;
  line-height: 19px;
  color: #929294;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 46px 0 54px;
  border-top: 1px solid #d9dce3;
  font-weight: 400;
`;

const FooterLinkS = styled.div`
  line-height: 24px;
`;

const LinkTextS = styled.u`
  text-decoration: none;
  display: inline-flex;
  align-items: center;
`;
const year = new Date().getFullYear();
export const Footer: React.FC = () => {
  return (
    <div style={{ width: '100%', maxWidth: '1440px', margin: '0 auto'}}>
      <FooterS>
      <FooterLinkS>
         © Цифрове видавництво Експертус {year}<br/>
          
              {'Відділ клієнтської підтримки: '}
              <a href="tel:+380800213838" style={{ color: 'inherit', lineHeight: '19px' }}>
                0 800 21 38 38
              </a> <br/>
            Графік роботи — будні з 9:00 до 18:00
          </FooterLinkS>

        <div>
          <a href="/agreement" target="_blank" title="Угода про умови користування Сайтом">
            <LinkTextS>Угода про умови користування Сайтом</LinkTextS>
          </a>
        </div>
      </FooterS>
    </div>
  );
};
