import React from 'react';

export const Trash = ({color = "#C2C2C2"}: {color?: string}) => {
  return (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3 4.875C3 4.52982 3.27982 4.25 3.625 4.25H17.375C17.7202 4.25 18 4.52982 18 4.875C18 5.22018 17.7202 5.5 17.375 5.5H3.625C3.27982 5.5 3 5.22018 3 4.875Z" fill={color}/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.625 8C8.97018 8 9.25 8.27982 9.25 8.625V13.625C9.25 13.9702 8.97018 14.25 8.625 14.25C8.27982 14.25 8 13.9702 8 13.625V8.625C8 8.27982 8.27982 8 8.625 8Z" fill={color}/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.375 8C12.7202 8 13 8.27982 13 8.625V13.625C13 13.9702 12.7202 14.25 12.375 14.25C12.0298 14.25 11.75 13.9702 11.75 13.625V8.625C11.75 8.27982 12.0298 8 12.375 8Z" fill={color}/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.875 4.25C5.22018 4.25 5.5 4.52982 5.5 4.875V16.75H15.5V4.875C15.5 4.52982 15.7798 4.25 16.125 4.25C16.4702 4.25 16.75 4.52982 16.75 4.875V16.75C16.75 17.0815 16.6183 17.3995 16.3839 17.6339C16.1495 17.8683 15.8315 18 15.5 18H5.5C5.16848 18 4.85054 17.8683 4.61612 17.6339C4.3817 17.3995 4.25 17.0815 4.25 16.75V4.875C4.25 4.52982 4.52982 4.25 4.875 4.25Z" fill={color}/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.29918 2.29917C7.65081 1.94754 8.12772 1.75 8.625 1.75H12.375C12.8723 1.75 13.3492 1.94754 13.7008 2.29917C14.0525 2.65081 14.25 3.12772 14.25 3.625V4.875C14.25 5.22018 13.9702 5.5 13.625 5.5C13.2798 5.5 13 5.22018 13 4.875V3.625C13 3.45924 12.9342 3.30027 12.8169 3.18306C12.6997 3.06585 12.5408 3 12.375 3H8.625C8.45924 3 8.30027 3.06585 8.18306 3.18306C8.06585 3.30027 8 3.45924 8 3.625V4.875C8 5.22018 7.72018 5.5 7.375 5.5C7.02982 5.5 6.75 5.22018 6.75 4.875V3.625C6.75 3.12772 6.94754 2.65081 7.29918 2.29917Z" fill={color}/>
  </svg>
  );
};
