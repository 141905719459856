import styled from 'styled-components';

const BtnS = styled.button<{ disabled?: boolean }>`
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  line-height: 24px;
  padding: 7px 15px 9px 40px;
  background-color: var(--blueColor2);
  color: var(--blueColor);
  appearance: none;
  border: 1px solid transparent;
  border-radius: 3px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  flex-wrap: nowrap;
  justify-content: center;
  outline: none;
  text-align: center;
  white-space: nowrap;
  max-width: 220px;
  margin: 16px 0 0;
  width: 100%;
  position: relative;
  &:before,
  &:after {
    content: '';
    display: block;
    height: 2px;
    width: 19px;
    position: absolute;
    left: 15px;
    top: 18px;
    background-color: var(--blueColor);
  }
  &:after{
    height: 18px;
    width: 2px;
    left: 24px;
    top: 10px;
  }

  ${({ disabled }) =>
    disabled
      ? `
        background-color: var(--grayColor);
        color: var(--whiteColor);
        &:before,
        &:after {
          background-color: var(--whiteColor);
        }
        `
      : ''}
`;

const BtnContentS = styled.span`
  display: inline-block;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
`;

const IconS = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 4px;
  position: absolute;
  right: -40px;
  top: 0px;
`;

const WrapS = styled.div`
  width: 100%;
  margin-bottom: 20px;
  position: relative;
`;

const InfoS = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: 20px;
  border: 1px solid var(--redColor6);
  color: var(--redColor6);
`;
const InfoС = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 55px;
  border-radius: 20px;
  border: 1px solid var(--redColor6);
  color: var(--redColor6);
`;
const InputS = styled.div`
  font-size: 14px;
  line-height: 20px;
  padding: 6px 12px;
  background: var(--whiteColor);
  border: 1px solid var(--grayColor102);
  box-sizing: border-box;
  border-radius: 3px;
  margin: 0 0 5px 0;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  height: 40px;
  outline: none;
`;
// eslint-disable-next-line
export default {
    BtnS,
    BtnContentS,
    IconS,
    WrapS,
    InfoS,
    InputS,
    InfoС
};
