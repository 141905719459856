// @ts-nocheck
import React from 'react';
import FacebookLogin from 'react-facebook-login';
import '../styles';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GetFBUser } from '../../../store/user/actions'; 

const FaceBookAuthButton = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userData, setUserData] = React.useState({});

  const login = () => {
    if (userData && userData.facebookUserId) {
      dispatch(
        GetFBUser.request({
          data: userData,
          callBack: (succ: any, data: any) => {

            if (props.setIsSucc) {
              if (succ) {
                props.setIsSucc({
                  status: 'success',
                  url: '',
                  tokenCoock: data?.tokenCoock || `${JSON.stringify(data)} NO tokenCoock2`
                })
              } else {
                const getDataParam = `&email=${data?.email ? data.email : ''}&firstName=${data?.firstName ? data?.firstName : ''}&lastName=${data?.lastName ? data?.lastName : ''}`
                if (data.redirect) {
                  props.setIsSucc({
                    status: 'register',
                    url: `${data.redirect}${getDataParam}`,

                  })
                }

              }
            } else {
              if (!succ && data.message) {
                props.setError(data.message);
              }
              if (!succ && data.redirect) {
                if (props.redirect) {
                  navigate(data.redirect);
                } else {
                  navigate(`/registration`);
                }
              }
              if (succ) {
                navigate('/main');
              }
            }
          },
        })
      );
    }

  }
  const callbackFunc = (response: any) => {
    if (response) {
      const data = {
        facebookToken: response.accessToken,
        facebookUserId: response.userID,
      }
      setUserData(data);
    }

  }

  return (
    <>
      <div style={{ display: 'none' }}>
        <FacebookLogin
          autoLoad={true}
          appId={process.env.REACT_APP_FACEBOOK_APP_ID}
          callback={callbackFunc}
          scope="email"
          textButton=''
          fields="name, email, picture"
          disableMobileRedirect={true}
          isMobile={true}


        />
      </div>
      {userData.facebookUserId ? (
      <div className='facebookIcon' onClick={() => login()}></div>
      ):null}
    </>
  );
};

export default FaceBookAuthButton;
