import React from 'react';

export const CircleHelpIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
      <g fill="none" fillRule="evenodd" stroke="#ccc">
        <path d="M13.5 26.5c7.18 0 13-5.82 13-13s-5.82-13-13-13-13 5.82-13 13 5.82 13 13 13z"></path>
        <circle cx="13.5" cy="13.5" r="7.5"></circle>
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15.866.715L14.34 5.948M18.955 1.83l-1.608 5.148M20.997 14.216l5.16 1.801M20.384 17.15l4.788 2.012M.88 10.549l5.055 2.044M2.137 7.645l4.81 2.172M12.56 21.026L11.037 26.3M10.002 20.136l-1.915 5.015"
        ></path>
      </g>
    </svg>
  );
};
