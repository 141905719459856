import React from 'react';

export const ArrowIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className="injected-svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.24414 7.24414C5.56966 6.91862 6.09743 6.91862 6.42294 7.24414L9.75023 10.571L13.0771 7.24415C13.4026 6.91863 13.9303 6.91863 14.2559 7.24415C14.5814 7.56966 14.5814 8.09743 14.2559 8.42295L9.75023 12.9286L5.24414 8.42294C4.91862 8.09743 4.91862 7.56966 5.24414 7.24414Z"
        fill="#6B6861"
      ></path>
    </svg>
  );
};
