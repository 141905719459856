import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../../store/applicationState';
import { CaretCircle } from '../../svgs/CaretCircle';
import { CloseIcon } from '../../svgs/CloseIcon';
import { MagnifyingGlass } from '../../svgs/MagnifyingGlass';
import { ListChecks } from '../../svgs/ListChecks';
import { IBookmark, IBookmarkProduct } from '../../../store/bookmarks/types';
import { GetBookmarks, DeleteBookmarks, CheckAllNewBookmarks } from '../../../store/bookmarks/actions';

import styled from 'styled-components';
import { Bookmark } from './Bookmark';
import { BookmarkProduct } from './BookmarkProduct';
import { MessageEmpty } from './MessageEmpty';

const WrapperS = styled.div`
  padding-top: 0px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

const MainS = styled.div`
  flex: 3;
  max-height: 450px;
  overflow: auto;
`;

const HeaderS = styled.div`
display: flex;
width: 100%;
font-weight: 400;
font-size: 18px;
line-height: 1.3;
color: #616161;
padding: 0 15px;
background: #F5F5F5;
padding: 0 calc((100% - 1440px) / 2);
@media (max-width: 1504px) {
  padding: 0 64px;
}
`;

const ContentS = styled.div`
display: flex;
width: 100%;
min-height: 450px;
max-width:1470px;
margin: 0 auto;
@media (max-width: 1504px) {
  padding: 0 64px;
}
`;
const SidebarS = styled.div(props => ({
  flex: props.slot === '1' ? '1' : 'inherit',
  width: props.slot === '1' ? '25%' : '0',
  overflow: 'hidden',
  transition: 'all 0.2s ease',
  visibility: props.slot === '1' ? 'visible' : 'hidden',
}));

const SidebarWrapS = styled.div`
min-width: 100%
`;

const CaretS = styled.div`
height: 56px;
display: flex;
align-items: center;
padding: 0;
margin-right: 15px;
`;

const TitleS = styled.div`
height: 56px;
display: flex;
align-items: center;
padding: 0;
flex: 3;
position: relative;
background: #F7F7F7;
border-radius: 5px 5px 0 0;
border-bottom: 1px solid #EDEDED;
`;

const ProductTitleS = styled.div`
font-size: 18px;
flex: 2;
`;
const SidebarTitleS = styled.div(props => ({
  color: '#424242',
  fontWeight: 600,
  height: '56px',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  flex: props.slot === '1' ? '1' : 'inherit',
  width: props.slot === '1' ? '25%' : '0',
  overflow: 'hidden',
  transition: 'all 0.2s ease',
  visibility: props.slot === '1' ? 'visible' : 'hidden',
}));

const SearchS = styled.input`
flex: 1;
padding: 4px 16px;
border: 1px solid #E0E0E0;
border-radius: 4px;
height: 40px;
`;

const ClearS = styled.div`
width: 24px;
height: 24px;
position: absolute;
right: 20px;
cursor: pointer;
`;

const CheckAllS = styled.div`
flex: 1;
padding: 4px 15px;
background: #EDEDED;
border-radius: 4px;
height: 40px;
font-size: 15px;
font-weight: 400;
display: flex;
align-items: center;
justify-content: center;
margin-right: 20px;
cursor: pointer;
color: #9E9E9E;
&:hover{
  background: #E1E1E1;
}
svg{
  margin-left: 10px;
}
`;


const CaretIconS = styled.div(props => ({
  transform: props.slot === '1' ? 'rotate(0deg)' : 'rotate(180deg)',
  cursor: 'pointer',
  transition: 'all 0.2s ease',
}));

export const Bookmarks: React.FC = () => {
  const dispatch = useDispatch();
  const { User, Bookmarks } = useSelector((store: AppStore) => store);
  const [showSb, setShowSb] = React.useState(true);
  const [currentProduct, setCurrentProduct] = React.useState<IBookmarkProduct>();
  const [search, setSearch] = React.useState('');
  const [list, setList] = React.useState<IBookmark[] | null>([]);
  const [products, setProducts] = React.useState<IBookmarkProduct[] | null>([]);
  const ref = React.useRef<HTMLInputElement>(null);
  const urlParams = new URLSearchParams(window.location.search);
  const appId = urlParams.get('appid');
  React.useEffect(() => {
    if (User.isAuth) {
      dispatch(
        GetBookmarks.request({
          data: {
            appId: currentProduct?.app_id || 0,
            userId: User.userProfile?.id,
            isId: true
          },
        })
      );
    }
  }, [User, dispatch, currentProduct]);

  React.useEffect(() => {
    if (Bookmarks && Bookmarks.data && Bookmarks.data.list) {
      setList(Bookmarks.data.list);
    }
    if (Bookmarks && Bookmarks.data && Bookmarks.data.products && Bookmarks.data.products.length > 0) {
      setProducts(Bookmarks.data.products);
      let curent = Bookmarks.data.products[0]
      if(appId){
        const findProduct =  Bookmarks.data.products.find((el)=>el.app_id === Number(appId))
        if(findProduct){
          curent = findProduct;
        }
      }
    
      setCurrentProduct(curent)
    }

  }, [Bookmarks, appId]);

  const handleSearch = () => {
    if (ref.current) {
      const value = ref.current.value;
      setSearch(value);
      if (Bookmarks && Bookmarks.data && Bookmarks.data.list) {
        const list = Bookmarks.data.list.filter((item) => item.title.toLowerCase().indexOf(value.toLowerCase()) !== -1);
        setList(list);
      }

    }
  }

  const handleClear = () => {
    if (ref.current) {
      setSearch('');
      ref.current.value = '';
      if (Bookmarks && Bookmarks.data && Bookmarks.data.list) {
        setList(Bookmarks.data.list);
      }
    }
  }

  const deleteBookmark = (props: IBookmark) => {
    dispatch(
      DeleteBookmarks.request({
        data: {
          appId: props.app_id,
          userId: props.user_id,
          url: props.url,
        },
      })
    );
  }

  const handleCheckAll = () => {
    if (list) {
      dispatch(
        CheckAllNewBookmarks.request({
          data: {
            appId: currentProduct?.app_id || 0,
            userId: User.userProfile?.id || 0,
          },
        })
      );
      const _list = list.map((item) => {
        item.isNew = 0
        return item;
      });      
      setList(_list);
      if(currentProduct && products && products.length > 0){ 
        const _products = products.map((item) => {
          if(item.app_id === currentProduct.app_id){
            item.isNew = 0;
          }
          return item;
        });   
        setProducts(_products);
      }
    }
  }

  const reduceCounter = (app_id: number) => {
    if(products && products.length > 0){
      const _products = [...products];
      for(let product of _products){
        if(product.app_id === app_id){
          product.isNew -= 1;
        }
      }  
      setProducts(_products);
    }
  }

  const productsJSX = products && products.length > 1 ? products.map((product, i) => {    
    return (
      <BookmarkProduct
      key={`${i}-${product.absnum}`}
      currentProduct = { currentProduct }
      product = { product }
      setCurrentProduct = { setCurrentProduct }
      isNew = { product.isNew }
      />
    );
  }) : null;

  const itemsJSX = list && list.length > 0 ? list.map((row, i) => {    
    return (
      <Bookmark
          key={`${i}-${row.absnum}`}
          bookmark={row}
          handleChange={deleteBookmark}
          reduceCounter={reduceCounter}
        />
    );
  }) : null;

  return (
    <WrapperS>
      <HeaderS>
      {products && products.length > 1 ? (
        <SidebarTitleS slot={showSb ? '1' : ''}>
          Мої закладки
        </SidebarTitleS>
        ) : null}
        <TitleS>
          {products && products.length > 1 ? (
            <CaretS onClick={() => setShowSb(!showSb)}>
              <CaretIconS slot={showSb ? '1' : ''}>
                <CaretCircle />
              </CaretIconS>
            </CaretS>
          ) : null}
          <ProductTitleS>
            {currentProduct?.name}
          </ProductTitleS>
          <CheckAllS onClick={() => handleCheckAll()}>
            {'Відмітити все як прочитане'}
            <ListChecks />
          </CheckAllS>
          <SearchS type='text' placeholder='Пошук...' ref={ref} onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSearch();
            }
          }} />
          <ClearS>
            {search ? (
              <div onClick={() => handleClear()}>
                <CloseIcon />
              </div>
            ) : (
              <div onClick={() => handleSearch()}>
                <MagnifyingGlass />
              </div>
            )}
          </ClearS>
        </TitleS>
      </HeaderS>
      <ContentS>
        {productsJSX ? (
          <>
            <SidebarS slot={showSb ? '1' : ''}>
              <SidebarWrapS>
                {productsJSX}
              </SidebarWrapS>
            </SidebarS>
          </>
        ) : null}
        <MainS>
          {itemsJSX ? (
            <>
              { itemsJSX }
            </>
          ) : (
            <MessageEmpty />
          )}
        </MainS>
      </ContentS>
    </WrapperS>
  );
};
