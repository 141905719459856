import React from 'react';
import TextField from '@mui/material/TextField';
import ukLocale from 'date-fns/locale/uk';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import styled from 'styled-components';
import s from './styles';

const BootstrapInput = styled(TextField)({
  width: '100%',
  '& .MuiInputBase-input': {
    padding: 0,
  },
  '& .MuiInputAdornment-root': {
    display: 'none',
  },
  '& .MuiOutlinedInput-root': {
    fontSize: '14px',
    lineHeight: '20px',
    padding: '6px 12px',
    background: 'var(--whiteColor)',
    boxSizing: 'border-box',
    borderRadius: '3px',
    margin: '0',
    position: 'relative',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    height: '40px',
    outline: 'none',
    '&.Mui-focused fieldset': {
      border: '1px solid var(--blueColor)',
    },
  },
});

interface PropsType {
  id?: string;
  label?: string;
  value?: Date | null;
  error?: string;
  required?: boolean;
  style?: React.CSSProperties | undefined;
  onChange: (value: Date | null) => void;
}

export const BasicDatePicker: React.FC<PropsType> = ({ value, onChange, label, style, error }) => {
  return (
    <s.FormGroupS>
      <s.FormLabelS>{label}</s.FormLabelS>
      <s.FIeldWrapperS>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ukLocale}>
          <DesktopDatePicker
            inputFormat="dd.MM.yyyy"
            value={value}
            onChange={onChange}
            renderInput={(params) => <BootstrapInput placeholder="ДД.ММ.ГГГГ" {...params} />}
          />
        </LocalizationProvider>
      </s.FIeldWrapperS>
      {error ? <s.WarningS>{error}</s.WarningS> : null}
    </s.FormGroupS>
  );
};
