import React from 'react';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { Support } from '../components/content/Support';
import s from './styles';

export const Feedback: React.FC = () => {
  return (
    <s.WrapperS>
      <Header />
      <s.WrapperMainS> 
        <Support />
      </s.WrapperMainS>
      <s.WrapperItemS>
        <Footer />
      </s.WrapperItemS>
    </s.WrapperS>
  );
};
