export const validatePhone = (phone: string) => {
  if (!phone) return '';
  let validPh = '';
  if (phone) {
    let str = phone.replace(/[^0-9.]/g, '');
    const idx = str.length - 9;
    validPh = str.substring(idx);
  }
  return validPh;
};
