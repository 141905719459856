import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { callApi } from '../../utils/callApi';
import { GetProducts, TypeGetProductsR, ActivateProduct, TypeActivateProductR } from './actions';
import { ActionTypes, IProduct } from './types';

function* getProductsWorker(action: ReturnType<typeof GetProducts.request>): Generator {
  const { callBack } = action.payload as TypeGetProductsR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/products`,
    })) as IProduct[];
    yield put(GetProducts.success(resp));
  } catch (e) {
    success = false;
    yield put(GetProducts.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* activateProductWorker(action: ReturnType<typeof ActivateProduct.request>): Generator {
  const { data, callBack } = action.payload as TypeActivateProductR;

  let success = true;
  let error = '';
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      data,
      path: '/products/activate',
    })) as IProduct;
    yield put(ActivateProduct.success(resp));
  } catch (e) {
    success = false;
    error = e as string;
    yield put(ActivateProduct.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, error);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_PRODUCTS_R, getProductsWorker);
  yield takeEvery(ActionTypes.ACTIVATE_PRODUCT_R, activateProductWorker);
}

export default function* productsSaga() {
  yield all([fork(watchFetchRequest)]);
}
