import styled from 'styled-components';

const WrapS = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    min-height: 500px;
    max-width: 1440px;
    position: relative;
    padding: 10px;

    &.frame {
        padding-top: 0;
        min-height: auto;

        h2 {
            font-size: 18px;
            line-height: 1;
            margin: 10px 0;
        }

        p {
            font-size: 12px;
            line-height: 1.25;
            margin: 0;
            
            a {
                line-height: inherit;
            }
        }

        .line,
        .newsletters-item {
            width: auto;
            height: auto;
            margin-bottom: 10px;
        }

        .line {
            margin: 10px 0;
        }

        .newsletters-save {
            position: fixed;
            bottom: 5px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 14px;
        }

        .newsletters-switch {
            height: 16px;
            width: 28px;
            min-width: 28px;
            border-radius: 8px;

            > div {
                height: 11px;
                width: 11px;
            }
        }
    }
`;

const MainS = styled.div`
    width: 640px;
    max-width: 100%;
`;

const TitleS = styled.h2`
    margin: 0 16px 24px 0;
    font-size: 32px;
    line-height: 44px;
    letter-spacing: -0.2px;
`;

const InfoS = styled.p`
    margin: 0 16px 24px 0;
    font-size: 16px;
    line-height: 1.5;
    color: #7E879C;
`;

const LineS = styled.div`
    width: 640px;
    height: 1px;
    background: #D9D9D9;
    margin: 16px 0;
`;

const ItemS = styled.div`
    width: 640px;
    height: 45px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const NameWrapS = styled.div`
    display: flex;
    flex-direction: column;
`;

const NameS = styled.p`
    font-size: 16px;
    line-height: 1.5;
    color: #000000;
    font-weight: 700;
`;

const StatusS = styled.p`
    font-size: 14px;
    line-height: 1.5;
    color: #7E879C;
`;

const SwitchS = styled.div<{ status: boolean }>`
    border: 1px solid #C3C3C3;
    border-radius: 32px;
    cursor: pointer;
    display: block;
    height: 32px;
    position: relative;
    transition: all .3s ease;
    width: 64px;
    position: relative;
    ${({status}) =>
        status ?
            `background-color: #3C9A5A;
      ` : `
      background-color: #ECECEC;
      `}
`;

const SwitcherS = styled.div<{ status: boolean }>`
    position: absolute;
    width: 28px;
    height: 28px;
    border-radius: 28px;
    top: 1px;
    background: linear-gradient(180deg, #FFFFFF 0%, #E3E3E3 100%);
    transition: all .3s ease;
    ${({status}) =>
        status ?
            `left: auto;
            right: 1px;
      box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.25);
      ` : `
      left: 1px;
      box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.25);
      `}
`;


const BtnS = styled.div<{ status: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 224px;
    height: 40px;
    border-radius: 2px;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    cursor: pointer;
    position: relative;
    margin-top: 32px;
    ${({status}) =>
        status ?
            `background-color: #0297FD;
      color: #FFFFFF;
      ` : `
      background-color: #ECECEC;
      color: #C3C3C3;
      `}
`;

const SuccS = styled.div`
    width: 100%;
    margin: 20px 0;
    color: var(--pp-color-green-4);
`;
const ErrorS = styled.div`
    width: 100%;
    margin: 20px 0;
    color: red;
`;

// eslint-disable-next-line
export default {
  MainS,
  WrapS,
  TitleS,
  InfoS,
  LineS,

  ItemS,
  NameWrapS,
  NameS,
  StatusS,

  SwitchS,
  SwitcherS,
  SuccS,
  ErrorS,
  BtnS,
};
