import React from 'react';
import { DoneIcon } from '../svgs/DoneIcon';
import s from './styles';

interface PropsType {
  type?: React.HTMLInputTypeAttribute;
  placeholder?: string;
  label?: string;
  value: string;
  handleChange: (value: string) => void;
  handleFocusout?: () => void;
  handleFocus?: () => void;
  styleGroup?: React.CSSProperties;
  styleWrap?: React.CSSProperties;
  style?: React.CSSProperties;
  error?: string;
  isDone?: boolean;
  isRedonly?: boolean;
  maxLength?: number;
  handleKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const DefaultInput: React.FC<PropsType> = ({
  maxLength,
  isRedonly,
  type,
  label,
  error,
  placeholder,
  value,
  styleGroup,
  styleWrap,
  style,
  isDone,
  handleChange,
  handleFocusout,
  handleFocus,
  handleKeyPress = () => {},
}) => {
  return (
    <s.FormGroupS style={styleGroup}> 
      <s.FormLabelS>{label}</s.FormLabelS>
      <s.FIeldWrapperS style={styleWrap}>
        <s.InputS
          readOnly={isRedonly}
          type={type}
          placeholder={placeholder}
          value={value}
          style={style}
          maxLength={maxLength}
          onBlur={handleFocusout}
          onFocus={handleFocus}
          onChange={(e) => handleChange(e.target.value)}
          onKeyPress={e=>handleKeyPress(e)}
        />
        {isDone ? (
          <s.InputVerifyS>
            <DoneIcon />
          </s.InputVerifyS>
        ) : null}
      </s.FIeldWrapperS>
      {error ? <s.WarningS dangerouslySetInnerHTML={{ __html: error }}></s.WarningS> : null}
    </s.FormGroupS>
  );
};
