import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../../store/applicationState';
import { ChangePasswd } from '../../../store/user/actions';
import { collectionValidate } from '../../../utils/collectionValidate';
import s from './styles';

interface PropsType {
  handleClose?: () => void;
}

export const FieldNewPasswd: React.FC<PropsType> = ({ handleClose }) => {
  const [password, setNewPassword] = React.useState('');
  const [error, setError] = React.useState('');
  const { User } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();

  const handleDone = () => {
    if (!password) return setError('Обов’язкове поле');
    if (!collectionValidate['password'].checkValue(password)) {
      return setError(collectionValidate['password'].error);
    }
    dispatch(
      ChangePasswd.request({
        data: {
          password,
        },
        callBack: (success) => {
          if (!success) setError('Сталася помилка');
          if (success && handleClose) {
            handleClose();
          }
        },
      })
    );
  };

  return (
    <s.FormGroupS>
      <s.FormLabelS>Новий пароль</s.FormLabelS>
      <s.FIeldWrapperS style={{ display: 'flex' }}>
        <s.InputS
          style={{ margin: '0 12px 0 0' }}
          type="password"
          name="password"
          value={password}
          onChange={(e) => {
            if (!collectionValidate['password'].checkValue(e.target.value)) {
              setError(collectionValidate['password'].error);
            } else {
              setError('');
            }
            setNewPassword(e.target.value);
          }}
        />
        <s.BtnSavePasswdS disabled={User.loading || !password || !!error} onClick={handleDone}>
          Зберегти
        </s.BtnSavePasswdS>
      </s.FIeldWrapperS>
      {error ? <s.WarningS>{error}</s.WarningS> : null}
    </s.FormGroupS>
  );
};
