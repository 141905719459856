export interface TConfigurationsState {
  data: IConfigurations | null;
  readonly loading: boolean;
  isAuth?: boolean;
  readonly errors?: string | undefined;
}

export interface IConfigurations {
  positions: IPositions[];
  support: ISupport[];
}

interface IPositions {
  Id: string;
  BtcMacroJobId: string;
  MakroName: string;
  Name: string;
}

export interface ISupport {
  id: number;
  name: string;
  description: string;
  img: string;
  position: number;
  approved: number;
  children: ISupportSub[];
}

export interface ISupportSub {
  id: number;
  partId: number;
  name: string;
  position: number;
  approved: number;
  questions: ISupportQuest[];
}

export interface ISupportQuest {
  id: number;
  partId: number;
  subpartId: number;
  name: string;
  body: string;
  position: number;
  approved: number;
}

export enum ActionTypes {
  GET_CONFIGURATIONS_R = '@@configurations/GET_CONFIGURATIONS_R',
  GET_CONFIGURATIONS_S = '@@configurations/GET_CONFIGURATIONS_S',
  GET_CONFIGURATIONS_E = '@@configurations/GET_CONFIGURATIONS_E',
}
