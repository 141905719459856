import axios from 'axios';
import { API_URL, TOKEN } from '../config';

export async function callApi(payload: {
  method: 'get' | 'post' | 'put' | 'delete' | 'patch';
  path: string;
  data?: Object | null;
  isFormData?: boolean;
}) {
  const { method, path, data, isFormData } = payload;

  const token = localStorage.getItem(TOKEN.access);

  try {
    const res = await axios.request({
      method,
      headers: {
        'Content-Type': isFormData ? 'multipart/form-data ' : 'application/json',
        Authorization: token,
      },
      withCredentials: true,
      url: API_URL + path,
      data: data ? data : null,
    });
    return res.data;
  } catch (e: any) {
    console.log(e.response, 'Error Api Call');
    console.log('Error', e);
    let error = e;
    if (e.response && e.response.data) error = e.response.data;
    throw error;
  }
}
