import { Reducer } from 'redux';
import { ActionTypes, TConfigurationsState } from './types';

export const initialState: TConfigurationsState = {
  loading: true,
  data: null,
  errors: undefined,
};

const configurationsReducer: Reducer<TConfigurationsState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_CONFIGURATIONS_R:
      return { ...state, loading: true, errors: undefined };

    case ActionTypes.GET_CONFIGURATIONS_S:
      return { ...state, loading: false, errors: undefined, data: action.payload };

    case ActionTypes.GET_CONFIGURATIONS_E:
      return { ...state, loading: false, errors: action.payload };
    default:
      return state;
  }
};

export { configurationsReducer as ConfigurationsReducer };
