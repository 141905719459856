import React from 'react';
import { Bookmarks } from '../components/content';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import s from './styles';

export const BookmarksPage: React.FC = () => {
  return (
    <s.WrapperS>
      <Header />
      <s.WrapperMain2S>
        <Bookmarks />
      </s.WrapperMain2S>
      <s.WrapperItemS>
        <Footer />
      </s.WrapperItemS>
    </s.WrapperS>
  );
};
