import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppStore } from '../../../store/applicationState';
import s from './styles';

export const Support: React.FC = () => {
  const { Configurations } = useSelector((store: AppStore) => store);
  const navigate = useNavigate();

  return (
    <s.WrapperS>
      <s.MainS>
        <div>
          <s.TitleS>Відповіді на часті запитання</s.TitleS>
          <s.LineS />
        </div>
        <div>
          {Configurations.data?.support.map((it) => {
            return (
              <s.PanelS
                key={it.id}
                onClick={() => {
                  navigate(`/feedback/${it.id}`);
                }}
              >
                <div style={{ marginRight: '8px' }}>
                  <s.SubTitle>{it.name}</s.SubTitle>
                  <s.DescriptionS>{it.description || ''}</s.DescriptionS>
                </div>
                <div style={{ display: 'flex', marginRight: '61px' }}>
                  <img style={{ width: '157px', height: '127px' }} src={it.img} alt="" />
                </div>
              </s.PanelS>
            );
          })}
        </div>
      </s.MainS>
    </s.WrapperS>
  );
};
