import React from 'react';
import styled from 'styled-components';

const ConteinerS = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentS = styled.div`
  padding: 64px;
  background: #faf8f5;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
  text-align: center;
`;

const BtnS = styled.button`
  outline: none;
  border: none;
  background: none;
  color: #0095ff;
  cursor: pointer;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.5;
  text-align: center;
  display: inline-block;
  padding-left: 26px;
  position: relative;
  -ms-grid-row-align: center;
  align-self: center;
`;

const TextS = styled.div`
  font-size: 18px;
  line-height: 150%;
  text-align: center;
`;

const TextBtnS = styled.span`
  line-height: 27px;
  color: #0095ff;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: #f1361d;
  }
`;

interface PropsType {
  handleOpen: () => void;
}

export const MessageEmpty: React.FC<PropsType> = ({ handleOpen }) => {
  return (
    <ConteinerS>
      <ContentS>
        <h2 style={{ marginBottom: '16px' }}>У вас поки що немає продуктів</h2>
        <TextS>
          Якщо ви придбали продукт і отримали код активації, <TextBtnS onClick={handleOpen}>уведіть його</TextBtnS>.
        </TextS>
      </ContentS>
      <BtnS>У мене є продукти, але зараз я їх не бачу</BtnS>
    </ConteinerS>
  );
};
