import React from 'react';
import { Main } from '../components/content';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import s from './styles';

export const HomePage: React.FC = () => {
  return (
    <s.WrapperS>
      <Header />
      <s.WrapperMainS>
        <Main />
      </s.WrapperMainS>
      <s.WrapperItemS>
        <Footer />
      </s.WrapperItemS>
    </s.WrapperS>
  );
};
