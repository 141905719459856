const ERROR_EMAIL = 'Неправильно зазначено e-mail';
const ERROR_PASSW = 'Латиниця і цифри, без пробілів, мінімум 4 символи';
const ERROR_FIO = 'Українською, без пробілів, від 2 букв';
const ERROR_POSITION = 'Уведіть не менше трьох символів';
const ERROR_VALID = 'Кирилиця від 5 букв';
const ERROR_PHONE = 'Ведіть номер телефону';

const checkFio = (value: string) => {
  if (
    // eslint-disable-next-line
    !RegExp(/[~!#$%^&*()@._|+=?;:",<>\{\}\[\]\\\/]/).test(value) &&
    // eslint-disable-next-line
    /[а-яієїґ']/.test(value.toLowerCase()) &&
    value.length >= 2 &&
    !value.includes(' ')
  ) {
    return true;
  }
  return false;
};

const checkTextFromFiveCyrillic = (value: string) => {
  if (!value) return true;
  if (
    // eslint-disable-next-line
    !RegExp(/[~!#$%^&*()@._|+=?;:",<>\{\}\[\]\\\/]/).test(value) &&
    /[а-яієїґ']/.test(value.toLowerCase()) &&
    value.replace(/ /g, '').length >= 5
  ) {
    return true;
  }
  return false;
};

const collectionValidate = {
  lastName: {
    error: ERROR_FIO,
    checkValue: checkFio,
  },
  firstName: {
    error: ERROR_FIO,
    checkValue: checkFio,
  },
  middleName: {
    error: ERROR_VALID,
    checkValue: (value: string) => {
      if (!value) return true;
      return checkTextFromFiveCyrillic(value);
    },
  },
  company: {
    error: ERROR_VALID,
    checkValue: (value: string) => {
      if (!value) return true;
      return checkTextFromFiveCyrillic(value);
    },
  },
  email: {
    error: ERROR_EMAIL,
    checkValue: (value: string) => {
      const indexDog = value.indexOf('@');
      const indexDot = value.lastIndexOf('.');
      // eslint-disable-next-line
      const afterDog = indexDog > 0 ? value.substring(indexDog + 1, indexDot).replace(/[0-9`~!#$@.%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/g, '') : '';
      // eslint-disable-next-line
      const strEnd = indexDot > 0 ? value.substring(indexDot + 1).replace(/[0-9`~!#$@.%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/g, '') : '';
      const tab =  value.includes('\t')
      if (
        !tab &&
        indexDot > 0 &&
        indexDog > 0 &&
        indexDog < indexDot &&
        afterDog.length > 0 &&
        strEnd.length >= 2 &&
        !value.includes(' ') &&
        // eslint-disable-next-line
        !RegExp(/[`~!#$@.%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/).test(value[0]) &&
        // eslint-disable-next-line
        !RegExp(/[`~!#$%^&*()|+=?;:'",<>\{\}\[\]\\\/]/).test(value)
      ) {
        console.log('email good');

        return true;
      }
      return false;
    },
  },
  password: {
    error: ERROR_PASSW,
    checkValue: (value: string) => {
      const str = value?.replace(/[0-9]/g, '');
      const num = value?.replace(/[^\d.]/g, '');
      if (/^[a-zA-Z]+$/.test(str) && num.length > 0 && value.length >= 4 && !value.includes(' ')) {
        return true;
      }
      return false;
    },
  },
  position: {
    error: ERROR_POSITION,
    checkValue: (value: string) => {
      if (!value) return true;
      return value.length >= 3 ? true : false;
    },
  },
  phone: {
    error: ERROR_PHONE,
    checkValue: (value: string) => {
      if (value.length >= 9) {
        return true;
      }
      return false;
    },
  },
};

export { collectionValidate };
