import React from 'react';
import styled from 'styled-components';

export const FooterS = styled.div`
  width: 100%;
  padding: 10px 0 0 50px;
  height: 120px;
  max-width: inherit;
  position: relative;
  line-height: 14px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 750px) {
    padding: 10px 15px 0 15px;
  }
`;

export const Footer: React.FC = () => { 
  return (
    <FooterS>
      <p>
        Відділ клієнтської підтримки:
        <br />
        <span>0 800 21 38 38 </span>
        <br />
        <span>Графік роботи — будні з 9:00 до 18:00</span>

      </p>

    </FooterS>
  );
};
