import React from 'react';
import s from '../components/Auth/styles';
import { Header, Footer } from '../components/Auth/components';

export const ConfirmChangeEmail: React.FC = () => {
  return (
    <div style={{ height: '100%' }}>
      <Header />
      <s.WrapperS>
        <s.FeedbackS>
          <h2>Ваш e-mail уже підтверджено. <a href='/login'>Авторизуйтеся</a></h2>

        </s.FeedbackS>
      </s.WrapperS>
      <Footer />
    </div>
  );
};
