import React from 'react';

export const AvatarIcon: React.FC<{ selected?: boolean }> = ({ selected }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlnsXlink="http://www.w3.org/1999/xlink">
      <circle cx="12" cy="9" r="4" stroke={selected ? 'black' : '#ADB2C2'} strokeWidth="1.85"></circle>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM12 22C17.4166 22 22 17.4166 22 12C22 6.58336 17.4166 2 12 2C6.58336 2 2 6.58336 2 12C2 17.4166 6.58336 22 12 22Z"
        fill={selected ? 'black' : '#ADB2C2'}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.33301 18.4204C4.99126 16.4752 6.83174 15.075 8.99925 15.075H14.9993C17.1673 15.075 19.0081 16.4758 19.6659 18.4217C19.1929 18.9858 18.6588 19.4969 18.0738 19.9448C18.0444 18.272 16.6791 16.925 14.9993 16.925H8.99925C7.31979 16.925 5.95479 18.2713 5.92476 19.9436C5.33986 19.4956 4.80588 18.9845 4.33301 18.4204Z"
        fill={selected ? 'black' : '#ADB2C2'}
      ></path>
    </svg>
  );
};
