import styled from 'styled-components';

const AboutMePageS = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 500px;
  max-width: 1440px;
  padding-top: 64px;
  position: relative;
`;

const AboutMainS = styled.div`
  width: 866px;
  max-width: 100%;
`;

const TitleS = styled.h2`
  margin: 0 16px 24px 0;
  display: inline-block;
  font-size: 32px;
  line-height: 44px;
  letter-spacing: -0.2px;
`;

const CompletenessS = styled.span`
  display: inline-flex;
  align-items: center;
  height: 44px;
  margin: 0 0 24px;
  vertical-align: top;
`;

const CompletenessTextS = styled.span`
  padding: 0 0 0 16px;
  color: #938e86;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
`;

const TabsS = styled.div`
  margin-bottom: 32px;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d9dce3;
  max-width: 100%;
  overflow: auto;
  overflow-y: hidden;
  @media (min-width: 1024px) {
    overflow: visible;
    overflow: initial;
    display: flex;
  }
`;

const LinkWrapperS = styled.div<{ selected?: boolean }>`
  display: flex;
  display: block;
  position: relative;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 6px;
  ${({ selected }) =>
    selected
      ? `
      a {
        color: #14151a;
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        height: 2px;
        bottom: -1px;
        background-color: #14151a;
      }
      `
      : ``}
`;

const ProfileFormS = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ProfileNoticeS = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

const LeftColumS = styled.div`
  flex: 1;
  max-width: 422px;
  margin: 0 40px 0 0;
`;

const ConfirmColumS = styled.div`
  flex: 1;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-end;
`;
const AvatarConteiner = styled.div`
  width: 170px;
  height: 170px;
  position: relative;
  background-color: var(--whiteColor);
  border: 1px dashed var(--coldGrayColor5);
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
`;

const AvatarConteinerText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 8px;
  font-size: 14px;
  line-height: 20px;
`;

const AvatarUploader = styled.div`
  width: 64px;
  height: 64px;
  background-color: var(--grayColor100);
  border: 1px solid var(--coldGrayColor2);
  box-sizing: border-box;
  border-radius: 100px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BtnS = styled.button<{ disabled?: boolean }>`
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  line-height: 24px;
  padding: 8px 24px;
  background-color: var(--blueColor);
  color: #fff;
  appearance: none;
  border: 1px solid transparent;
  border-radius: 3px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  flex-wrap: nowrap;
  justify-content: center;
  outline: none;
  text-align: center;
  white-space: nowrap;
  max-width: 422px;
  margin: 16px 0 0;
  width: 100%;

  ${({ disabled }) =>
    disabled
      ? `
        background-color: var(--grayColor);
        `
      : ''}
`;

const BtnCheckS = styled.button<{ disabled?: boolean }>`
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  line-height: 24px;
  padding: 8px 24px;
  background-color: var(--blueColor);
  color: #fff;
  appearance: none;
  border: 1px solid transparent;
  border-radius: 3px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  flex-wrap: nowrap;
  justify-content: center;
  outline: none;
  text-align: center;
  white-space: nowrap;
  max-width: 422px;
  margin: 8px;
  width: 200px;
  margin-top: 47px;

  ${({ disabled }) =>
    disabled
      ? `
        background-color: var(--grayColor);
        `
      : ''}
`;

const BtnSecondS = styled.div<{ disabled?: boolean }>`
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  line-height: 24px;
  color: #919191;
  appearance: none;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  flex-wrap: nowrap;
  justify-content: center;
  outline: none;
  text-align: center;
  margin: 0;
`;

const BtnContentS = styled.span`
  display: inline-block;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
`;

const FormGroupS = styled.div`
  margin: 16px 0 0;
`;

const FormLabelS = styled.label`
  margin: 0 0 4px;
  font-size: 16px;
  line-height: 24px;
`;

const FIeldWrapperS = styled.div`
  position: relative;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 0;
`;

const InputS = styled.input`
  font-size: 14px;
  line-height: 20px;
  padding: 6px 12px;
  background: var(--whiteColor);
  border: 1px solid var(--grayColor102);
  box-sizing: border-box;
  border-radius: 3px;
  margin: 0;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  height: 40px;
  outline: none;
  &:focus {
    border: 1px solid var(--blueColor);
  }
`;

const InputVerifyS = styled.div`
  right: 8px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  z-index: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  vertical-align: middle;
  margin: 0 8px;
  cursor: default;
`;

const WrapperRadioS = styled.label`
  align-items: center;
  color: var(--pp-color-gray-8);
  cursor: pointer;
  display: inline-flex;
  font-size: 16px;
  line-height: 24px;
  margin-left: 4px;
`;

const NameRadioS = styled.span`
  font-size: 16px;
  line-height: 24px;
  margin-left: 4px;
`;

const CircleS = styled.span`
  display: inline-block;
  line-height: 0;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-overflow: ellipsis;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  height: 20px;
  width: 20px;
  svg {
    stroke: var(--pp-color-blue-3);
    fill: var(--pp-color-white);
    display: inline-block;
    max-height: 100%;
    max-width: 100%;
    vertical-align: middle;
  }
`;

const BtnPasswdS = styled.button`
  margin: 16px 0 0;
  color: var(--blueColor);
  cursor: pointer;
  background: none;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font: inherit;
  text-align: left;
`;

const BtnSavePasswdS = styled.button<{ disabled?: boolean }>`
  background-color: var(--blueColor);
  font-size: 16px;
  font-weight: 600;
  height: 42px !important;
  line-height: var(--pp-button-large-line-height);
  padding: var(--pp-button-large-vertical-padding) var(--pp-button-large-horizontal-padding);
  color: var(--pp-button-blue-font-color);
  align-items: center;
  appearance: none;
  border: 1px solid transparent;
  border-radius: var(--pp-button-border-radius);
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  flex-wrap: nowrap;
  justify-content: center;
  outline: none;
  text-align: center;
  white-space: nowrap;
  ${({ disabled }) => (disabled ? 'background-color: var(--pp-button-background-color-disabled);' : '')}
`;

const WarningS = styled.span`
  margin: 2px 0 0;
  font-size: 14px;
  line-height: 20px;
  color: var(--redColor6);
`;

const SuccS = styled.div`
  width: 100%;
  margin: 20px 0;
  color: var(--pp-color-green-4);
`;
const ErrorS = styled.div`
  width: 100%;
  margin: 20px 0;
  color: red;
`;
const SocButtom = styled.div`
    margin: 16px 0 0;
    display: flex;
    width: 100px;
    justify-content: space-between;
`;

const PhoneRowS = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

const InfoS = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10px;
`;
const ConfirmInfoS = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
`;


// eslint-disable-next-line
export default {
  AboutMainS,
  AboutMePageS,
  AvatarConteiner,
  AvatarConteinerText,
  AvatarUploader,
  BtnContentS,
  BtnS,
  CircleS,
  CompletenessS,
  CompletenessTextS,
  FIeldWrapperS,
  FormGroupS,
  FormLabelS,
  LeftColumS,
  LinkWrapperS,
  NameRadioS,
  InputVerifyS,
  InputS,
  ProfileFormS,
  ProfileNoticeS,
  TabsS,
  TitleS,
  WrapperRadioS,
  BtnPasswdS,
  BtnSavePasswdS,
  WarningS,
  SuccS,
  ErrorS,
  SocButtom,
  PhoneRowS,
  BtnCheckS,
  ConfirmColumS,
  BtnSecondS,
  InfoS,
  ConfirmInfoS
};
