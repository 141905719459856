import { fork } from 'redux-saga/effects';
import productsSaga from './products/sagas';
import bookmarksSaga from './bookmarks/sagas';
import userSaga from './user/sagas';
import configurationsSaga from './configurations/sagas';
import newslettersSaga from './newsletters/sagas';

export default function* Main() {
  yield fork(userSaga);
  yield fork(productsSaga);
  yield fork(configurationsSaga);
  yield fork(bookmarksSaga);
  yield fork(newslettersSaga);
}
